import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Auth } from '@angular/fire/auth';
import {
  Database,
  child,
  push,
  ref as refDatabase,
  set as setDatabase,
} from '@angular/fire/database';
import {
  Storage,
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytesResumable,
} from '@angular/fire/storage';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { FileApi } from 'src/app/core/apis/file.api';
import { AuthService } from 'src/app/core/services/auth.service';
import { LogService } from 'src/app/core/services/log.service';

@Component({
  selector: 'app-form-file-upload',
  templateUrl: './form-file-upload.component.html',
  styleUrls: ['./form-file-upload.component.scss'],
})
export class FormFileUploadComponent implements OnInit {
  @Input() file?: File;
  @Input() fieldId?: string;
  @Input() formName?: string;
  @Input() solutionId?: string;
  @Output() reloadFiles: EventEmitter<number> = new EventEmitter();

  progress = 0;

  formNameRoute?: string;

  public _auth: Auth = inject(Auth);
  public authService: AuthService = inject(AuthService);
  private database: Database = inject(Database);
  private storage: Storage = inject(Storage);
  private logService: LogService = inject(LogService);
  private fileApi: FileApi = inject(FileApi);

  constructor(private messageService: VeoliaMessageService) {}

  ngOnInit(): void {
    this.uploadFile();
    if (this.formName === 'Design Form') {
      this.formNameRoute = 'designForm';
    }
    if (this.formName === 'Legal Form') {
      this.formNameRoute = 'legalForm';
    }
    if (this.formName === 'Article 32 Form') {
      this.formNameRoute = 'article32Form';
    }
    if (this.formName === 'Article 28 Form') {
      this.formNameRoute = 'article28Form';
    }
  }

  uploadFile() {
    if (this.file) {
      const metadata = {
        contentType: this.file?.type,
      };
      const path = `/files/${this.formName}/${this.solutionId}/${this.fieldId}/${this.file?.name}`;
      const storageRef = ref(this.storage, path);
      const uploadTask = uploadBytesResumable(storageRef, this.file, metadata);

      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.progress = progress - 10;
        },
        error => {
          console.log(error);
        },
        () => {
          const postedAt = new Date(Date.now()).toString();
          getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
            getMetadata(storageRef).then(metadata => {
              const uploadedFile = {
                contentType: metadata?.contentType,
                name: this.file?.name,
                userId: this._auth.currentUser?.uid,
                postedAt: postedAt,
                postedBy: this._auth.currentUser?.email,
                url: downloadURL,
              };

              const databasePath = `/files/${this.formName}/${this.solutionId}/${this.fieldId}`;
              const newFileKey = push(
                child(refDatabase(this.database), databasePath)
              ).key;

              setDatabase(
                refDatabase(this.database, `${databasePath}/${newFileKey}`),
                uploadedFile
              );
              this.progress = this.progress + 10;

              this.fileApi
                .notifyFileAdded(this.solutionId!, {
                  fileName: this.file!.name,
                  chapter: this.fieldId!,
                  formName: this.formName!,
                  postedAt: postedAt,
                  displayName: this.authService.displayName!,
                  formNameRoute: this.formNameRoute!
                })
                .subscribe();

              this.logService.setLogForAction(
                'File',
                this.solutionId!,
                this.formName!,
                {
                  action: 'File uploaded',
                  chapter: this.fieldId,
                  fileName: this.file?.name,
                  roleUser: this.authService.userRole,
                }
              );
              this.reloadFiles.emit();

              this.messageService.create(
                {
                  icon: 'check',
                  title: 'Success',
                  content: 'File uploaded successfully',
                  type: 'sucess',
                },
                { duration: 5000 }
              );
            });
          });
        }
      );
    }
  }
}
