import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { DesignForm } from 'src/app/core/models/design-form';
import { IaasForm } from 'src/app/core/models/iaas-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { Reservation } from 'src/app/core/models/reservation';
import { Solution } from 'src/app/core/models/solution';
import { getTagColor } from 'src/app/core/utils/colors';

@Component({
  selector: 'app-form-summary-panel',
  templateUrl: 'form-summary-panel.component.html',
  styleUrls: ['form-summary-panel.component.scss'],
})
export class FormSummaryPanelComponent {
  @Input() solutionId?: string;
  @Input() formName?: string;
  @Input() formStatus?: string;
  @Input() form?:
    | Article28Form
    | Article32Form
    | DesignForm
    | IaasForm
    | LegalForm;
  @Input() reservations?: Reservation[];
  @Input() title?: string;
  @Output() onOpenOverview: EventEmitter<string> = new EventEmitter<string>();
  @Output() onOpenEvaluation: EventEmitter<string> = new EventEmitter<string>();
  @Output() onOpenReservation: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(private router: Router) {}

  getFormStatusTheme(status: string) {
    return getTagColor(status);
  }

  get fixedCount() {
    return this.reservations?.filter(reservation => reservation.fixed === true)
      .length;
  }

  get notFixedCount() {
    return this.reservations?.filter(reservation => reservation.fixed === false)
      .length;
  }

  launchForm() {
    let formName = undefined;

    if (this.formName === 'Design Form') {
      formName = 'designForm';
    }

    if (this.formName === 'Legal Form') {
      formName = 'legalForm';
    }

    if (this.formName === 'Article 32 Form') {
      formName = 'article32Form';
    }

    if (this.formName === 'Annexes') {
      formName = 'article28Form';
    }

    if (formName) {
      this.router.navigate([`/${formName}/${this.solutionId}`]);
    }
  }
}
