import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NavbarModule } from '../../shared/features/navbar/navbar.module';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { LayoutModule } from '../../shared/features/layout/layout.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { BusinessUnitSolutionsComponent } from './components/business-unit-solutions/business-unit-solutions.component';
import { OtherBusinessUnitSolutionsComponent } from './components/other-business-unit-solutions/other-business-unit-solutions.component';
import { OwnedSolutionsComponent } from './components/owned-solutions/owned-solutions.component';
import { FollowedSolutionsComponent } from './components/followed-solutions/followed-solutions.component';
import { EvaluatorSolutionsComponent } from './components/evaluator-solutions/evaluator-solutions.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { PaginationModule } from '../../shared/features/pagination/pagination.module';
import { CaptionModule } from '../../shared/features/caption/caption.module';
import { DocumentationModule } from '../../shared/features/documentation/documentation.module';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzInputModule } from 'ng-zorro-antd/input';
import { SearchInputModule } from 'src/app/shared/features/search-input/search-input.module';
import { VendorSolutionsComponent } from './components/vendor-solutions/vendor-solutions.component';
import { AccessRightsModule } from 'src/app/shared/features/access-rights/access-rights.module';
import { HomeRoutingModule } from './home-routing.module';
import { VeoliaTabsComponent } from '@veolia.com/vds-angular-components/tabs';
import { VeoliaTabComponent } from '@veolia.com/vds-angular-components/tabs';
import { VeoliaSegmentedComponent } from '@veolia.com/vds-angular-components/segmented';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import {
  VeoliaTableComponent,
  VeoliaTableColumnComponent,
  VeoliaTableHeaderComponent,
  VeoliaTableCellComponent,
  VeoliaTableActionComponent,
} from '@veolia.com/vds-angular-components/table';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { VeoliaChipComponent } from '@veolia.com/vds-angular-components/chips';
import { VeoliaDropdownComponent } from '@veolia.com/vds-angular-components/dropdown';
import { VeoliaLinkComponent } from '@veolia.com/vds-angular-components/link';
import { SolutionsTableComponent } from './components/solutions-table/solutions-table.component';
import { VeoliaPageHeaderComponent } from '@veolia.com/vds-angular-components/page-header';
import { VeoliaBreadcrumbComponent } from '@veolia.com/vds-angular-components/breadcrumb';
import { VeoliaSwitchComponent } from '@veolia.com/vds-angular-components/switch';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { SolutionShareComponent } from 'src/app/shared/features/solution-share/components/solution-share.component';
import { SecurityScoreCardComponent } from 'src/app/shared/features/security-score-card/security-score-card.component';
import { SecurityScoreCardService } from 'src/app/core/services/security-score-card.service';
import { SolutionInfoSummaryModule } from 'src/app/shared/features/solution-info-summary/solution-info-summary.module';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';

@NgModule({
  declarations: [
    HomePageComponent,
    SolutionsTableComponent,
    BusinessUnitSolutionsComponent,
    OtherBusinessUnitSolutionsComponent,
    OwnedSolutionsComponent,
    FollowedSolutionsComponent,
    EvaluatorSolutionsComponent,
    VendorSolutionsComponent,
  ],
  imports: [
    CommonModule,
    LayoutModule,
    VeoliaModalModule,
    NzTableModule,
    NzIconModule,
    NavbarModule,
    NzButtonModule,
    NzTabsModule,
    NzDropDownModule,
    NzToolTipModule,
    NzMenuModule,
    NzModalModule,
    NzAlertModule,
    NzCheckboxModule,
    NzPaginationModule,
    NzTypographyModule,
    NzSelectModule,
    NzInputModule,
    PaginationModule,
    CaptionModule,
    DocumentationModule,
    SearchInputModule,
    AccessRightsModule,
    VeoliaSegmentedComponent,
    VeoliaTabsComponent,
    VeoliaTabComponent,
    VeoliaButtonComponent,
    VeoliaTableComponent,
    VeoliaTableColumnComponent,
    VeoliaTableHeaderComponent,
    VeoliaTableColumnComponent,
    VeoliaTableCellComponent,
    VeoliaTableActionComponent,
    VeoliaTagComponent,
    VeoliaIconComponent,
    VeoliaChipComponent,
    VeoliaLinkComponent,
    VeoliaDropdownComponent,
    VeoliaPageHeaderComponent,
    VeoliaBreadcrumbComponent,
    VeoliaSwitchComponent,
    SolutionShareComponent,
    SecurityScoreCardComponent,
    SolutionInfoSummaryModule,
    HomeRoutingModule,
  ],
  providers: [SecurityScoreCardService],
})
export class HomeModule {}
