import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import SolutionApi from 'src/app/core/apis/solution.api';
import { AccessRightsStore } from '../access-rights/access-rights.store';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-access-right-user',
  templateUrl: './access-right-user.component.html',
  styleUrls: ['./access-right-user.component.scss'],
  providers: [AccessRightsStore],
})
export class AccessRightUserComponent {
  @Input() solutionId?: string;
  @Input() solutionOwner?: string;
  @Input() email?: string;
  @Input() isDeletable?: boolean;
  @Output() removed: EventEmitter<void> = new EventEmitter<void>();
  isLoading = false;

  constructor(
    private veoliaMessageService: VeoliaMessageService,
    private solutionApi: SolutionApi,
    private authService: AuthService
  ) {}

  removeUser(): void {
    this.solutionApi
      .removeUserFromSolution(this.solutionId!, this.email!)
      .subscribe({
        next: response => {
          this.removed.emit();
          this.veoliaMessageService.create(
            {
              title: 'User removed !',
              icon: 'check',
              content: response.message,
            },
            {
              duration: 10000,
            }
          );
        },
        error: error => {
          console.log(error);
          this.veoliaMessageService.create(
            {
              title: 'Error',
              icon: 'error',
              content: error.message,
            },
            {
              duration: 10000,
            }
          );
        },
      });
  }
}
