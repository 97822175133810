import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { tap, withLatestFrom, map, switchMap, Observable } from 'rxjs';
import UserMapper from 'src/app/core/mappers/user.mapper';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { BusinessUnitService } from 'src/app/core/services/business-unit.service';
import { UserService } from 'src/app/core/services/user.service';
import { BusinessUnitItemsStore } from 'src/app/core/stores/business-unit-items.store';
import {
  BusinessUnitStoreState,
  businessUnitStoreInitialState,
} from 'src/app/core/stores/states/business-unit-item-store.state';

@Injectable()
export class AdminBusinessUnitsPageStore extends BusinessUnitItemsStore<
  BusinessUnitStoreState<BusinessUnit>
> {
  constructor(
    private businessUnitService: BusinessUnitService,
    private userService: UserService
  ) {
    super(businessUnitStoreInitialState);
  }

  getCount = this.effect(trigger$ => {
    return trigger$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(() => {
        return this.businessUnitService
          .getCountBusinessUnits()
          .then(total => {
            this.setTotal(total);
          })
          .catch((err: HttpErrorResponse) => {
            this.setError(err);
          });
      })
    );
  });

  getBusinessUnits = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ limit, isForward, nextCursor, prevCursor }) => {
        const cursor = isForward ? nextCursor : prevCursor;

        return this.businessUnitService
          .getBusinessUnits(cursor, limit, cursor ? isForward : true)
          .pipe(
            tapResponse(
              data => {
                this.setPrevCursor(data.docs[0]);
                this.setNextCursor(data.docs[data.docs.length - 1]);

                const businessUnitDocs = data.docs.map(businessUnit => {
                  return {
                    ...businessUnit.data(),
                    uid: businessUnit.id,
                  } as BusinessUnit;
                });

                this.setBusinessUnits(businessUnitDocs);
              },
              (err: HttpErrorResponse) => {
                this.setError(err);
              }
            )
          );
      })
    );
  });

  refreshBusinessUnits = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ limit, prevCursor }) => {
        return this.businessUnitService
          .getBusinessUnits(prevCursor, limit, true)
          .pipe(
            tapResponse(
              data => {
                this.setPrevCursor(data.docs[0]);
                this.setNextCursor(data.docs[data.docs.length - 1]);

                const businessUnitDocs = data.docs.map(businessUnit => {
                  return {
                    ...businessUnit.data(),
                    uid: businessUnit.id,
                  } as BusinessUnit;
                });

                this.setBusinessUnits(businessUnitDocs);
              },
              (err: HttpErrorResponse) => {
                this.setError(err);
              }
            )
          );
      })
    );
  });

  searchBusinessUnits = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ uid, search }) => {
        return this.businessUnitService.searchBusinessUnits(uid, search).pipe(
          tapResponse(
            data => {
              const businessUnitDocs = data.docs.map(businessUnit => {
                return {
                  ...businessUnit.data(),
                  uid: businessUnit.id,
                } as BusinessUnit;
              });

              this.setBusinessUnits(this.filterSearch(businessUnitDocs));
            },
            (err: HttpErrorResponse) => {
              this.setError(err);
            }
          )
        );
      })
    );
  });

  getBusinessUnit = (id: string) => {
    return this.get().businessUnits.find(
      businessUnit => businessUnit.uid === id
    );
  };

  getUsers = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoadingUsers(true);
      }),
      switchMap(() => {
        return this.userService.observeVeoliaUsers().pipe(
          tap(data => {
            this.setUsers(data.map(user => UserMapper.toEmailedUser(user)));
            this.setIsLoadingUsers(false);
          })
        );
      })
    );
  });

  changeLimit = this.effect((limit$: Observable<number>) => {
    return limit$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([limit, state]) => ({ limit, state })),
      tap(() => this.setPageReset(1)),
      tap(({ limit }) => {
        this.setLimit(limit);
      }),
      tap(() => this.getBusinessUnits())
    );
  });

  changePage = this.effect((page$: Observable<number>) => {
    return page$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([page, state]) => ({ page, state })),
      tap(({ page }) => {
        this.setPage(page);
      }),
      tap(() => this.getBusinessUnits())
    );
  });

  changeSearch = this.effect((search$: Observable<string>) => {
    return search$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([search, state]) => ({ search, state })),
      tap(({ search }) => {
        this.setSearch(search);
      }),
      tap(({ search }) => {
        if (search.length > 0) {
          return this.searchBusinessUnits();
        } else {
          this.setPageReset(1);
          return this.getBusinessUnits();
        }
      })
    );
  });

  filterSearch = (businessUnits: BusinessUnit[]): BusinessUnit[] => {
    const searchTerm = this.get().search.toLowerCase();
    return businessUnits.filter(
      businessUnit =>
        businessUnit.nomBu?.toLowerCase().includes(searchTerm) ||
        businessUnit.country?.toLowerCase().includes(searchTerm) ||
        businessUnit.delegatedZone?.toLowerCase().includes(searchTerm) ||
        businessUnit.emailDLABU?.toLowerCase().includes(searchTerm) ||
        businessUnit.evaluators?.includes(searchTerm) ||
        businessUnit.uid.includes(searchTerm) ||
        businessUnit.nomZone?.toLowerCase().includes(searchTerm)
    );
  };
}
