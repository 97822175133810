import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { debounceTime } from 'rxjs';
import { Solution } from 'src/app/core/models/solution';

@Component({
  selector: 'app-form-chapter',
  templateUrl: './form-chapter.component.html',
  styleUrls: ['./form-chapter.component.scss'],
})
export class FormChapterComponent implements OnInit {
  @Input() solution?: Solution;
  @Input() chapterId = '';
  @Input() evaluation: any = {};
  @Input() formEvaluation: any = {};
  @Input() chapterTitle = '';
  @Input() currentChapterId?: string;
  @Input() formModel: any;
  @Input() formName = '';
  @Input() formFields?: FormlyFieldConfig[] = [];
  @Input() assessable = true;
  @Output() chapterSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() chapterEdited: EventEmitter<any> = new EventEmitter<any>();
  @Output() formEdited: EventEmitter<any> = new EventEmitter<any>();

  private auth: Auth = inject(Auth);

  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      disabled: true,
    },
  };

  fields: FormlyFieldConfig[] = [];

  ngOnInit(): void {
    if (this.formFields) {
      this.model = { ...this.buildModel() };
      this.fields = this.formFields;

      this.options.formState.disabled = !this.isVendor || this.isVendorReadOnly;

      setTimeout(() => {
        this.form.valueChanges.subscribe((val: { [key: string]: any }) => {
          const changedFields: { [key: string]: any } = {};

          Object.keys(val).forEach(key => {
            if (val[key] !== undefined) {
              changedFields[key] = val[key];
            }
          });

          this.formEdited.emit(changedFields);
        });

        this.form.valueChanges
          .pipe(debounceTime(10000))
          .subscribe((val: { [key: string]: any }) => {
            const changedFields: { [key: string]: any } = {};

            Object.keys(val).forEach(key => {
              if (val[key] !== undefined) {
                changedFields[key] = val[key];
              }
            });

            this.chapterEdited.emit(changedFields);
          });
      }, 5000);
    }
  }

  get isVendor() {
    if (!this.solution?.emailVendorAuthorized) {
      return false;
    }
    return this.solution?.emailVendorAuthorized.includes(
      this.auth.currentUser?.email!
    );
  }

  get isVendorReadOnly() {
    if (this.formName === 'Article 28 Form') {
      return this.solution?.emailVendorReadonlyArt28?.includes(
        this.auth.currentUser?.email!
      );
    } else if (this.formName === 'Article 32 Form') {
      return this.solution?.emailVendorReadonlyArt32?.includes(
        this.auth.currentUser?.email!
      );
    } else if (this.formName === 'Design Form') {
      return this.solution?.emailVendorReadonlyDesign?.includes(
        this.auth.currentUser?.email!
      );
    } else if (this.formName === 'IaaS Form') {
      return this.solution?.emailVendorReadonlyIaas?.includes(
        this.auth.currentUser?.email!
      );
    } else if (this.formName === 'Legal Form') {
      return this.solution?.emailVendorReadonlyLegal?.includes(
        this.auth.currentUser?.email!
      );
    } else {
      return false;
    }
  }

  buildModel() {
    const keys = this.getKeys(this.formFields);
    return Object.fromEntries(
      Object.entries(this.formModel || []).filter(([key]) => keys.includes(key))
    );
  }

  getKeys(fields: any): string[] {
    return fields.reduce((keys: any, field: { fieldGroup: any; key: any }) => {
      if (field.fieldGroup) {
        return [...keys, ...this.getKeys(field.fieldGroup)];
      } else {
        return [...keys, field.key];
      }
    }, []);
  }

  selectChapter() {
    this.chapterSelected.emit(this.chapterId);
  }

  submit() {
    this.chapterEdited.emit(this.model);
  }
}
