import { NgModule } from '@angular/core';
import { DemandFormPageComponent } from './pages/demand-form-page/demand-form-page.component';
import { DemandsRoutingModule } from './demands-routing.module';
import { LayoutModule } from 'src/app/shared/features/layout/layout.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { FormLayoutModule } from 'src/app/shared/features/form-layout/form-layout.module';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DemandFormComponent } from './components/demand-form/demand-form.component';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormNZCheckbox } from 'src/app/shared/features/form-layout/components/form-nz-checkbox/form-nz-checkbox.type';
import { SolutionFormComponent } from './components/solution-form/solution-form.type';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { FormlyFieldInput } from 'src/app/shared/features/form-layout/components/form-nz-input/form-nz-input.type';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormlyFieldTextArea } from 'src/app/shared/features/form-layout/components/form-nz-textarea/form-nz-textarea.type';
import { minValidationMessage } from 'src/app/shared/validators/min.validator';
import { maxValidationMessage } from 'src/app/shared/validators/max.validator';
import { DomainService } from 'src/app/core/services/domain.service';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { FormlyFieldSelect } from 'src/app/shared/features/form-layout/components/form-nz-select/form-nz-select.type';
import {
  noHttpsAndWWWValidator,
  noHttpsAndWWWValidatorMessage,
} from 'src/app/shared/validators/website.validator';
import {
  lowercaseValidator,
  lowercaseValidatorMessage,
} from 'src/app/shared/validators/lowercase.validator';
import {
  noBlockedWordsInEmailValidator,
  noBlockedWordsInEmailValidatorMessage,
  noGenericEmailValidator,
  noGenericEmailValidatorMessage,
} from 'src/app/shared/validators/no-generic-email.validator';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import {
  IsCompletionAfterEvaluationDate,
  IsCompletionAfterEvaluationDateMessage,
  IsEvaluationBeforeCompletionDate,
  IsEvaluationBeforeCompletionDateMessage,
} from 'src/app/shared/validators/date-past.validator';
import { TranslateModule } from '@ngx-translate/core';
import { SolutionService } from 'src/app/core/services/solution.service';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { DemandFormEditPageComponent } from './pages/demand-form-edit-page/demand-form-edit-page.component';
import { DemandFormEditComponent } from './components/demand-form-edit/demand-form-edit.component';
import { DemandFormsPageComponent } from './pages/demand-forms-page/demand-forms-page.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { DocumentationModule } from 'src/app/shared/features/documentation/documentation.module';
import { CaptionModule } from 'src/app/shared/features/caption/caption.module';
import { DesignFormComponent } from './components/design-form/design-form.component';
import { LegalFormComponent } from './components/legal-form/legal-form.component';
import {
  VeoliaTabComponent,
  VeoliaTabsComponent,
} from '@veolia.com/vds-angular-components/tabs';
import { Article28FormComponent } from './components/article-28-form/article-28-form.component';
import { ChapterCompletionStatusService } from 'src/app/core/services/chapter-completion-status.service';
import { DesignFormStore } from './components/design-form/design-form.store';
import { LegalFormStore } from './components/legal-form/legal-form.store';
import { SimpleSpinnerModule } from 'src/app/shared/features/simple-spinner/simple-spinner.module';
import { Article32FormComponent } from './components/article-32-form/article-32-form.component';
import { Article28FormStore } from './components/article-28-form/article-28-form.store';
import { Article32FormStore } from './components/article-32-form/article-32-form.store';
import { EvaluationApi } from 'src/app/core/apis/evaluation.api';
import { VeoliaAlertComponent } from '@veolia.com/vds-angular-components/alert';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { VeoliaLinkComponent } from '@veolia.com/vds-angular-components/link';
import { SolutionShareComponent } from 'src/app/shared/features/solution-share/components/solution-share.component';
import { AccessRightsModule } from 'src/app/shared/features/access-rights/access-rights.module';
import { GoBackComponent } from 'src/app/shared/features/go-back/go-back.component';
import { IAASFormComponent } from './components/iass-form/iass-form.component';
import { DocumentPresenceComponent } from 'src/app/shared/features/document-presence/document-presence.component';
import { ContactFormComponent } from 'src/app/shared/features/contact-form/contact-form.component';
import { FormStore } from 'src/app/core/stores/form.store';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';

@NgModule({
  declarations: [
    DemandFormPageComponent,
    DemandFormEditPageComponent,
    DemandFormComponent,
    DemandFormEditComponent,
    DemandFormsPageComponent,
    DesignFormComponent,
    LegalFormComponent,
    Article28FormComponent,
    Article32FormComponent,
    IAASFormComponent,
    SolutionFormComponent,
  ],
  imports: [
    CommonModule,
    CaptionModule,
    DemandsRoutingModule,
    DocumentationModule,
    FormLayoutModule,
    FormlyNgZorroAntdModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'stepper',
          component: DemandFormComponent,
          wrappers: [],
        },
        {
          name: 'stepper-edit',
          component: DemandFormEditComponent,
          wrappers: [],
        },
        {
          name: 'nz-checkbox',
          component: FormNZCheckbox,
          wrappers: ['form-field'],
        },
        {
          name: 'nz-select',
          component: FormlyFieldSelect,
          wrappers: ['form-field'],
        },
        {
          name: 'input',
          component: FormlyFieldInput,
          wrappers: ['form-field'],
        },
        {
          name: 'repeat-solution',
          component: SolutionFormComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'nz-textarea',
          component: FormlyFieldTextArea,
          wrappers: ['form-field'],
        },
      ],
      validators: [
        { name: 'no-https-and-www', validation: noHttpsAndWWWValidator },
        { name: 'lowercase', validation: lowercaseValidator },
        { name: 'no-generic-email', validation: noGenericEmailValidator },
        { name: 'no-blocked-word', validation: noBlockedWordsInEmailValidator },
        {
          name: 'IsCompletionAfterEvaluationDate',
          validation: IsCompletionAfterEvaluationDate,
        },
        {
          name: 'IsEvaluationBeforeCompletionDate',
          validation: IsEvaluationBeforeCompletionDate,
        },
      ],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
        { name: 'no-https-and-www', message: noHttpsAndWWWValidatorMessage },
        { name: 'lowercase', message: lowercaseValidatorMessage },
        { name: 'no-generic-email', message: noGenericEmailValidatorMessage },
        {
          name: 'no-blocked-word',
          message: noBlockedWordsInEmailValidatorMessage,
        },

        {
          name: 'IsCompletionAfterEvaluationDate',
          message: IsCompletionAfterEvaluationDateMessage,
        },
        {
          name: 'IsEvaluationBeforeCompletionDate',
          message: IsEvaluationBeforeCompletionDateMessage,
        },
      ],
    }),
    LayoutModule,
    NzAlertModule,
    NzButtonModule,
    NzCollapseModule,
    NzDividerModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzMessageModule,
    NzSpinModule,
    NzTabsModule,
    NzTypographyModule,
    ReactiveFormsModule,
    VeoliaButtonComponent,
    VeoliaTabsComponent,
    VeoliaTabComponent,
    VeoliaIconComponent,
    NzTypographyModule,
    SimpleSpinnerModule,
    VeoliaAlertComponent,
    VeoliaIconComponent,
    VeoliaLinkComponent,
    NzModalModule,
    SolutionShareComponent,
    DocumentPresenceComponent,
    AccessRightsModule,
    GoBackComponent,
    ContactFormComponent,
    TranslateModule,
  ],
  providers: [
    DomainService,
    SolutionService,
    DesignFormStore,
    LegalFormStore,
    Article28FormStore,
    Article32FormStore,
    ChapterCompletionStatusService,
    EvaluationApi,
    FormStore,
  ],
})
export class DemandsModule {}
