<app-layout-page-header>
  <veolia-page-header variant="light">
    <div veolia-page-header-title style="display: flex">
      <veolia-icon
        class="backbtn"
        (click)="backtoAdminHome()"
        title="Back to Admin Home"
        [size]="24"
        [name]="'arrow_back'"></veolia-icon>
      Business Units administration
    </div>

    <div veolia-page-header-bottom>
      <div class="right-actions">
        <app-form-caption></app-form-caption>
        <app-documentation></app-documentation>
      </div>
      <div>
        <veolia-button
          [size]="'default'"
          [variant]="'primary'"
          [icon]="'add'"
          [iconFilled]="true"
          [iconPosition]="'left'"
          (click)="modal.open()">
          New Business Unit
        </veolia-button>
      </div>
    </div>
  </veolia-page-header>
</app-layout-page-header>
<div class="layout-body">
  <div class="layout-body-content">
    <ng-container *ngIf="vm$ | async as vm">
      <nz-alert
        *ngIf="vm.error"
        nzType="error"
        [nzMessage]="vm.error"
        nzShowIcon></nz-alert>
      <div>
        <div class="row row-right row-bottom">
          <search-input
            [isSearching]="vm.isSearching"
            (onChange)="onSearch($event)"></search-input>
        </div>
        <veolia-table
          [hideSelection]="true"
          [datasource]="vm.businessUnits"
          size="small"
          [showPagination]="false">
          <veolia-table-column
            [sortDirections]="['ascend', 'descend', null]"
            [sortPriority]="1">
            <veolia-table-header>Solution</veolia-table-header>
            <veolia-table-cell>
              <ng-template let-row>{{ row.nomBu }}</ng-template>
            </veolia-table-cell>
          </veolia-table-column>

          <veolia-table-column>
            <veolia-table-header>Zone</veolia-table-header>
            <veolia-table-cell>
              <ng-template let-row>
                {{ row.nomZone }}
              </ng-template>
            </veolia-table-cell>
          </veolia-table-column>

          <veolia-table-column>
            <veolia-table-header>Evaluators count</veolia-table-header>
            <veolia-table-cell>
              <ng-template let-row>
                {{ row.evaluators ? row.evaluators.length : '0' }}
              </ng-template>
            </veolia-table-cell>
          </veolia-table-column>

          <veolia-table-column fixedRight [width]="34">
            <veolia-table-header></veolia-table-header>
            <veolia-table-cell [withoutBackground]="true">
              <ng-template let-row>
                <a nz-dropdown [nzDropdownMenu]="menu">
                  <span nz-icon nzType="more"></span>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li nz-menu-item>
                      <veolia-link
                        size="small"
                        icon="domain_add"
                        (click)="onEdit(row.uid)">
                        Edit
                      </veolia-link>
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </ng-template>
            </veolia-table-cell>
          </veolia-table-column>
        </veolia-table>
      </div>
      <app-pagination
        [loading]="vm.isLoading || vm.isSearching"
        [size]="vm.limit"
        [page]="vm.currentPage"
        [total]="vm.total"
        (onPageChange)="changePage($event)"
        (onSizeChange)="changeSize($event)"></app-pagination>
    </ng-container>
  </div>
</div>
<veolia-modal #modal [closable]="true">
  <div veolia-modal-title>Edit Business Unit Form</div>
  <div veolia-modal-content>
    <div class="content">
      <div class="column column-space-between">
        <div>
          <form
            nz-form
            [formGroup]="form"
            (ngSubmit)="onSubmit()"
            [nzLayout]="'vertical'">
            <div class="row row-space-between">
              <div class="column">
                <nz-form-item>
                  <nz-form-label>Name</nz-form-label>
                  <nz-form-control nzErrorTip="Please input the name!">
                    <input
                      nz-input
                      class="light-gray"
                      formControlName="nomBu" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label>Zone</nz-form-label>
                  <nz-form-control nzErrorTip="Please input the zone!">
                    <input
                      nz-input
                      class="light-gray"
                      formControlName="nomZone" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label>Delegated zone</nz-form-label>
                  <nz-form-control
                    nzErrorTip="Please input the email of the Business Unit!">
                    <input
                      nz-input
                      class="light-gray"
                      formControlName="delegatedZone" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label>Country</nz-form-label>
                  <nz-form-control
                    nzErrorTip="Please input the country of the Business Unit!">
                    <input
                      nz-input
                      class="light-gray"
                      formControlName="country" />
                  </nz-form-control>
                </nz-form-item>
                <ng-container *ngIf="vm$ | async as vm">
                  <nz-form-item>
                    <nz-form-label>Evaluators</nz-form-label>
                    <nz-form-control>
                      <nz-select
                        nzMode="multiple"
                        nzPlaceHolder="Select a veolia"
                        nzAllowClear
                        nzShowSearch
                        (nzOnSearch)="onSearch($event)"
                        class="light-gray full-width"
                        formControlName="evaluators">
                        <ng-container *ngFor="let u of vm.users">
                          <nz-option
                            [nzValue]="u.email"
                            [nzLabel]="u.name"></nz-option>
                        </ng-container>
                        <nz-option
                          *ngIf="vm.isLoadingUsers"
                          nzDisabled
                          nzCustomContent>
                          <span
                            nz-icon
                            nzType="loading"
                            class="loading-icon"></span>
                          Loading Data...
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
                <nz-form-item>
                  <nz-form-label>Email of Business Unit</nz-form-label>
                  <nz-form-control
                    nzErrorTip="Please input the email of the Business Unit!">
                    <input
                      nz-input
                      class="light-gray"
                      formControlName="emailDLABU" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div veolia-modal-footer>
    <ng-container *ngIf="vm$ | async as vm">
      <veolia-button
        *ngIf="!vm.isLoadingUsers"
        variant="secondary"
        htmlType="reset"
        (click)="close()">
        Cancel
      </veolia-button>
      <veolia-button nz-button variant="primary" (click)="onSubmit()">
        Validate
      </veolia-button>
      <simple-spinner *ngIf="vm.isLoading"></simple-spinner>
    </ng-container>
  </div>
</veolia-modal>
<nz-modal
  [nzWidth]="575"
  [nzVisible]="isPreviewVisible"
  [nzTitle]="modalTitlePreview"
  (nzOnCancel)="closePreviewModal()"
  [nzFooter]="modalFooterPreview">
  [nzFooter]="null">
  <ng-template #modalTitlePreview>
    <div class="title row row-space-between row-vertical-center">
      <h3 nz-typography>
        {{ 'Business unit description for' }}
        <a>{{ selectedBusinessUnit?.nomBu }}</a>
        <br />
        <span style="font-size: 0.8em"
          >ID: <em>{{ selectedBusinessUnit?.uid! }}</em></span
        >
      </h3>
    </div>
  </ng-template>
  <ng-container *nzModalContent>
    <div class="content">
      <div class="column column-space-between">
        <div>
          <div class="row row-space-between">
            <div class="column">
              <div class="spacing-v16">
                <dl>
                  <dt><strong>Name</strong></dt>
                  <dd>{{ selectedBusinessUnit?.nomBu }}</dd>
                </dl>
              </div>
              <div class="spacing-v16">
                <dl>
                  <dt><strong>Country</strong></dt>
                  <dd>
                    <span
                      *ngIf="selectedBusinessUnit?.country; else not_available"
                      >{{ selectedBusinessUnit?.country }}</span
                    >
                  </dd>
                </dl>
              </div>
              <div class="spacing-v16">
                <dl>
                  <dt>
                    <strong>Zone</strong>
                  </dt>
                  <dd>
                    <span
                      *ngIf="selectedBusinessUnit?.nomZone; else not_available"
                      >{{ selectedBusinessUnit?.nomZone }}</span
                    >
                  </dd>
                </dl>
              </div>
              <div class="spacing-v16">
                <dl>
                  <dt><strong>Delegated zone</strong></dt>
                  <dd>
                    <span
                      *ngIf="
                        selectedBusinessUnit?.delegatedZone;
                        else not_available
                      "
                      >{{ selectedBusinessUnit?.delegatedZone }}</span
                    >
                  </dd>
                </dl>
              </div>
              <div class="spacing-v16">
                <nz-list
                  *ngIf="selectedBusinessUnit?.evaluators"
                  nzBordered
                  nzSize="small">
                  <nz-list-header>Evaluators:</nz-list-header>
                  <nz-list-item
                    *ngFor="let item of selectedBusinessUnit?.evaluators"
                    >{{ item }}</nz-list-item
                  >
                </nz-list>
                <nz-list
                  *ngIf="!selectedBusinessUnit?.evaluators"
                  nzBordered
                  nzSize="small">
                  <nz-list-header><strong>Evaluators</strong></nz-list-header>
                  <nz-list-item
                    ><span nz-typography
                      ><code>Not available</code></span
                    ></nz-list-item
                  >
                </nz-list>
              </div>
              <div>
                <dl>
                  <dt><strong>Email of Business Unit</strong></dt>
                  <dd>
                    <span
                      *ngIf="
                        selectedBusinessUnit?.emailDLABU;
                        else not_available
                      "
                      >{{ selectedBusinessUnit?.emailDLABU }}</span
                    >
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #modalFooterPreview>
    <div class="row row-right buttons">
      <ng-container *ngIf="vm$ | async as vm">
        <button
          *ngIf="!vm.isLoadingUsers"
          type="reset"
          nz-button
          nzType="default"
          (click)="closePreviewModal()">
          Close
        </button>
      </ng-container>
    </div>
  </ng-template>
</nz-modal>

<ng-template #not_available
  ><span nz-typography><code>Not available</code></span></ng-template
>
