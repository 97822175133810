import { Injectable } from '@angular/core';
import { tap, withLatestFrom, map, switchMap } from 'rxjs';
import { IaasForm } from 'src/app/core/models/iaas-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { ChapterCompletionStatusService } from 'src/app/core/services/chapter-completion-status.service';
import { CommentService } from 'src/app/core/services/comment.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { FileService } from 'src/app/core/services/file.service';
import { IaaSFormService } from 'src/app/core/services/iaas-form.service';
import { ReservationService } from 'src/app/core/services/reservation.service';
import { FormStore } from 'src/app/core/stores/form.store';

@Injectable({
  providedIn: 'root',
})
export class IAASFormStore extends FormStore {
  constructor(
    protected override evaluationService: EvaluationService,
    protected override reservationService: ReservationService,
    protected override chapterCompletionService: ChapterCompletionStatusService,
    protected override commentService: CommentService,
    protected override fileService: FileService,
    private iaasFormService: IaaSFormService
  ) {
    super(
      evaluationService,
      reservationService,
      chapterCompletionService,
      commentService,
      fileService
    );
  }

  getIaaSForm = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => this.setNotReady()),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ solution, solutionId }) => {
        return this.iaasFormService
          .get(solutionId)
          .then(data => {
            if (data.exists()) {
              console.log('data', data);
              this.setForm({
                uid: solution?.uid,
                ...data.data(),
              } as IaasForm);
              this.setReady();
            } else {
              this.iaasFormService
                .create(
                  this.initForm(solution, 'iaas') as unknown as IaasForm,
                  solution?.uid
                )
                .then(() => {
                  this.setForm({
                    uid: solution?.uid,
                    ...this.initForm(solution, 'iaas'),
                  } as unknown as IaasForm);

                  this.setReady();
                })
                .catch(error => {
                  console.log(error);
                  this.setReady();
                });
            }
          })
          .catch(() => {
            this.setReady();
          });
      })
    );
  });
}
