import { Injectable } from '@angular/core';
import { IaasForm } from '../models/iaas-form';
import { EntityService } from './entity.service';

@Injectable()
export class IaaSFormService extends EntityService<IaasForm> {
  override get collectionName(): string {
    return 'iaasForm';
  }
}
