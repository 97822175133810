import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  signal,
} from '@angular/core';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';
import { FormName } from 'src/app/core/enums/form-name';
import { FileItem } from 'src/app/core/models/file-item';
import { Solution } from 'src/app/core/models/solution';

@Component({
  selector: 'app-file-summary',
  templateUrl: './file-summary.component.html',
  styleUrls: ['./file-summary.component.scss'],
})
export class FileSummaryComponent implements OnInit, OnChanges {
  @Input() isVisible = false;
  @Input() solution?: Solution;
  @Input() formName?: FormName;
  @Input() files?: Record<string, FileItem[]>;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('modal') modal!: VeoliaModalComponent;

  fields = signal<string[]>([]);

  opened = 0;

  ngOnInit(): void {
    this.fields.set(Object.keys(this.files!));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['files'] && !changes['files'].isFirstChange()) {
      this.fields.set(Object.keys(this.files!));
    }
  }

  open() {
    this.modal.open();
  }
}
