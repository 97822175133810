<nz-card [nzTitle]="panelTitle" [nzExtra]="designStatus">
  <p>
    <nz-list>
      <nz-list-item>
        <veolia-link
          [variant]="'primary'"
          [size]="'default'"
          [icon]="'chevron_right'"
          (click)="onOpenOverview.emit(formName)">
          About this form
        </veolia-link>
      </nz-list-item>
      <nz-list-item>
        <veolia-link
          [variant]="'primary'"
          [size]="'default'"
          [icon]="'chevron_right'"
          (click)="onOpenEvaluation.emit(formName)">
          Evaluation
        </veolia-link>
      </nz-list-item>
      <nz-list-item>
        <veolia-link
          *ngIf="!reservations?.length"
          [disabled]="true"
          [variant]="'primary'"
          [size]="'default'"
          [icon]="'chevron_right'">
          Reservations
          <nz-badge
            [nzCount]="fixedCount"
            [nzStyle]="{ backgroundColor: '#52c41a' }"></nz-badge>
          <nz-badge [nzCount]="notFixedCount"></nz-badge>
        </veolia-link>
        <veolia-link
          *ngIf="reservations?.length"
          (click)="onOpenReservation.emit(formName)"
          [variant]="'primary'"
          [size]="'default'"
          [icon]="'chevron_right'">
          Reservations
          <nz-badge
            [nzCount]="fixedCount"
            [nzStyle]="{ backgroundColor: '#52c41a' }"></nz-badge>
          <nz-badge [nzCount]="notFixedCount"></nz-badge>
        </veolia-link>
      </nz-list-item>
    </nz-list>
  </p>
</nz-card>
<ng-template #designStatus>
  <veolia-tag [label]="formStatus!" [color]="getFormStatusTheme(formStatus!)" />
</ng-template>
<ng-template #panelTitle>
  <veolia-link
    [variant]="'primary'"
    [size]="'default'"
    [icon]="'chevron_right'"
    iconPosition="right"
    (click)="launchForm()">
    {{ title }}
  </veolia-link>
</ng-template>
