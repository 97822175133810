import { Injectable, inject } from '@angular/core';
import {
  FormStoreState,
  formStoreInitialState,
} from './states/form-store.state';
import { ComponentStore } from '@ngrx/component-store';
import { Auth } from '@angular/fire/auth';
import { BusinessUnit } from '../models/business-unit';
import { Solution } from '../models/solution';
import { withLatestFrom, map, switchMap, tap } from 'rxjs';
import { ChapterStatus } from '../models/chapter-status';
import { Evaluation } from '../models/evalutation';
import { Reservation } from '../models/reservation';
import { convertDictToList } from '../utils/common';
import { EvaluationService } from '../services/evaluation.service';
import { ReservationService } from '../services/reservation.service';
import { ChapterCompletionStatusService } from '../services/chapter-completion-status.service';
import { Article28Form } from '../models/article-28-form';
import { DesignForm } from '../models/design-form';
import { LegalForm } from '../models/legal-form';
import { Article32Form } from '../models/article-32-form';
import { CommentService } from '../services/comment.service';
import { FileService } from '../services/file.service';
import { Comment } from '../models/comment';
import { FileItem } from '../models/file-item';
import { IaasForm } from '../models/iaas-form';

@Injectable()
export class FormStore extends ComponentStore<FormStoreState> {
  private comments$ = this.select(state => state.comments);
  private error$ = this.select(state => state.error);
  private evaluations$ = this.select(state => state.evaluation);
  private files$ = this.select(state => state.files);
  private fileList$ = this.select(state => state.fileList);
  readonly form$ = this.select(state => state.form);
  private formName$ = this.select(state => state.formName);
  private message$ = this.select(state => state.message);
  private ready$ = this.select(state => state.ready);
  private solutionId$ = this.select(state => state.solutionId);
  private statuses$ = this.select(state => state.statuses);

  public _auth: Auth = inject(Auth);

  vm$ = this.select(
    this.comments$,
    this.error$,
    this.evaluations$,
    this.files$,
    this.fileList$,
    this.form$,
    this.formName$,
    this.message$,
    this.ready$,
    this.solutionId$,
    this.statuses$,
    (
      comments,
      error,
      evaluation,
      files,
      fileList,
      form,
      formName,
      message,
      ready,
      solutionId,
      statuses
    ) => ({
      comments,
      error,
      evaluation,
      files,
      fileList,
      form,
      formName,
      message,
      ready,
      solutionId,
      statuses,
    })
  );

  constructor(
    protected evaluationService: EvaluationService,
    protected reservationService: ReservationService,
    protected chapterCompletionService: ChapterCompletionStatusService,
    protected commentService: CommentService,
    protected fileService: FileService
  ) {
    super(formStoreInitialState);
  }

  setSolutionId = this.updater((state, solutionId: string) => ({
    ...state,
    solutionId,
  }));

  setSolution = this.updater((state, solution?: Solution) => ({
    ...state,
    solution,
  }));

  setBusinessUnit = this.updater((state, businessUnit?: BusinessUnit) => ({
    ...state,
    businessUnit,
  }));

  setForm = this.updater(
    (
      state,
      form: Article28Form | Article32Form | DesignForm | LegalForm | IaasForm
    ) => ({
      ...state,
      form,
    })
  );

  setFormName = this.updater((state, formName: string) => ({
    ...state,
    formName,
  }));

  setEvaluationIsLoading = this.updater(state => ({
    ...state,
    evaluationIsLoading: true,
  }));

  setEvaluation = this.updater((state, evaluation: Evaluation) => ({
    ...state,
    evaluation,
  }));

  setReservations = this.updater(
    (state, reservations: Record<string, Reservation>) => ({
      ...state,
      reservations,
    })
  );

  setComments = this.updater((state, comments: Record<string, Comment[]>) => ({
    ...state,
    comments,
  }));

  setFiles = this.updater((state, files: Record<string, FileItem[]>) => ({
    ...state,
    files,
  }));

  setFileList = this.updater((state, fileList: FileItem[]) => ({
    ...state,
    fileList,
  }));

  setStatuses = this.updater(
    (state, statuses: Record<string, ChapterStatus>) => ({ ...state, statuses })
  );

  setError = this.updater((state, message: string) => ({
    ...state,
    error: true,
    message,
  }));

  setSuccess = this.updater((state, message: string) => ({
    ...state,
    error: false,
    message,
  }));

  setReady = this.updater(state => ({
    ...state,
    ready: true,
  }));

  setNotReady = this.updater(state => ({
    ...state,
    ready: false,
  }));

  resetMessage = this.updater(state => ({
    ...state,
    error: false,
    message: undefined,
  }));

  getEvaluation = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setEvaluationIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ formName, solutionId }) => {
        return this.evaluationService
          .getEvaluation(solutionId, formName)
          .then(data => {
            this.setEvaluation(data as Evaluation);
          })
          .catch(error => {
            console.log(error);
          });
      })
    );
  });

  getAllComments = this.effect(trigger$ => {
    return trigger$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ formName, solutionId }) => {
        return this.commentService
          .getAllComments(formName, solutionId)
          .then(data => {
            const comments: Record<string, Comment[]> = {};

            if (data) {
              Object.entries(data).forEach(([field, fieldComments]) => {
                const commentList: Comment[] = [];
                Object.entries(fieldComments).forEach(([k, v]) => {
                  commentList.push({ id: k, ...(v as Comment) });
                });
                comments[field] = commentList;
              });
            }
            this.setComments(comments);
            return comments;
          })
          .catch(error => {
            console.log(error);
          });
      })
    );
  });

  getAllReservations = this.effect(trigger$ => {
    return trigger$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ formName, solutionId }) => {
        return this.reservationService
          .getReservations(solutionId, formName)
          .then(data => {
            const reservs = data as Record<string, unknown>;

            if (reservs) {
              const reservsDatas = convertDictToList(reservs);
              const reservations: Record<string, Reservation> = {};

              reservsDatas.forEach(rD => {
                Object.keys(rD).forEach(k => {
                  if (typeof rD[k] === 'object') {
                    reservations[(rD[k] as Reservation).reference] = {
                      idRef: k,
                      ...rD[k],
                    };
                  }
                });
              });

              this.setReservations(reservations);
            }
          })
          .catch(error => {
            console.log(error);
          });
      })
    );
  });

  getAllFiles = this.effect(trigger$ => {
    return trigger$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ formName, solutionId }) => {
        return this.fileService
          .getAllFiles(formName, solutionId)
          .then(data => {
            const files: Record<string, FileItem[]> = {};
            let fileList: FileItem[] = [];

            if (data) {
              Object.entries(data).forEach(([field, fieldFiles]) => {
                files[field] = Object.values(fieldFiles);
                fileList = [
                  ...fileList,
                  ...(Object.values(fieldFiles) as FileItem[]),
                ];
              });
            }
            this.setFileList(fileList);
            this.setFiles(files);
          })
          .catch(error => {
            console.log(error);
          });
      })
    );
  });

  getStatuses = this.effect(trigger$ => {
    return trigger$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ formName, solutionId }) => {
        return this.chapterCompletionService
          .getStatuses(solutionId, formName)
          .then(data => {
            const statuses = data as Record<string, unknown>;
            if (statuses) {
              const statusesDatas = convertDictToList(statuses);
              const completionStatuses: Record<string, ChapterStatus> = {};

              statusesDatas.forEach(cS => {
                Object.keys(cS).forEach(k => {
                  if (typeof cS[k] === 'object') {
                    completionStatuses[cS.id] = {
                      id: k,
                      ...cS[k],
                    };
                  }
                });
              });

              this.setStatuses(completionStatuses);
            }
          })
          .catch(error => {
            console.log(error);
          });
      })
    );
  });

  getFieldId = () => {
    return this.get().evaluation?.id;
  };

  getCurrentEvaluation = () => {
    return this.get().evaluation;
  };

  getFieldEvalution = (fieldName: string) => {
    return this.get().evaluation?.[fieldName] || 'DLA Assessment';
  };

  getFieldComment = (fieldName: string) => {
    return this.get().evaluation?.[fieldName] || '';
  };

  getFieldReservation = (fieldName: string) => {
    return this.get().reservations?.[fieldName];
  };

  getStatus = (fieldName: string) => {
    return this.get().statuses?.[fieldName];
  };

  getForm = () => {
    return this.get().form;
  };

  getSolution = () => {
    return this.get().solution;
  };

  getBusinessUnit = () => {
    return this.get().businessUnit;
  };

  initForm(solution?: Solution, type?: string) {
    if (type === 'design') {
      return {
        businessUnitOfSolution: solution?.businessUnitOfSolution,
        createdAt: solution?.createdAt,
        designFull: false,
        domainOfSolution: solution?.domainOfSolution,
        editorBrand: solution?.editorBrand,
        emailInitiatorVeolia: solution?.emailInitiatorVeolia,
        fullNameInitiatorVeolia: solution?.fullNameInitiatorVeolia,
        idSolution: solution?.uid,
        jsonFR: solution?.jsonFR,
        jsonUS: solution?.jsonUS,
        link: solution?.link,
        requestDate: solution?.requestDate,
        rfpName: solution?.rfpName,
        solutionName: solution?.solutionName,
        solutionOwner: solution?.solutionOwner,
        type: solution?.type,
      };
    }
    if (type === 'legal') {
      return {
        businessUnitOfSolution: solution?.businessUnitOfSolution,
        createdAt: solution?.createdAt,
        legalFull: false,
        domainOfSolution: solution?.domainOfSolution,
        editorBrand: solution?.editorBrand,
        emailInitiatorVeolia: solution?.emailInitiatorVeolia,
        fullNameInitiatorVeolia: solution?.fullNameInitiatorVeolia,
        idSolution: solution?.uid,
        jsonFR: solution?.jsonFR,
        jsonUS: solution?.jsonUS,
        link: solution?.link,
        requestDate: solution?.requestDate,
        rfpName: solution?.rfpName,
        solutionName: solution?.solutionName,
        solutionOwner: solution?.solutionOwner,
        type: solution?.type,
      };
    }
    if (type === 'art32') {
      return {
        businessUnitOfSolution: solution?.businessUnitOfSolution,
        createdAt: solution?.createdAt,
        article32Full: false,
        domainOfSolution: solution?.domainOfSolution,
        editorBrand: solution?.editorBrand,
        emailInitiatorVeolia: solution?.emailInitiatorVeolia,
        fullNameInitiatorVeolia: solution?.fullNameInitiatorVeolia,
        idSolution: solution?.uid,
        jsonFR: solution?.jsonFR,
        jsonUS: solution?.jsonUS,
        link: solution?.link,
        requestDate: solution?.requestDate,
        rfpName: solution?.rfpName,
        solutionName: solution?.solutionName,
        solutionOwner: solution?.solutionOwner,
        type: solution?.type,
      };
    }
    if (type === 'art28') {
      return {
        businessUnitOfSolution: solution?.businessUnitOfSolution,
        createdAt: solution?.createdAt,
        article28Full: false, //article28
        domainOfSolution: solution?.domainOfSolution,
        editorBrand: solution?.editorBrand,
        emailInitiatorVeolia: solution?.emailInitiatorVeolia,
        fullNameInitiatorVeolia: solution?.fullNameInitiatorVeolia,
        idSolution: solution?.uid,
        jsonFR: solution?.jsonFR,
        jsonUS: solution?.jsonUS,
        link: solution?.link,
        requestDate: solution?.requestDate,
        rfpName: solution?.rfpName,
        solutionName: solution?.solutionName,
        solutionOwner: solution?.solutionOwner,
        type: solution?.type,
      };
    }
    if (type === 'iaas') {
      return {
        businessUnitOfSolution: solution?.businessUnitOfSolution,
        createdAt: solution?.createdAt,
        iaasFull: false, //design
        domainOfSolution: solution?.domainOfSolution,
        editorBrand: solution?.editorBrand,
        emailInitiatorVeolia: solution?.emailInitiatorVeolia,
        fullNameInitiatorVeolia: solution?.fullNameInitiatorVeolia,
        idSolution: solution?.uid,
        jsonFR: solution?.jsonFR,
        jsonUS: solution?.jsonUS,
        link: solution?.link,
        requestDate: solution?.requestDate,
        rfpName: solution?.rfpName,
        solutionName: solution?.solutionName,
        solutionOwner: solution?.solutionOwner,
        type: solution?.type,
      };
    }
    return;
  }
}
