<app-layout-page-header>
  <veolia-page-header variant="light">
    <div veolia-page-header-title style="display: flex">
      <veolia-icon
        class="backbtn"
        (click)="backtoAdminHome()"
        title="Back to Admin Home"
        [size]="24"
        [name]="'arrow_back'"></veolia-icon>
      Solutions administration
    </div>
    <div veolia-page-header-bottom>
      <veolia-segmented
        *ngIf="vm$ | async as vm"
        [options]="[
          { label: 'SaaS', value: 0 },
          { label: 'IaaS', value: 1 }
        ]"
        (handleIndexChange)="onSolutionTypeChange($event)"
        [variant]="'primary'"
        [selectedIndex]="vm.solutionType"
        [size]="'default'" />

      <div class="right-actions">
        <app-form-caption></app-form-caption>
        <app-documentation></app-documentation>
      </div>
    </div>
  </veolia-page-header>
</app-layout-page-header>

<div class="layout-body">
  <div class="layout-body-content">
    <ng-container *ngIf="vm$ | async as vm">
      <nz-tabset>
        <nz-tab
          [nzTitle]="
            vm.solutionType === 0 ? 'All SaaS requests' : 'All IaaS requests'
          ">
          <app-admin-all-solutions
            [solutionType]="vm.solutionType"></app-admin-all-solutions>
        </nz-tab>
        <nz-tab
          [nzTitle]="
            vm.solutionType === 0
              ? 'All SaaS evaluated requests'
              : 'All IaaS evaluated solutions'
          ">
          <app-admin-all-evaluated-solutions
            [solutionType]="
              vm.solutionType
            "></app-admin-all-evaluated-solutions>
        </nz-tab>
        <nz-tab
          [nzTitle]="
            vm.solutionType === 0
              ? 'All SaaS pending requests'
              : 'All IaaS pending requests'
          ">
          <app-admin-all-pending-solutions
            [solutionType]="vm.solutionType"></app-admin-all-pending-solutions>
        </nz-tab>
      </nz-tabset>
    </ng-container>
  </div>
</div>
