import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { NzMessageService } from 'ng-zorro-antd/message';
import SolutionApi from 'src/app/core/apis/solution.api';
import { FormName } from 'src/app/core/enums/form-name';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { Solution } from 'src/app/core/models/solution';
import { LogService } from 'src/app/core/services/log.service';
import { ScrollService } from 'src/app/core/services/scroll.service';
import { SolutionService } from 'src/app/core/services/solution.service';
import { FormlyFormOptions } from '@ngx-formly/core';
import { Chapter } from 'src/app/core/types/chapter';
import { yesOrNoOptions } from 'src/app/core/forms/checkbox-options';
import { IAASFormStore } from './iaas-form.store';
import { IaasForm } from 'src/app/core/models/iaas-form';
import { IaaSFormService } from 'src/app/core/services/iaas-form.service';
import { serverTimestamp } from '@angular/fire/database';
import { serverTimestamp as fireServerTimestamp } from '@angular/fire/database';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-iaas-form',
  templateUrl: './iass-form.component.html',
  styleUrls: ['./iass-form.component.scss'],
})
export class IAASFormComponent implements OnInit {
  @Input() solution?: Solution;
  @Input() businessUnit?: BusinessUnit;
  @Output() updateFormDate: EventEmitter<{
    formName: string;
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  }> = new EventEmitter<{
    formName: string;
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  }>();

  formName: FormName = FormName.IAAS;
  showEvaluationModal?: boolean;

  vm$ = this.iaaSFormStore.vm$;

  formChapters: string[] = ['IAAS_1', 'IAAS_2', 'IAAS_3', 'IAAS_4'];

  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      disabled: true,
    },
  };
  formData: any = {};

  selectedChapter? = this.formChapters[0];
  fields: { [key: string]: Chapter } = {};

  constructor(
    public auth: Auth,
    public iaasFormService: IaaSFormService,
    public iaaSFormStore: IAASFormStore,
    public logService: LogService,
    public messageService: NzMessageService,
    public scrollService: ScrollService,
    public solutionApi: SolutionApi,
    public solutionService: SolutionService,
    private translateService: TranslateService,
    private veoliaMessageService: VeoliaMessageService
  ) {}

  ngOnInit(): void {
    this.loadEvaluation();
    this.fillFields();
  }

  loadEvaluation() {
    this.iaaSFormStore.setSolutionId(this.solution?.uid!);
    this.iaaSFormStore.setSolution(this.solution!);
    this.iaaSFormStore.setBusinessUnit(this.businessUnit!);
    this.iaaSFormStore.setFormName('IaaS Form');
    this.iaaSFormStore.getIaaSForm();
    this.iaaSFormStore.getEvaluation();
    this.iaaSFormStore.getAllReservations();
    this.iaaSFormStore.getAllComments();
    this.iaaSFormStore.getAllFiles();
    this.iaaSFormStore.getStatuses();

    this.options.formState.disabled = !this.isVendor || this.isVendorReadOnly;

    this.iaaSFormStore.form$.subscribe(data => {
      this.updateFormDate.emit({
        formName: this.formName,
        lastUpdate: data?.lastUpdate!,
        lastUpdateBy: data?.lastUpdateBy!,
        status: data?.iaasStatus!
      });
    });
  }

  selectChapter(id: string) {
    this.selectedChapter = id;
    this.scrollService.scrollToElementById(id);
  }

  formEdited(data: any) {
    this.formData = { ...this.formData, ...data };
  }

  editChapter(data: any) {
    const messageId = this.messageService.loading('Saving...', {
      nzDuration: 0,
    }).messageId;
    setTimeout(() => {
      this.iaasFormService
        .update(
          this.solution?.uid!,
          {
            ...data,
            iaasStatus: 'Vendor is responding',
          },
          true
        )
        .then(() => {
          return this.solutionService.updateSolution(this.solution?.uid!, {
            lastUpdate: fireServerTimestamp(),
            iaasStatus: 'Vendor is responding',
            status: 'Ongoing',
          });
        })
        .then(() => {
          this.messageService.remove(messageId);
          this.veoliaMessageService.create(
            {
              title: 'Saved successfully',
            },
            { duration: 3000 }
          );
        })
        .catch(error => {
          console.log(error);
          this.messageService.remove(messageId);
        });
    }, 2000);
  }

  saveAll() {
    const messageId = this.messageService.loading('Saving All', {
      nzDuration: 0,
    }).messageId;
    setTimeout(() => {
      this.iaasFormService
        .update(
          this.solution?.uid!,
          {
            ...this.formData,
            iaasStatus: 'Vendor is responding',
          },
          true
        )
        .then(() => {
          return this.solutionService.updateSolution(this.solution?.uid!, {
            lastUpdate: fireServerTimestamp(),
            iaasStatus: 'Vendor is responding',
            status: 'Ongoing',
          });
        })
        .then(() => {
          this.messageService.remove(messageId);
          this.logService.setLogForAction(
            'SaveForm',
            this.solution?.uid!,
            this.formName!,
            {
              action: 'Save form',
              userEmail: this.auth.currentUser?.email!,
              dateTime: serverTimestamp(),
              userId: this.auth.currentUser?.uid!,
            }
          );
          this.veoliaMessageService.create(
            {
              title: 'Saved All successfully',
            },
            { duration: 3000 }
          );
        })
        .catch(error => {
          console.log(error);
          this.messageService.remove(messageId);
        });
    }, 2000);
  }

  markAsComplete(status: boolean) {
    const messageId = this.messageService.loading(
      'Updating completion status',
      {
        nzDuration: 0,
      }
    ).messageId;

    setTimeout(() => {
      this.iaasFormService
        .update(
          this.solution?.uid!,
          {
            iaasStatus: status ? 'Vendor is responding' : 'Completed',
          },
          true
        )
        .then(() => {
          const data: any = {
            iaasStatus: status ? 'Vendor is responding' : 'Completed',
          };

          if (status === false) {
            data['status'] = 'Ongoing';
          }

          return this.solutionService.update(this.solution?.uid!, data, true);
        })
        .then(() => {
          this.messageService.remove(messageId);

          if (status == false) {
            this.veoliaMessageService.create(
              {
                title: 'Marked as completed successfully',
              },
              { duration: 3000 }
            );

            this.logService.setLogForAction(
              'SaveCompleteForm',
              this.solution?.uid!,
              this.formName!,
              {
                action: 'Save complete form',
                userEmail: this.auth.currentUser?.email!,
                dateTime: serverTimestamp(),
                userId: this.auth.currentUser?.uid!,
              }
            );

            this.solutionApi
              .notifySolutionFormCompleted(this.solution?.uid!, {
                formName: this.formName,
                formNameRoute: 'iaasForm',
              })
              .subscribe();
          } else {
            this.veoliaMessageService.create(
              {
                title: 'Marked as uncompleted successfully',
              },
              { duration: 3000 }
            );
          }

          this.refreshForm();
        })
        .catch(error => {
          console.log(error);
          this.messageService.remove(messageId);
        });
    }, 2000);
  }

  get evaluation() {
    return this.iaaSFormStore.getCurrentEvaluation();
  }

  get formCompletionStatus() {
    if ((this.iaaSFormStore.getForm() as IaasForm).iaasStatus) {
      if (
        ['New', 'Vendor is responding'].includes(
          (this.iaaSFormStore.getForm() as IaasForm).iaasStatus
        )
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  get formStatus() {
    return (this.iaaSFormStore.getForm() as IaasForm)?.iaasStatus || 'New';
  }

  get isVendor() {
    if (!this.solution?.emailVendorAuthorized) {
      return false;
    }

    return this.solution?.emailVendorAuthorized.includes(
      this.auth.currentUser?.email!
    );
  }

  get isVendorReadOnly() {
    if (!this.solution?.emailVendorReadonlyDesign) {
      return false;
    }

    return (
      this.solution?.emailVendorReadonlyDesign?.includes(
        this.auth.currentUser?.email!
      ) || false
    );
  }

  refreshForm() {
    this.iaaSFormStore.getIaaSForm();
    this.iaaSFormStore.getEvaluation();
    this.iaaSFormStore.getAllReservations();
  }

  fillFields() {
    this.fields = {
      IAAS_1: {
        evaluation: {
          commentField: 'IAAS_1_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'IAAS_1_RADIO',
        },
        label: 'IAAS_1',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: '',
                  solutionId: this.solution?.uid,
                  formName: 'IaaS Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_11_RADIO',
                    commentField: 'DA_11_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'IAAS_11_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('IAAS_11_01'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template:
                      '<p>Please add any proof (mail, document) that attests it.</p>',
                  },
                  {
                    key: 'IAAS_11_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('IAAS_11_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'IAAS_12_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('IAAS_12_01'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template:
                      '<p>Please add any proof (mail, document) that attests it.</p>',
                  },
                  {
                    key: 'IAAS_12_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('IAAS_12_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      IAAS_2: {
        evaluation: {
          commentField: 'IAAS_2_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'IAAS_2_RADIO',
        },
        label: 'IAAS_2',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: '',
                  solutionId: this.solution?.uid,
                  formName: 'IaaS Form',
                  solutionName: this.solution?.solutionName,
                },
                fieldGroup: [
                  {
                    template:
                      '<p>Please upload all evidence (Solution Architecture Documentation (SAD), architecture diagram…).<br />To know more about the architecture review, visit this <a href="https://oneintranet.veolia.com/corp-ist-norms/framework?contentV1Fallback=true" target="_blank">e-Norms page</a>.<br />Has the architecture been reviewed in an Architecture Comitee ?</p>',
                  },
                  {
                    key: 'IAAS_2_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('IAAS_2_01'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'IAAS_2_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('IAAS_2_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'IAAS_2_03',
                    type: 'nz-datepicker',
                    props: {
                      label: this.translateService.instant('IAAS_2_03'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'IAAS_2_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('IAAS_2_04'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      IAAS_3: {
        label: 'IAAS_3',
        evaluation: {
          statusField: 'IAAS_3_RADIO',
          commentField: 'IAAS_3_RADIO_comment',
        },
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: '',
                  solutionId: this.solution?.uid,
                  formName: 'IaaS Form',
                  solutionName: this.solution?.solutionName,
                },
                fieldGroup: [
                  {
                    template:
                      '<p><a href="https://gbl-ist-ve-cicddoc.ue.r.appspot.com/2.1.x/toolchain/" target="_blank">What is CI/CD toolchain ?</a><br />Please upload all evidence (CI/CD nutriscore…) available <a href="https://lookerstudio.google.com/u/0/reporting/ba2a9f3d-28ab-4d53-be0d-5f080001b696/page/p_gj5kia0mmc" target="_blank">here</a> by selecting your project</p>',
                  },
                  {
                    key: 'IAAS_3_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('IAAS_3_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'IAAS_3_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('IAAS_3_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      IAAS_4: {
        label: 'IAAS_4',
        evaluation: {
          statusField: 'IAAS_4_RADIO',
          commentField: 'IAAS_4_RADIO_comment',
        },
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: '',
                  solutionId: this.solution?.uid,
                  formName: 'IaaS Form',
                  solutionName: this.solution?.solutionName,
                },
                fieldGroup: [
                  {
                    template:
                      '<p>To know more about the Digital Solution Compliance, visit this <a href="https://docs.google.com/presentation/d/1khJAxJemtOIGNQD1iPyWS9_40y8MYh0MwCxWCqT3Lcs/edit#slide=id.g72415511a5_3_8274">page</a><br />Please upload all your evidences</p>',
                  },
                  {
                    key: 'IAAS_4_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('IAAS_4_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  }
}
