import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentStore, OnStoreInit } from '@ngrx/component-store';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { tap, withLatestFrom, map, switchMap, from, of } from 'rxjs';
import SolutionApi from 'src/app/core/apis/solution.api';
import { Solution } from 'src/app/core/models/solution';
import { AuthService } from 'src/app/core/services/auth.service';
import { BusinessUnitService } from 'src/app/core/services/business-unit.service';
import { DesignFormService } from 'src/app/core/services/design-form.service';
import { SolutionService } from 'src/app/core/services/solution.service';
import {
  DemandFormEditStoreState,
  demandFormEditStoreInitialState,
} from 'src/app/core/stores/states/demand-form-edit-store.state';
import { FormStore } from 'src/app/core/stores/form.store';
import { DesignForm } from 'src/app/core/models/design-form';
import { Auth } from '@angular/fire/auth';
import { LegalFormService } from 'src/app/core/services/legal-form.service';
import { Article32FormService } from 'src/app/core/services/article-32-form.service';
import { Article28FormService } from 'src/app/core/services/article-28-form.service';
import { LegalForm } from 'src/app/core/models/legal-form';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';

@Injectable()
export class DemandFormEditStore
  extends ComponentStore<DemandFormEditStoreState<Solution>>
  implements OnStoreInit
{
  readonly isLoading$ = this.select(state => state.loading);
  readonly selectedId$ = this.select(state => state.selectedId);
  readonly unlockeds$ = this.select(state => state.unlockeds);

  vm$ = this.select(
    this.isLoading$,
    this.selectedId$,
    this.unlockeds$,
    (loading, selectedId, unlockeds) => ({
      loading,
      selectedId,
      unlockeds,
    })
  );

  emailVeoliaAuthorized: string[] = [];

  constructor(
    private authService: AuthService,
    private businessUnitService: BusinessUnitService,
    private veoliaMessageService: VeoliaMessageService,
    private router: Router,
    private solutionApi: SolutionApi,
    private solutionService: SolutionService,
    private designFormService: DesignFormService,
    private legalFormService: LegalFormService,
    private article32FormService: Article32FormService,
    private article28FormService: Article28FormService,
    private formStore: FormStore,
    private auth: Auth
  ) {
    super(demandFormEditStoreInitialState);
  }

  ngrxOnStoreInit = () => {
    this.authService.userData.subscribe(user => {
      if (user) {
        this.patchState({
          currentUser: user,
        });

        this.getEvaluatorList();
        this.getVeoliaList();
      }
    });
  };

  setSolutionName = this.updater((state, solutionName: string) => {
    return {
      ...state,
      solutionName,
    };
  });

  setVeoliaList = this.updater((state, veoliaList: string[]) => {
    return {
      ...state,
      veoliaList,
    };
  });

  setEvaluatorList = this.updater((state, evaluatorList: string[]) => {
    return {
      ...state,
      evaluatorList,
    };
  });

  setIsLoading = this.updater(state => {
    return {
      ...state,
      loading: true,
    };
  });

  setIsNotLoading = this.updater(state => {
    return {
      ...state,
      loading: false,
    };
  });

  setSelectedId = this.updater((state, selectedId: string) => {
    return {
      ...state,
      selectedId,
    };
  });

  setUnlockeds = this.updater((state, unlockeds: string[]) => {
    return {
      ...state,
      unlockeds: Array.from(new Set([...state.unlockeds, ...unlockeds])),
    };
  });

  setFormData = this.updater((state, formData: any) => {
    return {
      ...state,
      formData,
    };
  });

  resetFormData = this.updater(state => {
    return {
      ...state,
      formData: [],
    };
  });

  setSolutionId = this.updater((state, solutionId: string) => {
    return {
      ...state,
      solutionId,
    };
  });

  getEvaluatorList = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([_, state]) => state),
      switchMap(({ currentUser }) => {
        return this.businessUnitService
          .getEvaluatorList(currentUser?.businessUnit ?? '')
          .then(querySnapshot => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach(doc => {
                const data = doc.data();
                if (data['nomBu'] === currentUser?.businessUnit) {
                  this.setEvaluatorList(data['evaluators']);
                }
              });
            }

            this.setIsNotLoading();
          })
          .catch(error => {
            this.setIsNotLoading();
          });
      })
    );
  });

  getVeoliaList = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([_, state]) => state),
      switchMap(({ currentUser }) => {
        return this.solutionService
          .getVeoliaAuthorizedList(this.get().solutionName!)
          .then(querySnapshot => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach(doc => {
                const data = doc.data();
                this.setVeoliaList(data['emailVeoliaAuthorized']);
                console.log("data['emailVeoliaAuthorized']", data['emailVeoliaAuthorized'])
              });
            }

            this.setIsNotLoading();
          })
          .catch(error => {
            this.setIsNotLoading();
          });
      })
    );
  });

  createSaaSSolution = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([_, state]) => state),
      switchMap(({ formData, currentUser }) => {
        const newFormData = formData?.map((data: any) => {
          return {
            businessUnitOfSolution: currentUser?.businessUnit,
            uidInitiatorVeolia: currentUser?.uid,
            fullNameInitiatorVeolia: currentUser?.displayName,
            emailInitiatorVeolia: currentUser?.email,
            emailVeoliaAuthorized: [currentUser?.email],
            solutionOwner: currentUser?.email,
            ...data,
          };
        });
        // edit each item in formData to add currentUser info
        return from(newFormData || []);
      }),
      map(data => {
        this.solutionService
          .createSolution(data)
          .then(data => {
            this.solutionApi.notifySolutionCreated(data.id).subscribe();
            this.veoliaMessageService.create(
              {
                title: 'Created !',
                icon: 'check',
                content: 'Solution created successfully',
              },
              {
                duration: 10000,
              }
            );
            this.router.navigate(['/']);
          })
          .catch(error => {
            console.log(error);
            this.veoliaMessageService.create(
              {
                title: 'Error',
                icon: 'error',
                content: 'Error creating solution',
              },
              {
                duration: 10000,
              }
            );
          });
      }),
      switchMap(() => {
        this.resetFormData();
        return of(this.setIsNotLoading());
      })
    );
  });

  createIaaSSolution = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([_, state]) => state),
      switchMap(({ formData, currentUser }) => {
        const newFormData = formData?.map((data: any) => {
          return {
            businessUnitOfSolution: currentUser?.businessUnit,
            uidInitiatorVeolia: currentUser?.uid,
            fullNameInitiatorVeolia: currentUser?.displayName,
            emailInitiatorVeolia: currentUser?.email,
            emailVeoliaAuthorized: [currentUser?.email],
            solutionOwner: currentUser?.email,
            ...data,
          };
        });
        return from(newFormData || []);
      }),
      map(data => {
        this.solutionService
          .createSolution(data)
          .then(() => {
            this.veoliaMessageService.create(
              {
                title: 'Created !',
                icon: 'check',
                content: 'Solution created successfully',
              },
              {
                duration: 10000,
              }
            );
            this.router.navigate(['/']);
          })
          .catch(error => {
            console.log(error);
            this.veoliaMessageService.create(
              {
                title: 'Error',
                icon: 'error',
                content: 'Error creating solution',
              },
              {
                duration: 10000,
              }
            );
          });
      }),
      switchMap(() => {
        this.resetFormData();
        return of(this.setIsNotLoading());
      })
    );
  });

  updateSolution = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([_, state]) => state),
      switchMap(({ formData }) => {
        const newFormData = formData?.map((data: any) => {
          console.log('this.emailsVeoliaFromSolution',this.emailsVeoliaFromSolution);
          console.log('data.solutionOwner',data.solutionOwner);

          if (this.emailsVeoliaFromSolution?.includes(data.solutionOwner)) {
            return {
              ...data,
            };
          } else {
            this.emailsVeoliaFromSolution?.push(data.solutionOwner); //ajout du new sol owner sous emailVeoliaAuthorized
            return {
              emailVeoliaAuthorized: this.emailsVeoliaFromSolution,
              ...data,
            };
          }
        });
        return from(newFormData || []);
      }),
      map(data => {
        console.log('SOLUTION', data);
        if (this.get().solutionId) {
          this.solutionService
            .updateSolution(this.get().solutionId, data)
            .then(() => {
              //Check each form if it has been initialized to impact changes in all
              this.designFormService //DESIGN
                .get(this.get().solutionId)
                .then(res => {
                  if (res.exists()) {
                    //le form a déjà été init
                    this.designFormService.update(
                      this.get().solutionId,
                      {
                        domainOfSolution: data.domainOfSolution,
                        editorBrand: data.editorBrand,
                        link: data.link,
                        rfpName: data.rfpName,
                        solutionName: data.solutionName,
                        solutionOwner: data.solutionOwner,
                      },
                      false
                    ) as unknown as DesignForm;
                    this.formStore.setReady();
                  } else {
                    //aucune action a répercuter
                    console.log('Form not yet initialized');
                  }
                })
                .catch(error => {
                  console.log(error);
                  console.log('Form not yet initialized or erreor');
                  this.formStore.setReady();
                });

              this.legalFormService //LEGAL
                .get(this.get().solutionId)
                .then(res => {
                  if (res.exists()) {
                    //le form a déjà été init
                    this.legalFormService.update(
                      this.get().solutionId,
                      {
                        domainOfSolution: data.domainOfSolution,
                        editorBrand: data.editorBrand,
                        link: data.link,
                        rfpName: data.rfpName,
                        solutionName: data.solutionName,
                        solutionOwner: data.solutionOwner,
                      },
                      false
                    ) as unknown as LegalForm;
                    this.formStore.setReady();
                  } else {
                    //aucune action a répercuter
                    console.log('Form not yet initialized');
                  }
                })
                .catch(error => {
                  console.log(error);
                  console.log('Form not yet initialized or erreor');
                  this.formStore.setReady();
                });

              this.article28FormService //ART28
                .get(this.get().solutionId)
                .then(res => {
                  if (res.exists()) {
                    //le form a déjà été init
                    this.article28FormService.update(
                      this.get().solutionId,
                      {
                        domainOfSolution: data.domainOfSolution,
                        editorBrand: data.editorBrand,
                        link: data.link,
                        rfpName: data.rfpName,
                        solutionName: data.solutionName,
                        solutionOwner: data.solutionOwner,
                      },
                      false
                    ) as unknown as Article28Form;
                    this.formStore.setReady();
                  } else {
                    //aucune action a répercuter
                    console.log('Form not yet initialized');
                  }
                })
                .catch(error => {
                  console.log(error);
                  console.log('Form not yet initialized or erreor');
                  this.formStore.setReady();
                });

              this.article32FormService //ART32
                .get(this.get().solutionId)
                .then(res => {
                  if (res.exists()) {
                    //le form a déjà été init
                    this.article32FormService.update(
                      this.get().solutionId,
                      {
                        domainOfSolution: data.domainOfSolution,
                        editorBrand: data.editorBrand,
                        link: data.link,
                        rfpName: data.rfpName,
                        solutionName: data.solutionName,
                        solutionOwner: data.solutionOwner,
                      },
                      false
                    ) as unknown as Article32Form;
                    this.formStore.setReady();
                  } else {
                    //aucune action a répercuter
                    console.log('Form not yet initialized');
                  }
                })
                .catch(error => {
                  console.log(error);
                  console.log('Form not yet initialized or erreor');
                  this.formStore.setReady();
                });

              this.veoliaMessageService.create(
                {
                  title: 'Updated !',
                  icon: 'check',
                  content: 'Solution updated successfully',
                },
                {
                  duration: 10000,
                }
              );
              this.router.navigate(['/']);
            })
            .catch(error => {
              console.log(error);
              this.veoliaMessageService.create(
                {
                  title: 'Error',
                  icon: 'error',
                  content: 'Error updating solution',
                },
                {
                  duration: 10000,
                }
              );
            });
        }
      }),
      switchMap(() => {
        this.resetFormData();
        return of(this.setIsNotLoading());
      })
    );
  });

  get emailsVeoliaFromSolution() {
    return this.get().veoliaList;
  }
}
