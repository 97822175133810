import { Component, Input, ViewChild } from '@angular/core';
import { Solution } from 'src/app/core/models/solution';
import { AccessRightsStore } from './access-rights.store';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import SolutionApi from 'src/app/core/apis/solution.api';
import { tapResponse } from '@ngrx/component-store';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '../../../../../core/models/user';
import { AuthService } from '../../../../../core/services/auth.service';
import { Auth } from '@angular/fire/auth';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { BusinessUnitService } from 'src/app/core/services/business-unit.service';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { DesignFormService } from 'src/app/core/services/design-form.service';
import { LegalFormService } from 'src/app/core/services/legal-form.service';
import { Article32FormService } from 'src/app/core/services/article-32-form.service';
import { Article28FormService } from 'src/app/core/services/article-28-form.service';
import { DesignForm } from 'src/app/core/models/design-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { FormStore } from 'src/app/core/stores/form.store';

@Component({
  selector: 'app-access-rights',
  templateUrl: './access-rights.component.html',
  styleUrls: ['./access-rights.component.scss'],
  providers: [AccessRightsStore],
})
export class AccessRightsComponent {
  solution?: Solution;
  requester?: string;
  uid?: string;
  solName?: string;
  solOwner?: string;
  options = ['User List', 'Evaluator List', 'Access Management'];
  simpleOptions = ['User List', 'Evaluator List'];
  emptyMessage?: string;
  permissions = [
    { label: '', value: 'List', icon: 'read' },
    { label: '', value: 'Kanban', icon: 'edit' },
  ];
  protected user?: User;

  @ViewChild('accessRightsModal') modal!: VeoliaModalComponent;

  @Input() businessUnit?: BusinessUnit;

  changeSoForm:
    | FormGroup<{
        emailVeoliaAccountExisting: FormControl<string | null>;
        emailVeoliaAccountNotExisting: FormControl<string | null>;
      }>
    | undefined;

  mode = 0;
  isLoading = false;
  customPatterns = { A: { pattern: new RegExp('^[a-zA-Z0-9._%+-]+') } };

  vm$ = this.accessRightsStore.vm$;

  constructor(
    public accessRightsStore: AccessRightsStore,
    private fb: FormBuilder,
    private solutionApi: SolutionApi,
    public auth: Auth,
    public authService: AuthService,
    public businessUnitService: BusinessUnitService,
    private messageService: VeoliaMessageService,
    private designFormService: DesignFormService,
    private legalFormService: LegalFormService,
    private article32FormService: Article32FormService,
    private article28FormService: Article28FormService,
    private formStore: FormStore
  ) {}

  getUserData() {
    this.authService.userData.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }

  initForm(): void {
    this.changeSoForm = this.fb.group({
      emailVeoliaAccountExisting: [''],
      emailVeoliaAccountNotExisting: [''],
    });
    this.accessRightsStore.getUsers();
  }

  refreshSolution(): void {
    this.accessRightsStore.getSolution();
  }

  submitFormChangeSo(): void {
    if (this.changeSoForm?.valid) {
      if (this.exactlyOneHasValue()) {
        this.isLoading = true;
        this.emptyMessage = undefined;
        let newSolutionOwner = '';
        if (this.changeSoForm?.get('emailVeoliaAccountExisting')?.value !== '') {
          newSolutionOwner += this.changeSoForm?.get('emailVeoliaAccountExisting')?.value;
        }
        if (this.changeSoForm?.get('emailVeoliaAccountNotExisting')?.value !== '') {
          newSolutionOwner += this.changeSoForm?.get('emailVeoliaAccountNotExisting')?.value+'@veolia.com';
        }

        this.solutionApi
          .changeSolutionOwner({
            solutionId: this.uid!,
            emailNewSolutionOwner: newSolutionOwner!,
          })
          .pipe(
            tapResponse(
              res => {
                console.log(res);
              },
              (err: HttpErrorResponse) => {
                console.log('error', err);
              }
            )
          )
          .subscribe({
            next: () => {
              //Check each form if it has been initialized to impact the new SO in all
              this.designFormService //DESIGN
                .get(this.uid!)
                .then(res => {
                  if (res.exists()) {
                    //le form a déjà été init
                    this.designFormService.update(
                      this.uid!,
                      {
                        solutionOwner: newSolutionOwner,
                      },
                      false
                    ) as unknown as DesignForm;
                    this.formStore.setReady();
                  } else {
                    //aucune action a répercuter
                    console.log('Form not yet initialized');
                  }
                })
                .catch(error => {
                  console.log(error);
                  console.log('Form not yet initialized or erreor');
                  this.formStore.setReady();
                });

              this.legalFormService //LEGAL
                .get(this.uid!)
                .then(res => {
                  if (res.exists()) {
                    //le form a déjà été init
                    this.legalFormService.update(
                      this.uid!,
                      {
                        solutionOwner: newSolutionOwner,
                      },
                      false
                    ) as unknown as LegalForm;
                    this.formStore.setReady();
                  } else {
                    //aucune action a répercuter
                    console.log('Form not yet initialized');
                  }
                })
                .catch(error => {
                  console.log(error);
                  console.log('Form not yet initialized or erreor');
                  this.formStore.setReady();
                });

              this.article28FormService //ART28
                .get(this.uid!)
                .then(res => {
                  if (res.exists()) {
                    //le form a déjà été init
                    this.article28FormService.update(
                      this.uid!,
                      {
                        solutionOwner: newSolutionOwner,
                      },
                      false
                    ) as unknown as Article28Form;
                    this.formStore.setReady();
                  } else {
                    //aucune action a répercuter
                    console.log('Form not yet initialized');
                  }
                })
                .catch(error => {
                  console.log(error);
                  console.log('Form not yet initialized or erreor');
                  this.formStore.setReady();
                });

              this.article32FormService //ART32
                .get(this.uid!)
                .then(res => {
                  if (res.exists()) {
                    //le form a déjà été init
                    this.article32FormService.update(
                      this.uid!,
                      {
                        solutionOwner: newSolutionOwner,
                      },
                      false
                    ) as unknown as Article32Form;
                    this.formStore.setReady();
                  } else {
                    //aucune action a répercuter
                    console.log('Form not yet initialized');
                  }

                  this.resetForm();
                  this.messageService.create(
                    {
                      title: 'Solution Owner changed !',
                      icon: 'check',
                      content: 'The new solution owner has been notified',
                    },
                    { duration: 12000 }
                  );
                })
                .catch(error => {
                  console.log(error);
                  console.log('Form not yet initialized or erreor');
                  this.formStore.setReady();
                });
              this.isLoading = false;
            },
            error: (error: HttpErrorResponse) => {
              this.messageService.create(
                {
                  title: 'Error',
                  icon: 'error',
                  content: error.message,
                },
                { duration: 3000 }
              );
            },
          });
      } else {
        this.emptyMessage = 'Please fill in only one field: A or B';
      }
    }
  }

  resetForm() {
    this.changeSoForm?.reset;
    this.modal.close();
  }

  onSearch(): void {
    this.isLoading = true;
  }

  handleIndexChange(e: number): void {
    this.mode = e;
  }

  exactlyOneHasValue(): boolean {
    const form = this.changeSoForm;
    let count = 0;

    Object.keys(form!.value).forEach(key => {
      if (form?.get(key)?.value !== '' && form?.get(key)?.value !== null) {
        count++;
      }
    });

    return count === 1;
  }

  open(solution: Solution, requester: string) {
    this.accessRightsStore.setSolutionId(solution?.uid);
    this.accessRightsStore.getSolution();
    this.uid = solution?.uid;
    this.solName = solution?.solutionName;
    this.solOwner = solution?.solutionOwner;
    this.businessUnitService
      .getByName(solution.businessUnitOfSolution!)
      .then(res => {
        console.log(res.docs[0].data());
        this.businessUnit = res.docs[0].data() as BusinessUnit;
      });
    this.initForm();
    this.getUserData();
    this.modal.open();
  }

  close(): void {
    this.changeSoForm?.reset();
  }

  get userIsSoOfSolution() {
    if (this.solOwner === this.auth.currentUser?.email!) return true;
    else return false;
  }

  get isBusinessUnitEvaluator() {
    if (!this.businessUnit?.evaluators?.includes(this.auth.currentUser!.email!)) {
      return false;
    } else {
      return true;
    }
  }

  get isSolutionEvaluator() {
    return this.solution?.emailEvaluatorAuthorized.includes(
      this.auth.currentUser!.email!
    );
  }

  get canDelete() {
    return (
      this.authService.roles?.admin ||
      (this.authService.roles?.evaluator && this.isBusinessUnitEvaluator) ||
      this.isSolutionEvaluator ||
      this.userIsSoOfSolution
    );
  }
}
