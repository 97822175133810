import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { tap, withLatestFrom, map, switchMap, Observable } from 'rxjs';

import { SolutionService } from '../../../../core/services/solution.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Solution } from '../../../../core/models/solution';
import { SolutionItemsStore } from 'src/app/core/stores/solution-items.store';
import {
  SolutionItemStoreState,
  solutionItemStoreInitialState,
} from 'src/app/core/stores/states/solution-item-store.state';

const solutiontTypes: string[] = ['SaaS', 'IaaS', 'PaaS'];

@Injectable()
export class OwnedSolutionsStore extends SolutionItemsStore<
  SolutionItemStoreState<Solution>
> {
  constructor(private solutionService: SolutionService) {
    super(solutionItemStoreInitialState);
  }

  getSolutions = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(
        ({ uid, limit, solutionType, isForward, nextCursor, prevCursor }) => {
          const cursor = isForward ? nextCursor : prevCursor;

          return this.solutionService
            .getOwnedSolutions(
              uid,
              cursor,
              limit,
              solutiontTypes[solutionType],
              cursor ? isForward : true
            )
            .pipe(
              tapResponse(
                data => {
                  this.setPrevCursor(data.docs[0]);
                  this.setNextCursor(data.docs[data.docs.length - 1]);

                  const solutionDocs = data.docs.map(solution => {
                    return {
                      ...solution.data(),
                      uid: solution.id,
                    } as Solution;
                  });
                  this.setSolutions(solutionDocs);
                },
                (err: HttpErrorResponse) => {
                  this.setError(err);
                }
              )
            );
        }
      )
    );
  });

  refreshSolutions = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(
        ({ uid, limit, solutionType, isForward, nextCursor, prevCursor }) => {
          return this.solutionService
            .getOwnedSolutions(
              uid,
              prevCursor,
              limit,
              solutiontTypes[solutionType],
              true
            )
            .pipe(
              tapResponse(
                data => {
                  this.setPrevCursor(data.docs[0]);
                  this.setNextCursor(data.docs[data.docs.length - 1]);

                  const solutionDocs = data.docs.map(solution => {
                    return {
                      ...solution.data(),
                      uid: solution.id,
                    } as Solution;
                  });
                  this.setSolutions(solutionDocs);
                },
                (err: HttpErrorResponse) => {
                  this.setError(err);
                }
              )
            );
        }
      )
    );
  });

  searchSolutions = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ uid, solutionType, search }) => {
        return this.solutionService
          .searchOwnedSolutions(uid, solutiontTypes[solutionType], search)
          .pipe(
            tapResponse(
              data => {
                const solutionDocs = data.docs.map(solution => {
                  return {
                    ...solution.data(),
                    uid: solution.id,
                  } as Solution;
                });

                this.setSolutions(this.filterSearch(solutionDocs));
              },
              (err: HttpErrorResponse) => {
                this.setError(err);
              }
            )
          );
      })
    );
  });

  getCount = this.effect(trigger$ => {
    return trigger$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ uid, solutionType }) => {
        return this.solutionService
          .getCountOfOwned(uid, solutiontTypes[solutionType])
          .then(total => {
            this.setTotal(total);
          })
          .catch((err: HttpErrorResponse) => {
            this.setError(err);
          });
      })
    );
  });

  changeLimit = this.effect((limit$: Observable<number>) => {
    return limit$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([limit, state]) => ({ limit, state })),
      tap(() => this.setPageReset(1)),
      tap(({ limit }) => {
        this.setLimit(limit);
      }),
      tap(() => this.getSolutions())
    );
  });

  changeSolutionType = this.effect((solutionType$: Observable<number>) => {
    return solutionType$.pipe(
      tap(() => {
        this.setPageReset(1);
      }),
      withLatestFrom(this.select(state => state)),
      map(([solutionType, state]) => ({ solutionType, state })),
      tap(({ solutionType }) => {
        this.setSolutionType(solutionType);
      }),
      tap(() => this.getCount()),
      tap(() => this.getSolutions())
    );
  });

  changePage = this.effect((page$: Observable<number>) => {
    return page$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([page, state]) => ({ page, state })),
      tap(({ page }) => {
        this.setPage(page);
      }),
      tap(() => this.getSolutions())
    );
  });

  changeSearch = this.effect((search$: Observable<string>) => {
    return search$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([search, state]) => ({ search, state })),
      tap(({ search }) => {
        this.setSearch(search);
      }),
      tap(({ search }) => {
        if (search.length > 0) {
          return this.searchSolutions();
        } else {
          this.setPageReset(1);
          return this.getSolutions();
        }
      })
    );
  });

  fetchData = () => {
    this.getCount();
    this.getSolutions();
  };
}
