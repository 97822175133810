import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Solution } from 'src/app/core/models/solution';
import { DomainService } from 'src/app/core/services/domain.service';
import { format } from 'date-fns';

@Component({
  selector: 'app-demand-form-page',
  templateUrl: './demand-form-edit-page.component.html',
  styleUrls: ['./demand-form-edit-page.component.scss'],
})
export class DemandFormEditPageComponent implements OnInit {
  selectedSolution?: Solution;
  selectedId?: string = '1';
  stepOneUnlocked = true;
  stepTwoUnlocked = false;
  stepThreeUnlocked = false;

  form = new FormGroup({});
  model: any = { disclaimer: 'Yes' };
  options: FormlyFormOptions = {};

  yesOrNoOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  yesOrNoIDKOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: "I don't know", label: "I don't know" },
  ];

  accessQuestionOptions = [
    {
      value:
        'Veolia employees (internal or external, with a veolia.com account)',
      label:
        'Veolia employees (internal or external, with a veolia.com account)',
    },
    {
      value: 'Veolia customers (municipalities...)',
      label: 'Veolia customers (municipalities...)',
    },
    { value: 'Veolia Partners', label: 'Veolia Partners' },
    {
      value: 'Public (specify if there is authentication)',
      label: 'Public (specify if there is authentication)',
    },
  ];

  communicationQuestionOptions = [
    {
      value: 'REST API capabilities are required',
      label: 'REST API capabilities are required',
    },
    {
      value: 'File Transfer capabilities are required',
      label: 'File Transfer capabilities are required',
    },
    {
      value: 'Offline capabilities are required',
      label: 'Offline capabilities are required',
    },
    {
      value: 'Sensitive personal data are going to be accessed or stored',
      label: 'Sensitive personal data are going to be accessed or stored',
    },
    {
      value: 'Sensitive business data are going to be accessed or stored',
      label: 'Sensitive business data are going to be accessed or stored',
    },
    {
      value: 'N/A',
      label: 'N/A',
    },
  ];

  fields = [
    {
      type: 'stepper-edit',
      fieldGroup: [
        {
          props: { label: 'Informations about your request' },
          fieldGroup: [
            {
              key: 'disclaimer',
              type: 'disclaimer',
              props: {
                options: [{ label: 'I agree', value: 'Yes' }],
              },
            },
            {
              key: 'Q0_A',
              type: 'radio',
              props: {
                label: 'A. The solution is provided (developed) by the Vendor',
                options: this.yesOrNoIDKOptions,
                required: true,
              },
            },
            {
              key: 'Q0_B',
              type: 'radio',
              props: {
                label:
                  'B. The solution is to be hosted in Veolia Public Cloud (Amazon Web Services or Google Cloud Platform)',
                options: this.yesOrNoIDKOptions,
                required: true,
              },
            },
            {
              key: 'Q0_C',
              type: 'radio',
              props: {
                label:
                  'C. The solution offers a mobile app, a chrome extension or a Google add-on',
                options: this.yesOrNoIDKOptions,
                required: true,
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 1',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'Yes' &&
                    field.model.Q0_B === 'Yes' &&
                    field.model.Q0_C === 'Yes'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 2',
              },
              expressions: {
                hide: "!(model.Q0_A === 'Yes' && model.Q0_B === 'Yes' && model.Q0_C === 'No')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 1',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'No' &&
                    field.model.Q0_B === 'Yes' &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 1',
              },
              expressions: {
                hide: "!(model.Q0_A === 'Yes' && model.Q0_B === 'No' && model.Q0_C === 'Yes')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 2',
              },
              expressions: {
                hide: "!(model.Q0_A === 'Yes' && model.Q0_B === 'No' && model.Q0_C === 'No')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 3',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'Yes' &&
                    field.model.Q0_B === 'No' &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 4',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'Yes' &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === 'Yes'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 5',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'Yes' &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === 'No'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 6',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'Yes' &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 4',
              },
              expressions: {
                hide: "!(model.Q0_A === 'No' && model.Q0_B === 'Yes' && model.Q0_C === 'Yes')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 5',
              },
              expressions: {
                hide: "!(model.Q0_A === 'No' && model.Q0_B === 'Yes' && model.Q0_C === 'No')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 5',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'No' &&
                    field.model.Q0_B === 'Yes' &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'Apps type 1',
              },
              expressions: {
                hide: "!(model.Q0_A === 'No' && model.Q0_B === 'No' && model.Q0_C === 'Yes')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'Idk type 1',
              },
              expressions: {
                hide: "!(model.Q0_A === 'No' && model.Q0_B === 'No' && model.Q0_C === 'No')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'Apps type 2',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'No' &&
                    field.model.Q0_B === 'No' &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 4',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'No' &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === 'Yes'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 5',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'No' &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === 'No'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 5',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'No' &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 4',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === 'Yes' &&
                    field.model.Q0_C === 'Yes'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 5',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === 'Yes' &&
                    field.model.Q0_C === 'No'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 5',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === 'Yes' &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 1',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === 'No' &&
                    field.model.Q0_C === 'Yes'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 7',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === 'No' &&
                    field.model.Q0_C === 'No'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 7',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === 'No' &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'Apps type 3',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === 'Yes'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'Idk type 2',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === 'No'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'Idk type 3',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'iaas-info',
              props: {
                type: 'info',
                text: 'For IaaS & PaaS DLA requests, please check DLA for XaaS e-Norms',
              },
              expressions: {
                hide: 'model.Q0_A === undefined || model.Q0_B === undefined || model.Q0_C === undefined',
              },
            },
            {
              key: 'type',
              type: 'radio',
              props: {
                label: 'Solution type',
                options: [
                  { value: 'SaaS', label: 'SaaS' },
                  { value: 'IaaS', label: 'IaaS', disabled: true },
                  { value: 'PaaS', label: 'PaaS', disabled: true },
                  {
                    value: 'Apps/Extensions/Add-ons',
                    label: 'Apps/Extensions/Add-ons',
                    disabled: true,
                  },
                ],
                required: true,
              },
              expressions: {
                hide: 'model.Q0_A === undefined || model.Q0_B === undefined || model.Q0_C === undefined',
              },
            },
          ],
        },
        {
          props: { label: 'Primary informations' },
          fieldGroup: [
            {
              key: 'fullNameInitiatorVeolia',
              type: 'input',
              props: {
                label: "Initiator's full name",
                disabled: true,
              },
            },
            {
              key: 'emailInitiatorVeolia',
              type: 'input',
              props: {
                label: "Initiator's email",
                type: 'email',
                disabled: true,
              },
            },
            {
              key: 'solutionOwner',
              type: 'input',
              props: {
                label: 'Current solution owner',
                required: true,
              },
            },
            {
              key: 'businessUnitOfSolution',
              type: 'input',
              props: {
                label: 'Business unit',
                disabled: true,
              },
            },
          ],
        },
        {
          props: { label: 'Tell us more about your need ?' },
          fieldGroup: [
            {
              template: `<div>
              <p>Once your request is created, the process is the following:</p>
              <ul>
                <li>
                  1-Your request is pending approval by DLA team
                  <p>
                    The forms are not yet available<br>
                    The vendor is not yet notified<br>
                    You can still edit your request informations<br>
                  </p>
                </li>
                <li>
                  2-Your request has been validated by DLA team
                  <p>
                    As the Solution Owner, you are notified<br>
                    The vendor's account is created and access is granted<br>
                    The vendor must complete the forms (Design/Legal/Article 32) and allow Veolia to scan the solution<br>
                    As the Solution Owner, you must revive the vendor if the request remains incomplete (cf. <a href="https://docs.google.com/presentation/d/1kqMltFibPvQjwLI2bcVVdQAQ2w7Stf-MIw78wsZUkOI/edit?pli=1#slide=id.g85055ddc33_0_1700">DLAaaS Solution owner guide</a>)<br>
                  </p>
                </li>
              </ul>
              </div>`,
            },
            {
              key: 'requestDate',
              type: 'nz-datepicker',
              props: {
                label: 'Request date',
                disabled: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'rfpName',
              type: 'nz-input',
              props: {
                label: 'RFP name/Project name',
                required: true,
                maxLength: 20,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'domainOfSolution',
              type: 'nz-select',
              props: {
                label: 'Domain of solution',
                options: this.domainService.observeAll(),
                required: true,
                valueProp: 'domainName',
                labelProp: 'domainName',
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              template:
                '<a href="https://docs.google.com/spreadsheets/d/1GmhGkvDij4reoV0GL9r3PJLEJutSOi4iovac-L9b71o/edit#gid=1430216237" target="_blank">Business Capabilities definitions</a>',
            },
            {
              template: '<br>',
            },
            {
              key: 'description',
              type: 'nz-textarea',
              props: {
                label:
                  'Can you describe the need ? What is the purpose of the solution ?',
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'accessQuestion',
              type: 'nz-checkbox',
              props: {
                label: 'Who will access/use the solution ?',
                multiple: true,
                options: this.accessQuestionOptions,
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'integrateQuestion',
              type: 'radio',
              props: {
                label:
                  'Will the solution have to integrate within Google Workspace? (Drive, GMail, Doc, Sheet...) ?',
                options: this.yesOrNoOptions,
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'interconnectQuestion',
              type: 'nz-checkbox',
              props: {
                label:
                  'Do you know if the solution will have to interconnect with 3rd party applications ?',
                multiple: true,
                options: [
                  {
                    value: 'Yes, with other SaaS',
                    label: 'Yes, with other SaaS',
                  },
                  {
                    value: 'Yes, with Veolia IaaS or PaaS solution',
                    label: 'Yes, with Veolia IaaS or PaaS solution',
                  },
                  { value: 'No', label: 'No' },
                  { value: "I don't know", label: "I don't know" },
                ],
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'interconnectQuestion2',
              type: 'nz-textarea',
              props: {
                label:
                  'Please give more details about interconnection with 3rd party applications',
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'communicationQuestion',
              type: 'nz-checkbox',
              props: {
                label: 'Select the following statements if they apply to your need',
                options: this.communicationQuestionOptions,
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'expectedCompletionDate',
              type: 'nz-datepicker',
              props: {
                label:
                  'Expected date of completion of documents (completion by vendor)',
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
              validators: {
                validation: ['IsCompletionAfterEvaluationDate'],
              },
            },
            {
              key: 'expectedEvaluationDate',
              type: 'nz-datepicker',
              props: {
                label:
                  'Expected evaluation date (Please allow 3 weeks once the documents are completed)',
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
              validators: {
                validation: ['IsEvaluationBeforeCompletionDate'],
              },
            },
            {
              key: 'expectedOnlineReleaseDate',
              type: 'nz-datepicker',
              props: {
                label: 'Expected date of on-line release',
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'q0a_iaas',
              type: 'radio',
              props: {
                label:
                  'If it is a new solution, do you confirm that NO SaaS solution exists on the market for that need & that the editor doesn’t offer and doesn’t plan to offer a SaaS mode for its solution ?',
                options: this.yesOrNoOptions,
                required: true,
              },
              expressions: {
                hide: 'model.type === "SaaS"',
              },
            },
            {
              key: 'q0b_iaas',
              type: 'radio',
              props: {
                label:
                  'The purpose of this DLA for IaaS request is the study of the lift&shift of a legacy “on prem” solution.',
                options: this.yesOrNoOptions,
                required: true,
              },
              expressions: {
                hide: 'model.type === "SaaS"',
              },
            },
            {
              key: 'nbSolution',
              type: 'nz-input',
              props: {
                label: 'Number of solutions to be evaluated',
                type: 'number',
                min: 1,
                max: 4,
                required: true,
                disabled: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
          ],
        },
        {
          props: { label: 'Candidate solution' },
          hideExpression: 'model.type !== "SaaS"',
          fieldGroup: [
            {
              template: `<h3 class="centered center">
              Solution
              </h3>`,
            },
            {
              key: 'solutionName',
              type: 'input',
              props: {
                label: 'Name of the solution to be evaluated',
                required: true,
                maxLength: 20,
              },
            },
            {
              key: 'editorBrand',
              type: 'input',
              props: {
                label: 'Vendor brand name',
                required: true,
                maxLength: 20,
              },
            },
            // {
            //   key: 'description',
            //   type: 'nz-textarea',
            //   props: {
            //     label: 'Can you describe your need ?',
            //     required: true,
            //   },
            // },
            // {
            //   key: 'domainOfSolution',
            //   type: 'nz-select',
            //   props: {
            //     label:
            //       'What domain / business capability does the solution depend on ? ',
            //     options: this.domainService.observeAll(),
            //     required: true,
            //     valueProp: 'domainName',
            //     labelProp: 'domainName',
            //   },
            // },
            {
              key: 'inPlace',
              type: 'radio',
              props: {
                label: 'Solution is already in use / DLA renewal ?',
                options: this.yesOrNoIDKOptions,
              },
            },
            {
              key: 'apmNumber',
              type: 'input',
              props: {
                label:
                  'If the solution is already in use, please provide its APM number (if available)',
              },
            },
            {
              key: 'link',
              type: 'input',
              props: {
                label: 'Vendor website url (SaaS solution offered to Veolia)',
              },
            },
            {
              type: 'divider',
            },
            {
              template: `<h3 class="centered center">
              Vendor contact for the solution
              </h3>`,
            },
            {
              key: 'mainEmailVendor',
              type: 'input',
              props: {
                label: 'Vendor email',
                type: 'email',
                required: true,
              },
              validators: {
                validation: ['no-generic-email', 'no-blocked-word'],
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'mainLastnameVendor',
              type: 'input',
              props: {
                label: 'Vendor lastname',
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'mainFirstnameVendor',
              type: 'input',
              props: {
                label: 'Vendor firstname',
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'mainCompanyVendor',
              type: 'input',
              props: {
                label: 'Vendor company',
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'linkCom',
              type: 'input',
              props: {
                label: 'Vendor website URL (of the commercial website)',
              },
              validators: {
                validation: ['no-https-and-www'],
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
          ],
        },
      ],
    },
  ];

  constructor(
    private domainService: DomainService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.selectedSolution = data['solution'] as Solution;

      const dates: any = {}; // Define the type of dates as {}

      if (this.selectedSolution?.expectedCompletionDate) {
        dates['expectedCompletionDate'] = format(
          new Date(this.selectedSolution.expectedCompletionDate.toDate()),
          'yyyy-MM-dd'
        );
      }

      if (this.selectedSolution?.expectedEvaluationDate) {
        dates['expectedEvaluationDate'] = format(
          new Date(this.selectedSolution.expectedEvaluationDate.toDate()),
          'yyyy-MM-dd'
        );
      }

      if (this.selectedSolution?.expectedOnlineReleaseDate) {
        dates['expectedOnlineReleaseDate'] = format(
          new Date(this.selectedSolution.expectedOnlineReleaseDate.toDate()),
          'yyyy-MM-dd'
        );
      }

      if (this.selectedSolution?.requestDate) {
        dates['requestDate'] = format(
          new Date(this.selectedSolution.requestDate.toDate()),
          'yyyy-MM-dd'
        );
      }

      this.model = { ...this.model, ...this.selectedSolution, ...dates };
    });
  }

  setSelected(id: string) {
    this.selectedId = id;
  }

  submit() {
    alert(JSON.stringify(this.model));
  }

  backToHome() {
    this.router.navigate(['/']);
  }
}
