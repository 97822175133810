import { Article28Form } from '../../models/article-28-form';
import { Article32Form } from '../../models/article-32-form';
import { ChapterStatus } from '../../models/chapter-status';
import { Comment } from '../../models/comment';
import { DesignForm } from '../../models/design-form';
import { Evaluation } from '../../models/evalutation';
import { FileItem } from '../../models/file-item';
import { LegalForm } from '../../models/legal-form';
import { Reservation } from '../../models/reservation';
import { Solution } from '../../models/solution';
import { BusinessUnit } from '../../models/business-unit';
import { IaasForm } from '../../models/iaas-form';

export type FormStoreState = {
  businessUnit?: BusinessUnit;
  comments: Record<string, Comment[]>;
  error?: boolean;
  evaluation?: Evaluation;
  evaluationIsLoading?: boolean;
  files: Record<string, FileItem[]>;
  fileList: FileItem[];
  form?: Article28Form | Article32Form | DesignForm | LegalForm | IaasForm;
  formName: string;
  message?: string;
  ready: boolean;
  reservations: Record<string, Reservation>;
  solution?: Solution;
  solutionId: string;
  statuses: Record<string, ChapterStatus>;
};

export const formStoreInitialState = {
  comments: {},
  files: {},
  fileList: [],
  formName: '',
  ready: false,
  reservations: {},
  solutionId: '',
  statuses: {},
};
