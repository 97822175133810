<veolia-modal #modal>
  <div veolia-modal-title>
    {{ 'Files of' | translate }}
    <strong> {{ solution?.solutionName }} - {{ formName }} </strong>
  </div>
  <div veolia-modal-content>
    <nz-collapse>
      <nz-collapse-panel
        (nzActiveChange)="opened = i"
        [nzActive]="i === opened"
        *ngFor="let field of fields(); let i = index"
        [nzHeader]="field | translate">
        <p style="margin: 0">
          <nz-list *ngIf="files">
            <nz-list-item *ngFor="let item of files[field]!">
              <app-file-summary-item [file]="item" />
            </nz-list-item>
          </nz-list>
        </p>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-list-empty
      nzNoResult="No files uploaded"
      *ngIf="fields()!.length === 0"></nz-list-empty>
    <nz-list-empty *ngIf="fields()!.length === 0"></nz-list-empty>
  </div>
</veolia-modal>
