import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
  signal,
} from '@angular/core';
import { Solution } from 'src/app/core/models/solution';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { Evaluation } from 'src/app/core/models/evalutation';
import { FormName } from 'src/app/core/enums/form-name';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { DesignForm } from 'src/app/core/models/design-form';
import { IaasForm } from 'src/app/core/models/iaas-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { FileItem } from 'src/app/core/models/file-item';
import { EvaluationSummaryComponent } from '../../../evaluation-summary/components/evaluation-summary/evaluation-summary.component';
import { CommentSummaryComponent } from '../../../comment-summary/components/comment-summary/comment-summary.component';
import { FileSummaryComponent } from '../../../file-summary/components/file-summary/file-summary.component';
import { SolutionInfoSummaryComponent } from '../../../solution-info-summary/components/solution-info-summary.component';
import { Comment } from 'src/app/core/models/comment';
import { AuthService } from 'src/app/core/services/auth.service';
import { Auth } from '@angular/fire/auth';
import { FileApi } from 'src/app/core/apis/file.api';

@Component({
  selector: 'app-form-toolbar',
  templateUrl: './form-toolbar.component.html',
  styleUrls: ['./form-toolbar.component.scss'],
})
export class FormToolbarComponent {
  @Input() solution?: Solution;
  @Input() businessUnit?: BusinessUnit;
  @Input() evaluation?: Evaluation;
  @Input() comments?: Record<string, Comment[]>;
  @Input() files?: Record<string, FileItem[]>;
  @Input() fileList?: FileItem[];
  @Input() currentForm?:
    | Article28Form
    | Article32Form
    | DesignForm
    | IaasForm
    | LegalForm;
  @Input() formName?: FormName;
  @Input() formCompletionStatus?: boolean;
  @Input() formStatus?: string;

  @Output() saveAll: EventEmitter<void> = new EventEmitter<void>();
  @Output() markAsComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refreshForm: EventEmitter<void> = new EventEmitter<void>();

  showEvaluationModal = signal(false);
  showCommentsModal = signal(false);
  showFilesModal = signal(false);

  @ViewChild('commentSummary')
  commentSummary!: CommentSummaryComponent;

  @ViewChild('evaluationSummary')
  evaluationSummary!: EvaluationSummaryComponent;

  @ViewChild('fileSummary')
  fileSummary!: FileSummaryComponent;

  @ViewChild('solutionInfoSummaryModal')
  solutionInfoSummary!: SolutionInfoSummaryComponent;

  constructor(
    private auth: Auth,
    private authService: AuthService,
    private fileApi: FileApi,
    public viewContainerRef: ViewContainerRef
  ) {}

  toggleInfoModal() {
    this.solutionInfoSummary.open(this.solution!);
  }

  toggleCommentsModal() {
    this.commentSummary.open();
    this.showCommentsModal.set(!this.showCommentsModal());
  }

  toggleEvaluationModal() {
    this.evaluationSummary.open();
    this.showEvaluationModal.set(!this.showEvaluationModal());
  }

  toggleFilesModal() {
    this.fileSummary.open();
    this.showFilesModal.set(!this.showFilesModal());
  }

  get isVendor() {
    return (
      this.solution?.emailVendorAuthorized.includes(
        this.auth.currentUser?.email!
      ) && !this.isVendorReadOnly(this.formName!)
    );
  }

  isVendorReadOnly(formName: string) {
    switch (formName) {
      case 'Article 28 Form':
        return this.solution?.emailVendorReadonlyArt28?.includes(
          this.auth.currentUser?.email!
        );
      case 'Article 32 Form':
        return this.solution?.emailVendorReadonlyArt32?.includes(
          this.auth.currentUser?.email!
        );
      case 'Design Form':
        return this.solution?.emailVendorReadonlyDesign?.includes(
          this.auth.currentUser?.email!
        );
      case 'Legal Form':
        return this.solution?.emailVendorReadonlyLegal?.includes(
          this.auth.currentUser?.email!
        );
      default:
        return false;
    }
  }

  get isViewer() {
    return this.authService.roles?.viewer;
  }

  get isAdmin() {
    return this.authService.roles?.admin;
  }

  get userIsSoOfSolution() {
    return this.solution?.solutionOwner === this.auth.currentUser!.email!;
  }

  get isBusinessUnitEvaluator() {
    if (
      this.businessUnit?.evaluators.includes(this.auth.currentUser!.email!) &&
      this.authService.roles?.evaluator
    ) {
      return true;
    } else {
      return false;
    }
  }

  get isSolutionEvaluator() {
    return this.solution?.emailEvaluatorAuthorized.includes(
      this.auth.currentUser!.email!
    );
  }

  get hasFullRights() {
    return (
      this.isAdmin || this.isBusinessUnitEvaluator || this.isSolutionEvaluator
    );
  }

  get isSubmittable() {
    return (
      this.isSolutionEvaluator || this.isAdmin || this.isBusinessUnitEvaluator
    );
  }

  get isCompletable() {
    return (
      this.hasFullRights ||
      this.userIsSoOfSolution ||
      (this.isVendor && !this.isVendorReadOnly(this.formName!))
    );
  }

  generateFormattedDate(): string {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  generatePDF() {
    this.fileApi
      .exportFormPDF(this.solution?.uid!, {
        formName: this.formName!,
      })
      .subscribe({
        next: response => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          let fileName = '';

          if (this.formName === 'Article 28 Form') {
            fileName = 'Annexes';
          } else {
            fileName = this.formName!;
          }

          link.download = `${fileName}-${this.solution?.solutionName!}-${this.generateFormattedDate()}.pdf`;
          link.click();
          window.URL.revokeObjectURL(url);
        },
        error: error => {
          console.error('Error downloading PDF:', error);
        },
      });
  }
}
