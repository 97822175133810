import { Component, Input, OnInit, ViewChild, inject, signal } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import {
  VeoliaModalComponent,
  VeoliaModalService,
} from '@veolia.com/vds-angular-components/modal';
import { FormName } from 'src/app/core/enums/form-name';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { DesignForm } from 'src/app/core/models/design-form';
import { Evaluation } from 'src/app/core/models/evalutation';
import { FileItem } from 'src/app/core/models/file-item';
import { IaasForm } from 'src/app/core/models/iaas-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { Reservation } from 'src/app/core/models/reservation';
import { Solution } from 'src/app/core/models/solution';
import { Article28FormService } from 'src/app/core/services/article-28-form.service';
import { Article32FormService } from 'src/app/core/services/article-32-form.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DesignFormService } from 'src/app/core/services/design-form.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { FileService } from 'src/app/core/services/file.service';
import { IaaSFormService } from 'src/app/core/services/iaas-form.service';
import { LegalFormService } from 'src/app/core/services/legal-form.service';
import { ReservationService } from 'src/app/core/services/reservation.service';
import { getTagColor } from 'src/app/core/utils/colors';
import { convertDictToList } from 'src/app/core/utils/common';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { TranslateService } from '@ngx-translate/core';
import { SolutionService } from 'src/app/core/services/solution.service';
import { EvaluationSummaryComponent } from 'src/app/shared/features/evaluation-summary/components/evaluation-summary/evaluation-summary.component';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { SummaryReservationOverviewComponent } from './components/summary-reservation-overview/summary-reservation-overview.component';

@Component({
  selector: 'app-assessments-summary-page',
  templateUrl: './assessments-summary-page.component.html',
  styleUrls: ['./assessments-summary-page.component.scss'],
})
export class AssessmentsSummaryPageComponent
  extends DataTableComponent
  implements OnInit
{
  @ViewChild('formModal')
  formModal!: VeoliaModalComponent;

  @ViewChild('evaluationSummaryModal')
  evaluationSummary!: EvaluationSummaryComponent;

  @ViewChild('summaryReservationOverview')
  summaryReservationOverview!: SummaryReservationOverviewComponent;

  @Input() businessUnit?: BusinessUnit;
  @Input() files?: Record<string, FileItem[]>;

  selectedSolution: Solution = {} as Solution;
  // Forms
  formNameEnum = FormName;
  formName?: string;
  formStatus = '';
  designForm?: DesignForm;
  legalForm?: LegalForm;
  art32Form?: Article32Form;
  art28Form?: Article28Form;
  iaaSForm?: IaasForm;
  currentForm?:
    | Article28Form
    | Article32Form
    | DesignForm
    | IaasForm
    | LegalForm;

  // Evaluations
  art28FormEvaluation?: Evaluation;
  art32FormEvaluation?: Evaluation;
  designFormEvaluation?: Evaluation;
  legalFormEvaluation?: Evaluation;
  currentEvaluation?: Evaluation;
  iaaSFormEvaluation?: Evaluation;

  // Reservations
  art28FormReservations: Reservation[] = [];
  art32FormReservations: Reservation[] = [];
  designFormReservations: Reservation[] = [];
  legalFormReservations: Reservation[] = [];
  iaaSFormReservations: Reservation[] = [];
  currentReservations: Reservation[] = [];

  art28FormFiles?: FileItem[] = [];
  art32FormFiles?: FileItem[] = [];
  designFormFiles?: FileItem[] = [];
  legalFormFiles?: FileItem[] = [];
  iaasFormFiles?: FileItem[] = [];
  currentFileList?: FileItem[] = [];

  //showEvaluationModal = false;
  showEvaluationModal = signal(false);
  showReservationModal = false;
  showReservationOverviewModal = false;

  #service = inject(VeoliaModalService);

  constructor(
    private auth: Auth,
    private route: ActivatedRoute,
    private art28FormService: Article28FormService,
    private art32FormService: Article32FormService,
    private designFormService: DesignFormService,
    private evaluationService: EvaluationService,
    private fileService: FileService,
    private iaasFormService: IaaSFormService,
    private legalFormService: LegalFormService,
    private reservationService: ReservationService,
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    public override solutionService: SolutionService
  ) {
    super(authService, router, translateService, solutionService);
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.businessUnit = data['businessUnit'] as BusinessUnit;
      this.selectedSolution = data['solution'] as Solution;
      this.getArt28Form();
      this.getArt32Form();
      this.getDesignForm();
      this.getLegalForm();
      this.getIaaSForm();
    });
  }

  getArt28Form() {
    this.art28FormService.get(this.selectedSolution?.uid).then(art28Form => {
      if (art28Form.exists()) {
        this.art28Form = {
          uid: art28Form.id,
          ...art28Form.data(),
        } as Article28Form;
        this.getFormEvaluation(FormName.Article28, this.selectedSolution?.uid);
        this.getFormReservations(
          FormName.Article28,
          this.selectedSolution?.uid
        );
        this.getFormFiles(FormName.Article28, this.selectedSolution?.uid);
      }
    });
  }

  getArt32Form() {
    this.art32FormService.get(this.selectedSolution?.uid).then(art32Form => {
      if (art32Form.exists()) {
        this.art32Form = {
          uid: art32Form.id,
          ...art32Form.data(),
        } as Article32Form;
        this.getFormEvaluation(FormName.Article32, this.selectedSolution?.uid);
        this.getFormReservations(
          FormName.Article32,
          this.selectedSolution?.uid
        );
      }
    });
  }

  getDesignForm() {
    this.designFormService.get(this.selectedSolution?.uid).then(designForm => {
      if (designForm.exists()) {
        this.designForm = {
          uid: designForm.id,
          ...designForm.data(),
        } as DesignForm;
        this.getFormEvaluation(FormName.Design, this.selectedSolution?.uid);
        this.getFormReservations(FormName.Design, this.selectedSolution?.uid);
        this.getFormFiles(FormName.Design, this.selectedSolution?.uid);
      }
    });
  }

  getLegalForm() {
    this.legalFormService.get(this.selectedSolution?.uid).then(legalForm => {
      if (legalForm.exists()) {
        this.legalForm = {
          uid: legalForm.id,
          ...legalForm.data(),
        } as LegalForm;
        this.getFormEvaluation(FormName.Legal, this.selectedSolution?.uid);
        this.getFormReservations(FormName.Legal, this.selectedSolution?.uid);
      }
    });
  }

  getIaaSForm() {
    this.iaasFormService.get(this.selectedSolution?.uid).then(iaasForm => {
      if (iaasForm.exists()) {
        this.iaaSForm = iaasForm.data() as IaasForm;
        this.getFormEvaluation(FormName.IAAS, this.selectedSolution?.uid);
        this.getFormReservations(FormName.IAAS, this.selectedSolution?.uid);
        this.getFormFiles(FormName.IAAS, this.selectedSolution?.uid);
      }
    });
  }

  openFormInfoModal(
    formName: FormName,
    form: Article28Form | Article32Form | DesignForm | IaasForm | LegalForm
  ) {
    this.currentForm = form;
    this.formName = formName;

    if (formName === FormName.Design) {
      if (this.designForm) {
        this.formStatus = this.designForm?.designStatus;
      }
      this.currentEvaluation = this.designFormEvaluation;
    } else if (formName === FormName.Legal) {
      if (this.legalForm) {
        this.formStatus = this.legalForm?.legalStatus;
      }
      this.currentEvaluation = this.legalFormEvaluation;
    } else if (formName === FormName.Article28) {
      if (this.art28Form) {
        this.formStatus = this.art28Form?.article28Status;
      }
      this.currentEvaluation = this.art28FormEvaluation;
    } else if (formName === FormName.Article32) {
      if (this.art32Form) {
        this.formStatus = this.art32Form?.article32Status;
      }
      this.currentEvaluation = this.art32FormEvaluation;
    } else if (formName === FormName.IAAS) {
      if (this.iaaSForm) {
        this.formStatus = this.iaaSForm?.iaasStatus;
      }
      this.currentEvaluation = this.iaaSFormEvaluation;
    }

    this.formModal.open(); //for 'about this form' in summary/id page
  }

  openEvaluationModal(
    formName: FormName,
    form: Article28Form | Article32Form | DesignForm | IaasForm | LegalForm
  ) {
    this.currentForm = form;
    this.formName = formName;

    if (formName === FormName.Article28) {
      this.currentEvaluation = this.art28FormEvaluation;
      this.currentFileList = this.art28FormFiles;
    } else if (formName === FormName.Article32) {
      this.currentEvaluation = this.art32FormEvaluation;
      this.currentFileList = this.art32FormFiles;
    } else if (formName === FormName.Design) {
      this.currentEvaluation = this.designFormEvaluation;
      this.currentFileList = this.designFormFiles;
    } else if (formName === FormName.Legal) {
      this.currentEvaluation = this.legalFormEvaluation;
      this.currentFileList = this.legalFormFiles;
    } else if (formName === FormName.IAAS) {
      this.currentEvaluation = this.iaaSFormEvaluation;
      this.currentFileList = this.iaasFormFiles;
    }

    //this.showEvaluationModal = true;
    this.evaluationSummary.open(this.formName);
    this.showEvaluationModal.set(!this.showEvaluationModal());
  }

  // closeEvaluationModal() {
  //   this.showEvaluationModal = false;
  // }

  openReservationModal(form: string) {
    this.formName = form;

    if (form === FormName.Article28) {
      this.currentReservations = this.art28FormReservations;
    } else if (form === FormName.Article32) {
      this.currentReservations = this.art32FormReservations;
    } else if (form === FormName.Design) {
      this.currentReservations = this.designFormReservations;
    } else if (form === FormName.Legal) {
      this.currentReservations = this.legalFormReservations;
    } else if (form === FormName.IAAS) {
      this.currentReservations = this.iaaSFormReservations;
    }

    this.showReservationModal = true;
  }

  closeReservationModal() {
    this.showReservationModal = false;
  }

  openReservationOverviewModal(form: string) {
    this.formName = form;

    if (form === FormName.Article28) {
      this.currentReservations = this.art28FormReservations;
    } else if (form === FormName.Article32) {
      this.currentReservations = this.art32FormReservations;
    } else if (form === FormName.Design) {
      this.currentReservations = this.designFormReservations;
    } else if (form === FormName.Legal) {
      this.currentReservations = this.legalFormReservations;
    } else if (form === FormName.IAAS) {
      this.currentReservations = this.iaaSFormReservations;
    }

    this.summaryReservationOverview.open();
    // this.showReservationOverviewModal = true;
  }

  closeReservationOverviewModal() {
    this.showReservationOverviewModal = false;
  }

  getFormEvaluation(form: string, solutionId: string) {
    this.evaluationService
      .getEvaluations(solutionId, form)
      .then(evaluations => {
        const evals = evaluations as Record<string, unknown>;

        if (evals) {
          const evalDatas = convertDictToList(evals);

          if (form === FormName.Article28) {
            this.art28FormEvaluation = evalDatas[0] as Evaluation;
          }

          if (form === FormName.Article32) {
            this.art32FormEvaluation = evalDatas[0] as Evaluation;
          }

          if (form === FormName.Design) {
            this.designFormEvaluation = evalDatas[0] as Evaluation;
          }

          if (form === FormName.Legal) {
            this.legalFormEvaluation = evalDatas[0] as Evaluation;
          }

          if (form === FormName.IAAS) {
            this.iaaSFormEvaluation = evalDatas[0] as Evaluation;
          }
        }
      })
      .catch(error => {
        // this.notificationService.error('error', error + ' ' + form);
      });
  }

  getFormReservations(form: string, solutionId: string) {
    this.reservationService
      .getReservations(solutionId, form)
      .then(reservations => {
        const reservs = reservations as Record<string, unknown>;
        if (reservs) {
          const reservsDatas = convertDictToList(reservs);
          const reservations: Reservation[] = [];

          reservsDatas.forEach(rD => {
            Object.keys(rD).forEach(k => {
              if (typeof rD[k] === 'object') {
                reservations.push({ idRef: k, ...rD[k] });
              }
            });
          });

          if (form === FormName.Article28) {
            this.art28FormReservations = reservations;
          }

          if (form === FormName.Article32) {
            this.art32FormReservations = reservations;
          }

          if (form === FormName.Design) {
            this.designFormReservations = reservations;
          }

          if (form === FormName.Legal) {
            this.legalFormReservations = reservations;
          }

          if (form === FormName.IAAS) {
            this.iaaSFormReservations = reservations;
          }
        }
      })
      .catch(error => {
        // this.notificationService.error('error', error + ' ' + form);
      });
  }

  getFormFiles(formName: string, solutionId: string) {
    this.fileService
      .getFiles(formName, solutionId)
      .then(files => {
        const fileList = files as Record<string, File>;

        if (fileList) {
          const filesList = convertDictToList(fileList);
          if (formName === FormName.Article28) {
            this.art28FormFiles = filesList;
          }

          if (formName === FormName.Article32) {
            this.art32FormFiles = filesList;
          }

          if (formName === FormName.Design) {
            this.designFormFiles = filesList;
          }

          if (formName === FormName.Legal) {
            this.legalFormFiles = filesList;
          }

          if (formName === FormName.IAAS) {
            this.iaasFormFiles = filesList;
          }
        }
      })
      .catch(error => {
        // this.notificationService.error('error', error + ' ' + formName);
      });
  }

  getFormStatus(formName: string): string {
    switch (formName) {
      case FormName.Article28:
        return this.getFormStatusColor(this.art28Form?.article28Status);
      case FormName.Article32:
        return this.getFormStatusColor(this.art32Form?.article32Status);
      case FormName.Design:
        return this.getFormStatusColor(this.designForm?.designStatus);
      case FormName.Legal:
        return this.getFormStatusColor(this.legalForm?.legalStatus);
      case FormName.IAAS:
        return this.getFormStatusColor(this.iaaSForm?.iaasStatus);
      default:
        return 'new';
    }
  }

  getFormStatusColor(status?: string): string {
    switch (status) {
      case 'Canceled':
        return 'cancelled';
      case 'Completed':
        return 'completed';
      case 'Empty':
        return 'empty';
      case 'ON_HOLD':
        return 'on-hold';
      case 'Not recommended':
        return 'not-recommended';
      case 'Pending':
        return 'pending';
      case 'Recommended':
        return 'recommended';
      case 'Reservations':
        return 'reservations';
      case 'Vendor is responding':
        return 'vendor-is-responding';
      default:
        return 'new';
    }
  }

  getFormStatusTheme(status: string) {
    return getTagColor(status);
  }

  onEditedReservation(reservation: Reservation) {
    const index = this.currentReservations?.findIndex(
      r => r.idRef === reservation.idRef
    );

    if (index !== -1) {
      this.currentReservations[index] = reservation;
    }

    if (this.formName === FormName.Article28) {
      const index = this.art28FormReservations?.findIndex(
        r => r.idRef === reservation.idRef
      );
      if (index !== -1) {
        this.art28FormReservations[index] = reservation;
      }
    } else if (this.formName === FormName.Article32) {
      const index = this.art32FormReservations?.findIndex(
        r => r.idRef === reservation.idRef
      );
      if (index !== -1) {
        this.art32FormReservations[index] = reservation;
      }
    } else if (this.formName === FormName.Design) {
      const index = this.designFormReservations?.findIndex(
        r => r.idRef === reservation.idRef
      );

      if (index !== -1) {
        this.designFormReservations[index] = reservation;
      }
    } else if (this.formName === FormName.Legal) {
      const index = this.legalFormReservations?.findIndex(
        r => r.idRef === reservation.idRef
      );
      if (index !== -1) {
        this.legalFormReservations[index] = reservation;
      }
    } else if (this.formName === FormName.IAAS) {
      const index = this.iaaSFormReservations?.findIndex(
        r => r.idRef === reservation.idRef
      );
      if (index !== -1) {
        this.iaaSFormReservations[index] = reservation;
      }
    }
  }

  get userIsSoOfSolution() {
    if (this.selectedSolution?.solutionOwner === this.auth.currentUser?.email) return true;
    else return false;
  }

  get isBusinessUnitEvaluator() {
    if (!this.businessUnit?.evaluators?.includes(this.auth.currentUser?.email!)) {
      return false;
    } else {
      return true;
    }
  }

  get isSolutionEvaluator() {
    return this.selectedSolution?.emailEvaluatorAuthorized.includes(
      this.auth.currentUser!.email!
    );
  }

  get canEdit() {
    return (
      this.authService.roles?.admin ||
      (this.authService.roles?.evaluator && this.isBusinessUnitEvaluator) ||
      this.isSolutionEvaluator ||
      this.userIsSoOfSolution
    );
  }

  mailTo(email: string) {
    const mail = document.createElement('a');
    mail.href = 'mailto:' + email;
    mail.target = '_blank';
    mail.click();
  }
}
