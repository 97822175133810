import { Injectable } from '@angular/core';
import { Solution } from '../models/solution';
import { Observable, from, map } from 'rxjs';
import {
  QueryConstraint,
  addDoc,
  arrayRemove,
  arrayUnion,
  endAt,
  or,
  startAt,
  collectionData,
  collection,
  query,
  orderBy,
  startAfter,
  limit,
  endBefore,
  limitToLast,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  and,
} from '@angular/fire/firestore';
import { EntityService } from './entity.service';
import { FormType } from '../enums/form-type';
import { NzTableSortOrder } from 'ng-zorro-antd/table';

type OrderByDirection = 'desc' | 'asc';

@Injectable()
export class SolutionService extends EntityService<Solution> {
  override get collectionName(): string {
    return 'solutions';
  }

  getSolution(id: string) {
    return getDoc(doc(this._firestore, this.collectionName, id));
  }

  override observeOwned(
    uid?: string,
    lastDoc?: Solution,
    pageSize = 10,
    solutionType = 'SaaS',
    isForward = true
  ) {
    return collectionData(
      query(
        collection(this._firestore, this.collectionName),
        where('solutionOwner', '==', this._auth.currentUser?.email),
        where('type', '==', solutionType),
        orderBy('solutionName'),
        isForward
          ? startAfter(lastDoc?.solutionName || 0)
          : endBefore(lastDoc?.solutionName || 0),
        isForward ? limit(pageSize) : limitToLast(pageSize)
      )
      // {
      //   idField: nameof<Entity>('solutionOwner'),
      // }
    ) as Observable<Solution[]>;
  }

  getOwnedSolutions(
    uid?: string,
    lastDoc?: Solution,
    pageSize = 10,
    solutionType = 'SaaS',
    isForward = true
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('solutionOwner', '==', this._auth.currentUser?.email),
          where('type', '==', solutionType),
          orderBy('solutionName'),
          isForward
            ? startAfter(lastDoc?.solutionName || 0)
            : endBefore(lastDoc?.solutionName || 0),
          isForward ? limit(pageSize) : limitToLast(pageSize)
        )
      )
    );
  }

  searchOwnedSolutions(email?: string, solutionType = 'SaaS', search = '') {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('solutionOwner', '==', this._auth.currentUser?.email),
          where('type', '==', solutionType),
          orderBy('solutionName')
        )
      )
    );
  }

  async getCountOfOwned(email?: string, solutionType = 'SaaS') {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('solutionOwner', '==', this._auth.currentUser?.email),
          where('type', '==', solutionType)
        )
      )
    ).size;
  }

  getAllFollowed(
    uid?: string,
    lastDoc?: Solution,
    pageSize = 10,
    solutionType = 'SaaS',
    isForward = true
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('emailVeoliaAuthorized', 'array-contains', uid),
          where('type', '==', solutionType),
          orderBy('solutionName'),
          isForward
            ? startAfter(lastDoc?.solutionName || 0)
            : endBefore(lastDoc?.solutionName || 0),
          isForward ? limit(pageSize) : limitToLast(pageSize)
        )
      )
    );
  }

  searchAllFollowed(email?: string, solutionType = 'SaaS', search = '') {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('emailVeoliaAuthorized', 'array-contains', email),
          where('type', '==', solutionType),
          orderBy('solutionName')
        )
      )
    );
  }

  async getCountOfFollowed(uid?: string, solutionType = 'SaaS') {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('emailVeoliaAuthorized', 'array-contains', uid),
          where('type', '==', solutionType)
        )
      )
    ).size;
  }

  getAllEvaluatorSolutions(
    email?: string,
    lastDoc?: Solution,
    pageSize = 10,
    solutionType = 'SaaS',
    isForward = true
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('emailEvaluatorAuthorized', 'array-contains', email),
          where('type', '==', solutionType),
          orderBy('solutionName'),
          isForward
            ? startAfter(lastDoc?.solutionName || 0)
            : endBefore(lastDoc?.solutionName || 0),
          isForward ? limit(pageSize) : limitToLast(pageSize)
        )
      )
    );
  }

  searchAllEvaluatorSolutions(
    email?: string,
    solutionType = 'SaaS',
    search = ''
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('emailEvaluatorAuthorized', 'array-contains', email),
          where('type', '==', solutionType),
          orderBy('solutionName')
        )
      )
    );
  }

  async getCountOfEvaluatorSolutions(uid?: string, solutionType = 'SaaS') {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('emailEvaluatorAuthorized', 'array-contains', uid),
          where('type', '==', solutionType)
        )
      )
    ).size;
  }

  getAllByBusinessUnit(
    businessUnit: string,
    lastDoc?: any,
    pageSize = 10,
    solutionType = 'SaaS',
    isForward = true
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('businessUnitOfSolution', '==', businessUnit),
          orderBy('solutionName'),
          isForward ? startAt(lastDoc || 0) : endAt(lastDoc || 0),
          isForward ? limit(pageSize) : limitToLast(pageSize)
        )
      )
    );
  }

  searchAllByBusinessUnit(
    solutionType = 'SaaS',
    businessUnit: string,
    search = ''
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('businessUnitOfSolution', '==', businessUnit),
          orderBy('solutionName')
        )
      )
    );
  }

  getAllByOtherBusinessUnit(
    businessUnit: string,
    lastDoc?: any,
    pageSize = 10,
    solutionType = 'SaaS',
    isForward = true
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('businessUnitOfSolution', '!=', businessUnit),
          orderBy('businessUnitOfSolution'),
          orderBy('solutionName'),
          isForward ? startAt(lastDoc || 0) : endAt(lastDoc || 0),
          isForward ? limit(pageSize) : limitToLast(pageSize)
        )
      )
    );
  }

  searchAllOtherByBusinessUnit(solutionType = 'SaaS', search = '') {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          orderBy('solutionName')
        )
      )
    );
  }

  getVeoliaAuthorizedList(solName: string) {
    return getDocs(
      query(
        collection(this._firestore, this.collectionName),
        where('solutionName', '==', solName)
      )
    );
  }

  getAllVendorAuthorized(
    email?: string,
    lastDoc?: Solution,
    pageSize = 10,
    solutionType = 'SaaS',
    isForward = true
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('emailVendorAuthorized', 'array-contains', email),
          where('type', '==', solutionType),
          orderBy('solutionName'),
          isForward
            ? startAfter(lastDoc?.solutionName || 0)
            : endBefore(lastDoc?.solutionName || 0),
          isForward ? limit(pageSize) : limitToLast(pageSize)
        )
      )
    );
  }

  searchAllVendorAuthorized(
    email?: string,
    solutionType = 'SaaS',
    search = ''
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('emailVendorAuthorized', 'array-contains', email),
          where('type', '==', solutionType),
          orderBy('solutionName')
        )
      )
    );
  }

  getAllSolutions(
    uid?: string,
    lastDoc?: Solution,
    pageSize = 10,
    solutionType = 'SaaS',
    isForward = true,
    filter: {
      key: string;
      value: NzTableSortOrder;
    } = {
      key: 'solutionName',
      value: 'ascend',
    }
  ) {
    const queryConstraints: QueryConstraint[] = [
      where('type', '==', solutionType),
      orderBy(filter?.key, filter?.value === 'ascend' ? 'asc' : 'desc'),
      isForward ? limit(pageSize) : limitToLast(pageSize),
    ];

    // Only add cursor if lastDoc is provided
    if (lastDoc) {
      queryConstraints.push(
        isForward ? startAfter(lastDoc) : endBefore(lastDoc)
      );
    }

    const q = query(
      collection(this._firestore, this.collectionName),
      ...queryConstraints
    );

    return from(getDocs(q));
  }

  searchAllSolutions(uid?: string, solutionType = 'SaaS', search = '') {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          orderBy('solutionName')
        )
      )
    );
  }

  async getCountOfAllSolutions(uid?: string, solutionType = 'SaaS') {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType)
        )
      )
    ).size;
  }

  getAllEvaluatedSolutions(
    uid?: string,
    lastDoc?: Solution,
    pageSize = 10,
    solutionType = 'SaaS',
    isForward = true,
    filter: {
      key: string;
      value: NzTableSortOrder;
    } = {
      key: 'solutionName',
      value: 'ascend',
    }
  ) {
    const queryConstraints: QueryConstraint[] = [
      where('fullyEvaluated', '==', true),
      where('type', '==', solutionType),
      orderBy(filter?.key, filter?.value === 'ascend' ? 'asc' : 'desc'),
      isForward ? limit(pageSize) : limitToLast(pageSize),
    ];

    if (lastDoc) {
      queryConstraints.push(
        isForward ? startAfter(lastDoc) : endBefore(lastDoc)
      );
    }

    const q = query(
      collection(this._firestore, this.collectionName),
      ...queryConstraints
    );

    return from(getDocs(q));
  }

  searchAllEvaluatedSolutions(
    uid?: string,
    solutionType = 'SaaS',
    search = ''
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('fullyEvaluated', '==', true),
          where('type', '==', solutionType),
          orderBy('solutionName')
        )
      )
    );
  }

  async getCountOfAllEvaluatedSolutions(uid?: string, solutionType = 'SaaS') {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('fullyEvaluated', '==', true)
        )
      )
    ).size;
  }

  getAllPendingSolutions(
    uid?: string,
    lastDoc?: Solution,
    pageSize = 10,
    solutionType = 'SaaS',
    isForward = true,
    filter: {
      key: string;
      value: NzTableSortOrder;
    } = {
      key: 'solutionName',
      value: 'ascend',
    }
  ) {
    const queryConstraints: QueryConstraint[] = [
      where('type', '==', solutionType),
      where('status', '==', 'Pending'),
      orderBy(filter?.key, filter?.value === 'ascend' ? 'asc' : 'desc'),
      isForward ? limit(pageSize) : limitToLast(pageSize),
    ];

    if (lastDoc) {
      queryConstraints.push(
        isForward ? startAfter(lastDoc) : endBefore(lastDoc)
      );
    }

    const q = query(
      collection(this._firestore, this.collectionName),
      ...queryConstraints
    );

    return from(getDocs(q));
  }

  getAllPendingRefreshSolutions(
    uid?: string,
    lastDoc?: Solution,
    pageSize = 10,
    solutionType = 'SaaS',
    isForward = true,
    filter: {
      key: string;
      value: NzTableSortOrder;
    } = {
      key: 'solutionName',
      value: 'ascend',
    }
  ) {
    const queryConstraints: QueryConstraint[] = [
      where('type', '==', solutionType),
      where('status', '==', 'Pending'),
      orderBy(filter?.key, filter?.value === 'ascend' ? 'asc' : 'desc'),
      isForward ? limit(pageSize) : limitToLast(pageSize),
    ];

    if (lastDoc) {
      queryConstraints.push(
        isForward ? startAt(lastDoc) : endBefore(lastDoc)
      );
    }

    const q = query(
      collection(this._firestore, this.collectionName),
      ...queryConstraints
    );

    return from(getDocs(q));
  }

  searchAllPendingSolutions(uid?: string, solutionType = 'SaaS', search = '') {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('status', '==', 'Pending'),
          orderBy('solutionName')
        )
      )
    );
  }

  async getCountOfAllPendingSolutions(uid?: string, solutionType = 'SaaS') {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('status', '==', 'Pending')
        )
      )
    ).size;
  }

  async getCountByType(solutionType = 'SaaS') {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType)
        )
      )
    ).size;
  }

  async getCountByTypeAndBusinessUnit(
    solutionType = 'SaaS',
    businessUnit: string
  ) {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('businessUnitOfSolution', '==', businessUnit)
        )
      )
    ).size;
  }

  async getCountByTypeAndOtherBusinessUnit(
    solutionType = 'SaaS',
    businessUnit: string
  ) {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('businessUnitOfSolution', '!=', businessUnit)
        )
      )
    ).size;
  }

  async setReadAccess(
    solutionId: string,
    email: string,
    form: FormType,
    value: number
  ) {
    const payload: Record<string, any> = {};

    switch (form) {
      case FormType.Design:
        payload['emailVendorReadonlyDesign'] = arrayUnion(email);
        break;
      case FormType.Art28:
        payload['emailVendorReadonlyArticle28'] = arrayUnion(email);
        break;
      case FormType.Art32:
        payload['emailVendorReadonlyArticle32'] = arrayUnion(email);
        break;
      case FormType.Legal:
        payload['emailVendorReadonlyLegal'] = arrayUnion(email);
        break;
    }

    return await updateDoc(
      doc(this._firestore, this.collectionName, solutionId),
      payload
    );
  }

  async setWriteAccessRight(solutionId: string, email: string, form: FormType) {
    const payload: Record<string, any> = {};

    switch (form) {
      case FormType.Design:
        payload['emailVendorReadonlyDesign'] = arrayRemove(email);
        break;
      case FormType.Art28:
        payload['emailVendorReadonlyArticle28'] = arrayRemove(email);
        break;
      case FormType.Art32:
        payload['emailVendorReadonlyArticle32'] = arrayRemove(email);
        break;
      case FormType.Legal:
        payload['emailVendorReadonlyLegal'] = arrayRemove(email);
        break;
    }

    return await updateDoc(
      doc(this._firestore, this.collectionName, solutionId),
      payload
    );
  }

  async createSolution(payload: any) {
    return await addDoc(collection(this._firestore, this.collectionName), {
      ...payload,
    });
  }

  async updateSolution(id: string, payload: any) {
    return await updateDoc(
      doc(this._firestore, this.collectionName, id),
      payload
    );
  }

  /////####### EVALUATOR #######/////

  //////SaaS//////

  //=> Tab 1 "SaaS"/Requests to evaluate
  //Demandes avec businessUnitOfSolution (solutions collection) <-> dont le user est évaluator via 'evaluators' (businessUnit collection) nomBu
  getAllEvaluatorBusinessUnitSolutionsToEvaluate(
    uid?: string,
    lastDoc?: Solution,
    pageSize = 10,
    solutionType = 'SaaS',
    isForward = true,
    bu?: string[]
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('businessUnitOfSolution', 'in', bu),
          where('fullyEvaluated', '==', false),
          orderBy('solutionName'),
          isForward ? startAt(lastDoc || 0) : endAt(lastDoc || 0),
          isForward ? limit(pageSize) : limitToLast(pageSize)
        )
      )
    );
  }

  searchAllEvaluatorBusinessUnitSolutionsToEvaluate(
    uid?: string,
    solutionType = 'SaaS',
    search = '',
    bu?: string[]
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('fullyEvaluated', '!=', true),
          where('businessUnitOfSolution', 'in', bu),
          orderBy('solutionName')
        )
      )
    );
  }

  async getCountOfAllEvaluatorBusinessUnitSolutionsToEvaluate(
    uid?: string,
    solutionType = 'SaaS',
    bu?: string[]
  ) {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('businessUnitOfSolution', 'in', bu),
          orderBy('solutionName')
        )
      )
    ).size;
  }

  //=> Tab 1 "SaaS"/Evaluated requests
  //Demandes avec businessUnitOfSolution (solutions collection) <-> dont le user est évaluator via 'evaluators' (businessUnit collection) nomBu
  getAllEvaluatorBusinessUnitEvaluatedSolutions(
    uid?: string,
    lastDoc?: Solution,
    pageSize = 10,
    solutionType = 'SaaS',
    isForward = true,
    bu?: string[]
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('businessUnitOfSolution', 'in', bu),
          where('fullyEvaluated', '==', true),
          orderBy('solutionName'),
          isForward ? startAt(lastDoc || 0) : endAt(lastDoc || 0),
          isForward ? limit(pageSize) : limitToLast(pageSize)
        )
      )
    );
  }

  searchAllEvaluatorBusinessUnitEvaluatedSolutions(
    uid?: string,
    solutionType = 'SaaS',
    search = '',
    bu?: string[]
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('fullyEvaluated', '==', true),
          where('businessUnitOfSolution', 'in', bu),
          orderBy('solutionName')
        )
      )
    );
  }

  async getCountOfAllEvaluatorBusinessUnitEvaluatedSolutions(
    uid?: string,
    solutionType = 'SaaS',
    bu?: string[]
  ) {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType),
          where('businessUnitOfSolution', 'in', bu),
          orderBy('solutionName')
        )
      )
    ).size;
  }

  //Counters
  //Admin home page
  async getCountOfAllSolutionsByType(solutionType: string) {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', solutionType)
        )
      )
    ).size;
  }

  async getCountOfAllSolutionsAssessedByType(solutionType: string) {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('fullyEvaluated', '==', true),
          where('type', '==', solutionType)
        )
      )
    ).size;
  }

  async getCountOfAllSolutionsPendingByType(solutionType: string) {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('status', '==', 'Pending'),
          where('type', '==', solutionType)
        )
      )
    ).size;
  }

  async getCountOfAllSolutionsNewByType(solutionType: string) {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('status', '==', 'Empty'),
          where('type', '==', solutionType)
        )
      )
    ).size;
  }

  async getCountOfAllSolutionsInProgressByType(solutionType: string) {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('status', '==', 'Ongoing'),
          where('type', '==', solutionType)
        )
      )
    ).size;
  }

  async getCountOfAllSolutionsCanceledByType(solutionType: string) {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('status', '==', 'Canceled'),
          where('type', '==', solutionType)
        )
      )
    ).size;
  }

  //Admin users page and validate solution
  cancelSolution(id: string, solution: Solution) {
    return updateDoc(doc(this._firestore, this.collectionName, id), {
      status: 'Canceled',
      cancellationDate: new Date(Date.now()),
    });
  }

  approveSolution(id: string, solution: Solution) {
    return updateDoc(doc(this._firestore, this.collectionName, id), {
      status: 'Empty',
    });
  }

  unCancelSolution(id: string, solution: Solution) {
    return updateDoc(doc(this._firestore, this.collectionName, id), {
      status: 'Empty',
      restoreDate: new Date(Date.now()),
    });
  }
}
