import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { User } from '../models/user';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';

@Injectable({
  providedIn: 'root',
})
export class EvaluatorGuard {
  constructor(
    public authService: AuthService,
    public router: Router,
    public userService: UserService,
    private veoliaMessageService: VeoliaMessageService
  ) {}

  // Check if user is admin
  canActivate(): Observable<boolean> | Promise<boolean> | UrlTree | boolean {
    return new Promise((resolve, reject) => {
      return this.authService.user$.subscribe(user => {
        if (user) {
          this.userService
            .getUserData(user?.uid)
            .then(user => {
              const userData = user.data() as User;
              if (userData.roles.evaluator === true) {
                resolve(true);
              } else {
                this.veoliaMessageService.create(
                  {
                    title: 'Access denied !',
                    icon: 'error',
                    content: 'Only Evaluator BU authorized',
                  },
                  {
                    duration: 10000,
                  }
                );
                this.router.navigate(['/']);
                reject(false);
              }
            })
            .catch(() => {
              this.router.navigate(['/']);
              reject(false);
            });
        } else {
          this.router.navigate(['/']);
          reject(false);
        }
      });
    });
  }
}
