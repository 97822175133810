<div *ngIf="vm$ | async as vm">
  <app-simple-spinner-placeholder *ngIf="!vm.ready" />
  <app-form-toolbar
    *ngIf="vm.ready"
    [comments]="vm.comments"
    [currentForm]="vm.form"
    [fileList]="vm.fileList"
    [files]="vm.files"
    [evaluation]="vm.evaluation"
    [formName]="formName"
    [formCompletionStatus]="formCompletionStatus"
    [formStatus]="formStatus"
    [solution]="solution"
    [businessUnit]="businessUnit"
    (saveAll)="saveAll()"
    (markAsComplete)="markAsComplete(formCompletionStatus)"
    (refreshForm)="refreshForm()" />
  <div nz-row [nzGutter]="16">
    <div
      *ngIf="vm.ready"
      nz-col
      class="gutter-row"
      [nzSpan]="5"
      [nzLg]="6"
      [nzMd]="6"
      [nzSm]="24"
      [nzXs]="24">
      <div class="rounded-white spacing-b16">
        <h4 nz-typography>Table of content</h4>
        <div>
          <app-form-block-button
            [showId]="false"
            id="1"
            [title]="'IAAS_1' | translate"
            (click)="selectChapter('IAAS_1')"
            [evaluation]="vm.evaluation"
            evaluationField="IAAS_1_RADIO"
            [selected]="selectedChapter === 'IAAS_1'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="2"
            [title]="'IAAS_2' | translate"
            (click)="selectChapter('IAAS_2')"
            [evaluation]="vm.evaluation"
            evaluationField="IAAS_2_RADIO"
            [selected]="selectedChapter === 'IAAS_2'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="3"
            [title]="'IAAS_3' | translate"
            (click)="selectChapter('IAAS_3')"
            [evaluation]="vm.evaluation"
            evaluationField="IAAS_3_RADIO"
            [selected]="selectedChapter === 'IAAS_3'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="4"
            [title]="'IAAS_4' | translate"
            (click)="selectChapter('IAAS_4')"
            [evaluation]="vm.evaluation"
            evaluationField="IAAS_4_RADIO"
            [selected]="selectedChapter === 'IAAS_4'"
            [unlocked]="true">
          </app-form-block-button>
        </div>
      </div>
    </div>
    <div
      *ngIf="vm.ready"
      nz-col
      class="gutter-row chapters"
      [nzLg]="18"
      [nzMd]="18"
      [nzSm]="24"
      [nzXs]="24">
      <app-form-chapter
        *ngIf="fields['IAAS_1']?.fields"
        [evaluation]="fields['IAAS_1']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'IAAS_1'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="IaaS Form"
        [formModel]="vm.form"
        [formFields]="fields['IAAS_1'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('IAAS_1')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['IAAS_2']?.fields"
        [evaluation]="fields['IAAS_2']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'IAAS_2'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="IaaS Form"
        [formModel]="vm.form"
        [formFields]="fields['IAAS_2'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('IAAS_2')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['IAAS_3']?.fields"
        [evaluation]="fields['IAAS_3']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'IAAS_3'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="IaaS Form"
        [formModel]="vm.form"
        [formFields]="fields['IAAS_3'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('IAAS_3')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['IAAS_4']?.fields"
        [evaluation]="fields['IAAS_4']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'IAAS_4'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="IaaS Form"
        [formModel]="vm.form"
        [formFields]="fields['IAAS_4'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('IAAS_4')"
        (formEdited)="formEdited($event)"></app-form-chapter>
    </div>
  </div>
</div>
