<ng-container *ngIf="vm$ | async as vm">
  <nz-alert
    *ngIf="vm.error"
    nzType="error"
    [nzMessage]="vm.error"
    nzShowIcon></nz-alert>
  <div>
    <div class="row row-right row-vertical-center">
      <app-export-csv-button
        exportType="all"
        [solutions]="vm.solutions!"
        [isSearching]="vm.isSearching"
        [solutionType]="vm.solutionType === 0 ? 'SaaS' : 'IaaS'" />
      <search-input
        [isSearching]="vm.isSearching"
        (onChange)="onSearch($event)"></search-input>
    </div>
    <app-solutions-table
      (refresh)="onRefreshAfterEmit()!"
      [actions]="[
        'edit',
        'share',
        'summary',
        'logs',
        'access',
        'cancel',
        'restore',
        'ssc'
      ]"
      [solutions]="vm.solutions"
      [solutionType]="vm.solutionType"
      [showId]="true"
      (sorted)="sorted($event)"></app-solutions-table>
  </div>
  <app-pagination
    *ngIf="!vm.isSearching"
    [loading]="vm.isLoading || vm.isSearching"
    [size]="vm.limit"
    [page]="vm.currentPage"
    [total]="vm.total"
    (onPageChange)="changePage($event)"
    (onSizeChange)="changeSize($event)"></app-pagination>
</ng-container>
