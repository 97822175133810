import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  VeoliaModalComponent,
  VeoliaModalModule,
} from '@veolia.com/vds-angular-components/modal';
import { VeoliaSegmentedComponent } from '@veolia.com/vds-angular-components/segmented';
import { Solution } from 'src/app/core/models/solution';
import { SolutionShareStore } from './solution-share.store';
import {
  ValidatorFn,
  AbstractControl,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import UserApi from 'src/app/core/apis/user.api';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { NzFormLayoutType, NzFormModule } from 'ng-zorro-antd/form';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import { SimpleSpinnerModule } from '../../simple-spinner/simple-spinner.module';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { Auth } from '@angular/fire/auth';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

const isPatternMatched = (regex: RegExp, controlValue: any): boolean => {
  return regex.test(controlValue);
};

const EmailValidator = (): ValidatorFn => {
  const regex = new RegExp(
    '((.*)(?<!veolia.*)$)((.*)(?<!gmail.*)$)((.*)(?<!outlook.*)$)((.*)(?<!yahoo.*)$)((.*)(?<!hotmail.*)$)((.*)(?<!ymail.*)$)((.*)(?<!free.com)$)((.*)(?<!free.fr)$)'
  );
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value && !isPatternMatched(regex, control.value)
      ? { url: { valid: false, message: 'Unauthorized email domain' } }
      : null;
  };
};

const isEmail = (regex: RegExp, controlValue: any): boolean => {
  return regex.test(controlValue);
};

const IsEmailValidator = (): ValidatorFn => {
  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value && !isEmail(emailRegex, control.value)
      ? { url: { valid: false, message: 'Invalid email address' } }
      : null;
  };
};

@Component({
  selector: 'app-solution-share',
  templateUrl: './solution-share.component.html',
  styleUrls: ['./solution-share.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    VeoliaModalModule,
    VeoliaSegmentedComponent,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    VeoliaIconComponent,
    NzSelectModule,
    NzInputModule,
    VeoliaButtonComponent,
    SimpleSpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NzTypographyModule,
    NzToolTipModule,
  ],
  providers: [SolutionShareStore, provideNgxMask()],
})
export class SolutionShareComponent implements OnInit {
  solution?: Solution;
  requester?: string;
  options = ['External user', 'Veolia user'];
  formOption = 0;
  formLayout: NzFormLayoutType = 'vertical';
  optionList: string[] = [];
  selectedUser?: string;
  isLoading = false;
  vm$ = this.solutionShareStore.vm$;
  customPatterns = { A: { pattern: new RegExp('^[a-zA-Z0-9._%+-]+') } };

  @ViewChild('solutionShareModal') modal!: VeoliaModalComponent;

  externalUserForm: FormGroup<{
    emailVendor: FormControl<string | null>;
    lastnameVendor: FormControl<string | null>;
    firstnameVendor: FormControl<string | null>;
    companyVendor: FormControl<string | null>;
  }> = this.fb.group({
    emailVendor: [
      '',
      [
        Validators.required,
        Validators.email,
        IsEmailValidator(),
        EmailValidator(),
      ],
    ],
    lastnameVendor: ['', [Validators.required]],
    firstnameVendor: ['', [Validators.required]],
    companyVendor: ['', [Validators.required]],
  });

  veoliaUserForm:
    | FormGroup<{
        emailVeoliaAccount: FormControl<string | null>;
        emailNewUserVeolia: FormControl<string | null>;
      }>
    | undefined;

  constructor(
    private cDRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private solutionShareStore: SolutionShareStore,
    private authService: AuthService,
    private userApi: UserApi,
    private userService: UserService,
    private veoliaMessageService: VeoliaMessageService,
    private auth: Auth
  ) {}

  ngOnInit(): void {
    this.solutionShareStore.getUsers();
    this.initVeoliaUserForm();
  }

  open(solution: Solution, requester: string) {
    this.solution = solution;
    this.requester = requester;
    this.modal.open();
  }

  initVeoliaUserForm(): void {
    this.veoliaUserForm = this.fb.group({
      emailVeoliaAccount: [''],
      emailNewUserVeolia: ['', [Validators.pattern('((.*)(?<!@.*)$)')]],
    });

    this.veoliaUserForm.valueChanges.subscribe(value => {
      if (this.atLeastOneHasValue()) {
        if (
          value.emailVeoliaAccount !== '' &&
          value.emailVeoliaAccount !== null &&
          value.emailVeoliaAccount !== undefined
        ) {
          this.veoliaUserForm
            ?.get('emailNewUserVeolia')
            ?.disable({ emitEvent: false });
        } else {
          this.veoliaUserForm
            ?.get('emailVeoliaAccount')
            ?.disable({ emitEvent: false });
        }
      } else {
        this.veoliaUserForm
          ?.get('emailNewUserVeolia')
          ?.enable({ emitEvent: false });
        this.veoliaUserForm
          ?.get('emailVeoliaAccount')
          ?.enable({ emitEvent: false });
      }
    });
  }

  onSearch(value: string): void {
    this.isLoading = true;
  }

  handleIndexChange(e: number): void {
    this.formOption = e;
    this.solutionShareStore.reset();
  }

  submitForm(): void {
    this.isLoading = true;
    const form =
      this.formOption === 0 ? this.externalUserForm : this.veoliaUserForm;
    if (form?.valid) {
      if (this.formOption === 0) { //share to vendor
        this.userService
          .checkUserExists(this.externalUserForm.value.emailVendor!)
          .then(snapShot => {
            const isExisting = !snapShot.empty;

            return this.userApi
              .addVendorToSolutionIfExistsOrNotByVendorOrVeolia(
                this.solution!.uid,
                {
                  emailVendor: this.externalUserForm.value.emailVendor!,
                  firstnameVendor: this.externalUserForm.value.firstnameVendor!,
                  lastnameVendor: this.externalUserForm.value.lastnameVendor!,
                  companyVendor: this.externalUserForm.value.companyVendor!,
                  isExisting,
                },
                this.auth.currentUser?.email!,
                this.requester!
              )
              .subscribe({
                next: result => {
                  if (result === 'NEW_VENDOR_CREATED_SOL_SHARED') {
                    this.veoliaMessageService.create(
                      {
                        title: 'Notification sent !',
                        icon: 'check',
                        content:
                          'New vendor created/notified and solution shared successfully',
                        type: 'success',
                      },
                      {
                        duration: 8000,
                      }
                    );
                  }
                  if (result === 'EXISTING_VENDOR_SOL_SHARED') {
                    this.veoliaMessageService.create(
                      {
                        title: 'Notification sent !',
                        icon: 'check',
                        content:
                          'Existing vendor notified and solution shared successfully',
                        type: 'success',
                      },
                      {
                        duration: 8000,
                      }
                    );
                  }
                  if (result === 'DIFF_DOMAIN_NAME_VENDOR_SHARED_APPROVAL') {
                    this.veoliaMessageService.create(
                      {
                        title: 'Notification sent !',
                        icon: 'info',
                        content:
                          'Approval sharing request sent to the solution owner',
                        type: 'success',
                      },
                      {
                        duration: 8000,
                      }
                    );
                  }
                },
                error: err => {
                  this.veoliaMessageService.create(
                    {
                      title: 'Error',
                      icon: 'error',
                      actionText: err.message,
                      content: err.message,
                      type: 'error',
                    },
                    {
                      duration: 8000,
                    }
                  );
                },
              });
          });
        this.modal.close();
      } else { //share to veolia
        this.userApi
          .addVeoliaToSolutionByVeolia(
            this.solution!.uid,
            this.veoliaUserForm?.value.emailVeoliaAccount!
              ? this.veoliaUserForm?.value.emailVeoliaAccount!
              : this.veoliaUserForm?.value.emailNewUserVeolia + '@veolia.com',
            this.veoliaUserForm?.value.emailVeoliaAccount! ? true : false,
            this.auth.currentUser?.email!,
            this.requester!
          )
          .subscribe({
            next: () => {
              this.veoliaMessageService.create(
                {
                  title: 'Notification sent !',
                  icon: 'check',
                  content: 'Solution shared with Veolia user successfully',
                  type: 'success',
                },
                {
                  duration: 8000,
                }
              );
            },
            error: err => {
              this.veoliaMessageService.create(
                {
                  title: 'Error',
                  icon: 'error',
                  actionText: err.message,
                  content: err.message,
                  type: 'error',
                },
                {
                  duration: 8000,
                }
              );
            },
          });

        this.modal.close();
      }
    } else {
      Object.values(form!.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  atLeastOneHasValue(): boolean {
    const form = this.veoliaUserForm;
    return (
      Object.keys(form!.value).find(
        key => form?.get(key)?.value !== '' && form?.get(key)?.value !== null
      ) !== undefined
    );
  }

  reset(): void {
    // this.formOption = 0;
    //this.modal.close();
    this.externalUserForm.reset();
    this.veoliaUserForm?.reset();
  }

  get userType() {
    return this.authService.type;
  }

  get domainName() {
    const emailOfMainVendor = this.solution?.mainEmailVendor;
    const atIndex = emailOfMainVendor!.indexOf('@');
    const domainNameOfMainVendor = emailOfMainVendor!.substring(atIndex);

    return domainNameOfMainVendor;
  }
}
