import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
  FormlyModule,
} from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import {
  VeoliaModalComponent,
  VeoliaModalModule,
} from '@veolia.com/vds-angular-components/modal';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { DesignForm } from 'src/app/core/models/design-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { Solution } from 'src/app/core/models/solution';
import { getTagColor } from 'src/app/core/utils/colors';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { Evaluation } from 'src/app/core/models/evalutation';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { EvaluationSummaryModule } from '../../../evaluation-summary/evaluation-summary.module';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzListModule } from 'ng-zorro-antd/list';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { FileItem } from 'src/app/core/models/file-item';
import { CommonModule } from '@angular/common';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { EvaluationApi } from 'src/app/core/apis/evaluation.api';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { LogService } from 'src/app/core/services/log.service';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { Auth } from '@angular/fire/auth';
import { AuthService } from 'src/app/core/services/auth.service';
import { IaasForm } from 'src/app/core/models/iaas-form';
import { DesignFormService } from 'src/app/core/services/design-form.service';
import { LegalFormService } from 'src/app/core/services/legal-form.service';
import { Article28FormService } from 'src/app/core/services/article-28-form.service';
import { Article32FormService } from 'src/app/core/services/article-32-form.service';
import { IaaSFormService } from 'src/app/core/services/iaas-form.service';
import { SolutionService } from 'src/app/core/services/solution.service';

@Component({
  standalone: true,
  selector: 'app-form-assessment',
  templateUrl: './form-assessment.component.html',
  styleUrls: [],
  imports: [
    CommonModule,
    EvaluationSummaryModule,
    FormsModule,
    FormlyModule,
    NzCollapseModule,
    NzDividerModule,
    NzFormModule,
    NzListModule,
    NzRadioModule,
    ReactiveFormsModule,
    TranslateModule,
    VeoliaButtonComponent,
    VeoliaIconComponent,
    VeoliaModalModule,
    VeoliaTagComponent,
  ],
  providers: [TranslateService],
})
export class FormAssessmentComponent implements OnInit, OnChanges {
  @Input() evaluation?: Evaluation;
  @Input() isEditable = false;
  @Input() fileList?: FileItem[] = [];
  @Input() files?: Record<string, FileItem[]>;
  @Input() form?:
    | Article28Form
    | Article32Form
    | DesignForm
    | LegalForm
    | IaasForm;
  @Input() formName?:
    | 'Design Form'
    | 'Legal Form'
    | 'Article 28 Form'
    | 'Article 32 Form'
    | 'IaaS Form';
  @Input() solution?: Solution;
  @Output() refreshForm: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('modal') modal!: VeoliaModalComponent;

  printing = false;
  evaluationStatus?: string = 'Form Assessment';
  evaluationColor?:
    | 'default'
    | 'warning'
    | 'error'
    | 'success'
    | 'information'
    | 'purple'
    | 'orange'
    | 'lightblue';

  model: any = {};
  assessmentForm = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'status',
      type: 'nz-status',
      wrappers: ['form-divider'],
      props: {
        required: true,
        label: 'Status',
        options: [
          { value: 'Not recommended', label: 'Not recommended' },
          { value: 'Recommended', label: 'Recommended' },
          {
            value: 'Reservations',
            label: 'Recommended with reservations',
          },
        ],
      },
    },
    {
      key: 'comment',
      type: 'nz-textarea',
      wrappers: ['form-divider'],
      props: {
        label: 'Add new comment',
      },
    },
  ];

  options: FormlyFormOptions = {
    formState: {
      disabled: true,
    },
  };

  constructor(
    private auth: Auth,
    private authService: AuthService,
    private evaluationService: EvaluationService,
    private evaluationApi: EvaluationApi,
    private logService: LogService,
    private messageService: VeoliaMessageService,
    private article28FormService: Article28FormService,
    private article32FormService: Article32FormService,
    private designFormService: DesignFormService,
    private iaasFormService: IaaSFormService,
    private legalFormService: LegalFormService,
    private solutionService: SolutionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['evaluation']) {
      this.evaluation = {
        ...(changes['evaluation'].currentValue as unknown as Evaluation),
      };

      this.evaluationColor = getTagColor(
        this.evaluation?.status || 'Form assessment'
      );
      this.evaluationStatus = this.evaluation?.status;
    }
  }

  get evaluationPath() {
    return `evaluations/${this.formName}/${this.solution?.uid}/${this.evaluation?.id}`;
  }

  ngOnInit(): void {
    this.evaluationColor = getTagColor(
      this.evaluation?.status || 'Form assessment'
    );
    this.evaluationStatus = this.evaluation?.status;
    this.model = {
      ...this.model,
      status: this.evaluation?.status || 'Form assessment',
      comment: this.evaluation?.finalComment,
    };
  }

  get formStatus() {
    if (this.formName === 'Article 28 Form') {
      return (this.form as Article28Form)?.article28Status || 'New';
    } else if (this.formName === 'Article 32 Form') {
      return (this.form as Article32Form)?.article32Status || 'New';
    } else if (this.formName === 'Design Form') {
      return (this.form as DesignForm)?.designStatus || 'New';
    } else if (this.formName === 'Legal Form') {
      return (this.form as LegalForm)?.legalStatus || 'New';
    } else {
      return (this.form as IaasForm)?.iaasStatus || 'New';
    }
  }

  resetForm() {
    if (this.options.resetModel) {
      this.options?.resetModel();
    }
    this.model = {
      ...this.model,
      status: this.evaluation?.status,
      comment: this.evaluation?.finalComment,
    };
    this.modal.close();
  }

  download(file: FileItem) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {};
    xhr.open('GET', file.url);
    xhr.send();
  }

  getFormStatusTheme(status: string) {
    return getTagColor(status);
  }

  submit() {
    if (this.assessmentForm.valid && this.solution) {
      this.evaluationService
        .updateEvaluation(this.evaluationPath, {
          finalComment: this.model.comment || null,
          status: this.model.status, //Si toujours pas d'évaluation finale cochée alors DLA is reviewing
          postedAt: new Date(Date.now()).toString(),
          postedBy: this.auth.currentUser?.email,
          userId: this.auth.currentUser?.uid,
          solutionName: this.solution?.solutionName,
        })
        .then(() => {
          this.messageService.create(
            {
              icon: 'check_box',
              title: 'Success',
              content: 'Evaluated successfully',
            },
            { duration: 5000 }
          );

          if (
            ['Recommended', 'Reservations', 'Not recommended'].includes(
              this.model.status
            )
          ) {
            const data: any = {};

            if (this.formName === 'Design Form') {
              data['designStatus'] = this.model.status;
              this.designFormService
                .update(
                  this.solution?.uid!,
                  {
                    designStatus: this.model.status,
                    designFull: true,
                  },
                  false
                )
                .then(() => {
                  this.notifyEvaluation('designForm');
                })
                .catch(error => {
                  this.onErrorEvaluation(error);
                });
            } else if (this.formName === 'Legal Form') {
              data['legalStatus'] = this.model.status;
              this.legalFormService
                .update(
                  this.solution?.uid!,
                  {
                    legalStatus: this.model.status,
                    legalFull: true,
                  },
                  false
                )
                .then(() => {
                  this.notifyEvaluation('legalForm');
                })
                .catch(error => {
                  this.onErrorEvaluation(error);
                });
            } else if (this.formName === 'Article 32 Form') {
              data['article32Status'] = this.model.status;
              this.article32FormService
                .update(
                  this.solution?.uid!,
                  {
                    article32Status: this.model.status,
                    article32Full: true,
                  },
                  false
                )
                .then(() => {
                  this.notifyEvaluation('article32Form');
                })
                .catch(error => {
                  this.onErrorEvaluation(error);
                });
            } else if (this.formName === 'Article 28 Form') {
              data['article32Status'] = this.model.status;
              this.article28FormService
                .update(
                  this.solution?.uid!,
                  {
                    article28Status: this.model.status,
                    article28Full: true,
                  },
                  false
                )
                .then(() => {
                  this.notifyEvaluation('article28Form');
                })
                .catch(error => {
                  this.onErrorEvaluation(error);
                });
            } else {
              data['iaasStatus'] = this.model.status;
              this.iaasFormService
                .update(
                  this.solution?.uid!,
                  {
                    iaasStatus: this.model.status,
                    iaasFull: true,
                  },
                  false
                )
                .then(() => {
                  this.notifyEvaluation('iaasForm');
                })
                .catch(error => {
                  this.onErrorEvaluation(error);
                });
            }

            this.solutionService.update(this.solution!.uid, data, false);
          }

          this.logService.setLogForAction(
            'FinalEvaluation',
            this.solution!.uid,
            this.formName!,
            {
              roleUser: this.authService.userRole,
              action: 'Final evaluation saved',
              dateTime: new Date(Date.now()).toString(),
              status: this.model.status,
              comment: this.model.comment || null,
            }
          );

          this.refreshForm.emit();

          this.modal.close();
        })
        .catch(error => {
          this.messageService.create(
            {
              icon: 'error',
              title: 'Error',
              content: 'Oops! Evaluation error: ' + error.message,
            },
            { duration: 5000 }
          );
        });
    }
  }

  get isViewer() {
    return this.authService.roles?.viewer;
  }

  notifyEvaluation(formNameRoute: string) {
    this.evaluationApi
      .notifyFullEvaluation(this.solution?.uid!, {
        formName: this.formName!,
        status: this.model.status,
        comment: this.model.comment || null,
        formNameRoute: formNameRoute,
      })
      .subscribe({
        next: () => {
          this.messageService.create(
            {
              icon: 'check_box',
              title: 'Success',
              content: 'Notification sent successfully',
            },
            { duration: 5000 }
          );
        },
        error: error => {
          this.messageService.create(
            {
              icon: 'error',
              title: 'Error',
              content: 'Oops! Evaluation error: ' + error.message,
            },
            { duration: 5000 }
          );
        },
      });
  }

  onErrorEvaluation(error: any) {
    this.messageService.create(
      {
        icon: 'error',
        title: 'Error',
        content: 'Oops! Evaluation error: ' + error.message,
      },
      { duration: 5000 }
    );
  }
}
