import { Component } from '@angular/core';
import {
  Auth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from '@angular/fire/auth';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import UserApi from 'src/app/core/apis/user.api';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-edit-password-page',
  templateUrl: './edit-password-page.component.html',
  styleUrls: ['./edit-password-page.component.scss'],
})
export class EditPasswordPageComponent {
  loading = false;

  public editPasswordForm: FormGroup<{
    currentPassword: FormControl<string>;
    newPassword: FormControl<string>;
    newPasswordConfirm: FormControl<string>;
  }> = this.fb.group({
    currentPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required]],
    newPasswordConfirm: ['', [Validators.required]],
  });

  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'oldPassword',
      type: 'nz-password',
      props: {
        label:
          'Your current passwod (has a length between 6 and 32 characters)',
        required: true,
      },
    },
    {
      type: 'divider',
    },
    {
      validators: {
        validation: [
          { name: 'fieldMatch', options: { errorPath: 'newPasswordConfirm' } },
        ],
      },
      fieldGroup: [
        {
          key: 'newPassword',
          type: 'nz-password',
          props: {
            label:
              'Your new passwod (has a length between 6 and 32 characters)',
            required: true,
          },
          validators: {
            validation: ['passwordRule'],
          },
        },
        {
          key: 'newPasswordConfirm',
          type: 'nz-password',
          props: {
            label:
              'Confirm your passwod (has a length between 6 and 32 characters)',
            required: true,
          },
        },
      ],
    },
  ];

  constructor(
    private auth: Auth,
    private fb: NonNullableFormBuilder,
    public router: Router,
    private userApi: UserApi,
    private userService: UserService,
    private veoliaMessageService: VeoliaMessageService
  ) {}

  submit() {
    if (this.form.valid) {
      this.loading = true;
      const credential = EmailAuthProvider.credential(
        this.auth.currentUser?.email!,
        this.model.oldPassword
      );

      reauthenticateWithCredential(this.auth.currentUser!, credential)
        .then(() => {
          updatePassword(this.auth.currentUser!, this.model.newPassword)
            .then(() => {
              this.userService
                .update(
                  this.auth?.currentUser!.uid,
                  {
                    passwordEdited: true,
                  },
                  false
                )
                .then(() => {
                  this.loading = false;

                  this.userApi.notify_password_changed().subscribe();
                  this.veoliaMessageService.create(
                    {
                      icon: 'check',
                      title: 'Success',
                      type: 'success',
                      content: 'Password changed successfully',
                    },
                    { duration: 3000 }
                  );
                  this.router.navigate(['/']);
                })
                .catch(() => {
                  this.loading = false;

                  this.veoliaMessageService.create(
                    {
                      icon: 'error',
                      title: 'Error',
                      type: 'error',
                      content: 'Error! Please init a reset password',
                    },
                    { duration: 3000 }
                  );
                });
            })
            .catch(() => {
              this.loading = false;

              this.veoliaMessageService.create(
                {
                  icon: 'error',
                  title: 'Error',
                  type: 'error',
                  content: 'Error during password change',
                },
                { duration: 3000 }
              );
            });
        })
        .catch(() => {
          this.loading = false;

          this.veoliaMessageService.create(
            {
              icon: 'error',
              title: 'Error',
              type: 'error',
              content: 'Error checking your password',
            },
            { duration: 3000 }
          );
        });
    }
  }
}
