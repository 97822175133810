import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, map, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent {
  @Input() isSearching = false;
  @Output() clear: EventEmitter<void> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('search') search: ElementRef<HTMLInputElement> = {} as ElementRef;

  constructor(private router: Router) {}

  ngAfterViewInit() {
    this.debounce(this.search.nativeElement, 'input').subscribe(val => {
      this.onChange.emit(val);
    });
  }

  debounce(element: any, event: any, time = 2000) {
    const eventObserver = fromEvent(element, event).pipe(
      map((i: any) => i.currentTarget.value)
    );
    return eventObserver.pipe(debounceTime(time), distinctUntilChanged());
  }

  clearSearch() {
    this.search.nativeElement.value = '';
    this.search.nativeElement.dispatchEvent(
      new Event('input', { bubbles: true })
    );
    this.clear.emit();
  }

  isAdminUsersRoute() {
    return this.router.url === '/admin/users';
  }

  isAdminBusRoute() {
    return this.router.url === '/admin/business-units';
  }

}
