<veolia-table
  [datasource]="solutions"
  size="small"
  [showPagination]="false"
  [hideSelection]="true">
  <veolia-table-column [width]="34">
    <veolia-table-header></veolia-table-header>
    <veolia-table-cell [withoutBackground]="true">
      <ng-template let-row>
        <veolia-link
          size="small"
          icon="info"
          [iconFilled]="true"
          (click)="launchAction('info', row)">
        </veolia-link>
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>
  <veolia-table-column>
    <veolia-table-header>Solution</veolia-table-header>
    <veolia-table-cell *ngIf="solutionType === 0">
      <ng-template let-row>
        <a
          style="display: flex"
          nz-dropdown
          [nzDropdownMenu]="menu"
          *ngIf="
            row.status !== 'Pending' &&
            row.status !== 'Canceled' &&
            isFormsAccessible
          "
          (click)="launchForm(row, 'designForm')">
          {{ row.solutionName }}
          <veolia-icon
            [size]="16"
            [filled]="false"
            [name]="'expand_more'"></veolia-icon>
        </a>
        <a
          style="display: flex; cursor: default; color: black"
          *ngIf="row.status === 'Pending' || row.status === 'Canceled'"
          >{{ row.solutionName }}</a
        >
        <a
          style="display: flex; cursor: default; color: black"
          *ngIf="!isFormsAccessible"
          >{{ row.solutionName }}</a
        >
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item>
              <veolia-link size="small" (click)="launchForm(row, 'designForm')">
                Design Form
              </veolia-link>
            </li>
            <li nz-menu-item>
              <veolia-link size="small" (click)="launchForm(row, 'legalForm')">
                Legal Form
              </veolia-link>
            </li>
            <li nz-menu-item>
              <veolia-link
                size="small"
                (click)="launchForm(row, 'article32Form')">
                Article 32
              </veolia-link>
            </li>
            <li nz-menu-item>
              <veolia-link
                size="small"
                (click)="launchForm(row, 'article28Form')">
                Annexes
              </veolia-link>
            </li>
          </ul>
        </nz-dropdown-menu>
      </ng-template>
    </veolia-table-cell>

    <veolia-table-cell *ngIf="solutionType === 1">
      <ng-template let-row>
        <a
          style="display: flex"
          nz-dropdown
          [nzDropdownMenu]="menu2"
          *ngIf="
            row.status !== 'Pending' &&
            row.status !== 'Canceled' &&
            isFormsAccessible
          "
          (click)="launchForm(row, 'iaasForm')">
          {{ row.solutionName }}
          <veolia-icon
            [size]="16"
            [filled]="false"
            [name]="'expand_more'"></veolia-icon>
        </a>
        <a
          style="display: flex; cursor: default; color: black"
          *ngIf="row.status === 'Pending' || row.status === 'Canceled'"
          >{{ row.solutionName }}</a
        >
        <a
          style="display: flex; cursor: default; color: black"
          *ngIf="!isFormsAccessible"
          >{{ row.solutionName }}</a
        >
        <nz-dropdown-menu #menu2="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item>
              <veolia-link size="small" (click)="launchForm(row, 'iaasForm')">
                IaaS Form
              </veolia-link>
            </li>
          </ul>
        </nz-dropdown-menu>
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column *ngIf="solutionType === 0">
    <veolia-table-header>RFP/Project</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>{{ row.rfpName }}</ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column>
    <veolia-table-header>DLA status</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        <veolia-tag
          *ngIf="row.status !== 'Canceled' && row.fullyEvaluated === false"
          [label]="row.status"
          [color]="getTagColor(row.status)"
          variant="bold" />
        <veolia-tag
          *ngIf="
            (row.status !== 'Canceled' && row.fullyEvaluated === true) ||
            row.status === 'Canceled'
          "
          style="cursor: context-menu"
          nz-tooltip
          [nzTooltipTitle]="dateToolTip"
          [label]="row.status"
          [color]="getTagColor(row.status)"
          variant="bold" />
        <ng-template #dateToolTip>
          <strong
            *ngIf="row.status !== 'Canceled' && row.fullyEvaluated === true"
            style="margin-left: 3px">
            {{ getFormattedDate(row.finalEvaluationDate) }}
          </strong>
          <strong *ngIf="row.status === 'Canceled'" style="margin-left: 3px">
            {{ getFormattedDate(row.cancellationDate) }}
          </strong>
        </ng-template>
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column *ngIf="solutionType === 0">
    <veolia-table-header>Design</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        <veolia-tag
          [label]="row.designStatus"
          [color]="getTagColor(row.designStatus)"
          variant="bold" />
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column *ngIf="solutionType === 0">
    <veolia-table-header>Legal</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        <veolia-tag
          [label]="row.legalStatus"
          [color]="getTagColor(row.legalStatus)"
          variant="bold" />
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column *ngIf="solutionType === 0">
    <veolia-table-header>Art 32</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        <veolia-tag
          [label]="row.article32Status"
          [color]="getTagColor(row.article32Status)"
          variant="bold" />
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column *ngIf="solutionType === 1">
    <veolia-table-header>IaaS</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        <veolia-tag
          [label]="row.iaasStatus"
          [color]="getTagColor(row.iaasStatus)"
          variant="bold" />
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column>
    <veolia-table-header> Vendor SSC </veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        <app-security-score-card [solution]="row" />
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column>
    <veolia-table-header>Request date</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>{{ getFormattedDate(row.requestDate) }}</ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column>
    <veolia-table-header>Last update</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        {{ getFormattedDate(row.lastUpdate) }}
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    [sortDirections]="['ascend', 'descend', null]"
    [sortPriority]="2">
    <veolia-table-header>Business Unit</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>{{ row.businessUnitOfSolution }}</ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column fixedRight [width]="34">
    <veolia-table-header></veolia-table-header>
    <veolia-table-cell [withoutBackground]="true">
      <ng-template let-row>
        <a nz-dropdown [nzDropdownMenu]="menu">
          <span nz-icon nzType="more"></span>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li
              nz-menu-item
              *ngIf="
                hasAction('edit') &&
                (row.status === 'Pending' || row.status !== 'Pending') &&
                row.status !== 'Canceled'
              ">
              <veolia-link
                size="small"
                icon="edit"
                (click)="launchAction('edit', row)">
                Edit
              </veolia-link>
            </li>
            <li
              nz-menu-item
              *ngIf="
                hasAction('share') &&
                row.status !== 'Pending' &&
                row.status !== 'Canceled'
              ">
              <veolia-link
                size="small"
                icon="share"
                (click)="launchAction('share', row)">
                Share
              </veolia-link>
            </li>
            <li
              nz-menu-item
              *ngIf="
                hasAction('summary') &&
                row.status !== 'Pending' &&
                (row.status !== 'Canceled' || row.status === 'Canceled')
              ">
              <veolia-link
                size="small"
                icon="event_available"
                (click)="launchAction('summary', row)">
                Summary
              </veolia-link>
            </li>
            <li
              nz-menu-item
              *ngIf="
                hasAction('logs') &&
                row.status !== 'Pending' &&
                (row.status !== 'Canceled' || row.status === 'Canceled')
              ">
              <veolia-link
                size="small"
                icon="list_alt"
                (click)="launchAction('logs', row)">
                Logs
              </veolia-link>
            </li>
            <li
              nz-menu-item
              *ngIf="
                hasAction('access') &&
                row.status !== 'Pending' &&
                (row.status !== 'Canceled' || row.status === 'Canceled')
              ">
              <veolia-link
                size="small"
                icon="group"
                (click)="launchAction('access', row)">
                Access rights
              </veolia-link>
            </li>
          </ul>
        </nz-dropdown-menu>
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>
</veolia-table>

<app-access-rights #accessRightsModal />

<app-solution-share #solutionShareModal />

<app-solution-info-summary #solutionInfoSummaryModal />
