<veolia-modal #modal [closable]="true">
  <div veolia-modal-title>
    {{ 'Reservations overview for' | translate }}
    <a> {{ solution?.solutionName }}</a>
  </div>
  <div veolia-modal-subtitle>
    <strong>{{ 'Form' | translate }}</strong>
    : {{ formName }}
  </div>

  <div veolia-modal-content>
    <app-reservation-overview
      *ngFor="let reservation of reservations"
      [reservation]="reservation"
      [solution]="solution!"
      (reservationEdit)="onEdit($event)"
      (reservationEdited)="onEdited($event)"
      (onCancel)="onCancel()"
      [editedReference]="editedReference"></app-reservation-overview>
  </div>
</veolia-modal>
