import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Article28FormService } from 'src/app/core/services/article-28-form.service';
import { Article28FormStore } from './article-28-form.store';
import { Solution } from 'src/app/core/models/solution';
import { ScrollService } from 'src/app/core/services/scroll.service';
import { Chapter } from 'src/app/core/types/chapter';
import { TranslateService } from '@ngx-translate/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { NzMessageService } from 'ng-zorro-antd/message';
import { yesOrNoOptions } from 'src/app/core/forms/checkbox-options';
import { FormName } from 'src/app/core/enums/form-name';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Auth } from '@angular/fire/auth';
import { FormlyFormOptions } from '@ngx-formly/core';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { SolutionService } from 'src/app/core/services/solution.service';
import SolutionApi from 'src/app/core/apis/solution.api';
import { serverTimestamp } from '@angular/fire/database';
import { LogService } from 'src/app/core/services/log.service';
import { Timestamp, serverTimestamp as fireServerTimestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-article-28-form',
  templateUrl: './article-28-form.component.html',
  styleUrls: ['./article-28-form.component.scss'],
  providers: [Article28FormStore],
})
export class Article28FormComponent implements OnInit {
  @Input() solutionId = '';
  @Input() solution?: Solution;
  @Input() businessUnit?: BusinessUnit;
  @Output() updateFormDate: EventEmitter<{
    formName: string;
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  }> = new EventEmitter<{
    formName: string;
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  }>();

  formName: FormName = FormName.Article28;

  vm$ = this.article28FormStore.vm$;

  formChapters: string[] = ['AR28'];
  selectedChapter? = this.formChapters[0];

  fields: { [key: string]: Chapter } = {
    AR28: {
      evaluation: {
        commentField: 'AR28_SCC1_ANNEX_3_RADIO_comment',
        solutionName: this.solution?.solutionName,
        statusField: 'AR28_SCC1_ANNEX_3_RADIO',
      },
      label: 'AR28',
      fields: [
        {
          type: 'nz-sub-chapter',
          fieldGroup: [
            {
              props: {
                label: 'AR28',
              },
              fieldGroup: [
                {
                  template:
                    '<span nz-typography nzType="danger">' +
                    this.translateService.instant('AR28_LABEL_0') +
                    '</span>',
                },
                {
                  template: this.translateService.instant('AR28_LABEL_1'),
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q0_RADIO',
                  type: 'radio',
                  props: {
                    label: this.translateService.instant(
                      'AR28_SCC1_ANNEX_3_Q0'
                    ),
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<span class="spacing-v8">${this.translateService.instant('AR28_LABEL_2')}</span><br /><br />`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q0_DATE',
                  type: 'nz-datepicker',
                  props: {
                    label: this.translateService.instant('AR28_LABEL_3'),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<span class="spacing-v8">${this.translateService.instant('AR28_LABEL_4')}</span><br /><br />`,
                },
                {
                  template: `<span class="spacing-v8">${this.translateService.instant('AR28_LABEL_5')}</span><br /><br />`,
                },
                {
                  template: `<span class="spacing-v8">${this.translateService.instant('AR28_LABEL_6')}</span><br /><br />`,
                },
                {
                  template: `<span class="spacing-v8">${this.translateService.instant('AR28_LABEL_7')}</span><br /><br />`,
                },
                {
                  template: `<span class="spacing-v8">${this.translateService.instant('AR28_LABEL_8')}</span><br /><br />`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q0_RADIO',
                  type: 'radio',
                  props: {
                    label: this.translateService.instant(
                      'AR28_SCC1_ANNEX_3_Q0'
                    ),
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_9')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_LABEL_10')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q1_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_11')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_LABEL_12')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q2_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q2_2',
                  type: 'nz-textarea',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_13')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q3_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q3_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q3_2')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q3_2',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q3_3')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q3_3',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q3_4')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q3_4',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q3_5')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q3_5',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q3_6')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q3_6',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q3_7')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q3_7',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: this.translateService.instant('AR28_LABEL_14'),
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_15')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q4_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q4_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q4_2')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q4_2',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q4_3')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q4_3',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q2_4',
                  type: 'nz-textarea',
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_16')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q5_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q5_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q5_2')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q5_2',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q5_3')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q5_3',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q5_4',
                  type: 'nz-textarea',
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_17')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q6_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q6_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q6_2',
                  type: 'nz-textarea',
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_18')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q7_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q7_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q7_2')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q7_2',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q7_3')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q7_3',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q7_4')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q7_4',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q7_5')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q7_5',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q7_6',
                  type: 'nz-textarea',
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_19')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q8_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q8_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q8_2',
                  type: 'nz-textarea',
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_20')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q9_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q9_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q9_2',
                  type: 'nz-textarea',
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_21')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q10_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q10_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q10_2')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q10_2',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q10_3',
                  type: 'nz-textarea',
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_22')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q11_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q11_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q11_2',
                  type: 'nz-textarea',
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_23')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q11_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q12_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q12_2',
                  type: 'nz-textarea',
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_24')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q13_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q13_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q13_2')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q13_2',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q13_3')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q13_3',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q13_4',
                  type: 'nz-textarea',
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_25')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q14_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q14_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q14_2',
                  type: 'nz-textarea',
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_26')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q15_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q15_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q15_2')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q15_2',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q15_3')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q15_3',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q15_4',
                  type: 'nz-textarea',
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  template: `<strong class="spacing-v8">${this.translateService.instant('AR28_LABEL_27')}</strong><br />`,
                },
                {
                  template: `<ul><li><span class="spacing-v8">${this.translateService.instant('AR28_SCC1_ANNEX_3_Q16_1')}</span></li></ul>`,
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q16_1',
                  type: 'radio',
                  props: {
                    options: yesOrNoOptions,
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q16_2',
                  type: 'nz-textarea',
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q17',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant(
                      'AR28_SCC1_ANNEX_3_Q17'
                    ),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
                {
                  key: 'AR28_SCC1_ANNEX_3_Q18',
                  type: 'nz-textarea',
                  props: {
                    label: this.translateService.instant(
                      'AR28_SCC1_ANNEX_3_Q18'
                    ),
                  },
                  expressions: {
                    'props.disabled': 'formState.disabled',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  };

  options: FormlyFormOptions = {
    formState: {
      disabled: true,
    },
  };
  formData: any = {};

  constructor(
    private auth: Auth,
    private article28FormService: Article28FormService,
    private article28FormStore: Article28FormStore,
    private logService: LogService,
    public messageService: NzMessageService,
    public scrollService: ScrollService,
    public solutionApi: SolutionApi,
    public solutionService: SolutionService,
    private translateService: TranslateService,
    private veoliaMessageService: VeoliaMessageService
  ) {}

  ngOnInit(): void {
    this.article28FormStore.setSolutionId(this.solutionId);
    this.article28FormStore.setSolution(this.solution!);
    this.article28FormStore.setFormName('Article 28 Form');
    this.article28FormStore.getArticle28Form();
    this.article28FormStore.getEvaluation();
    this.article28FormStore.getAllReservations();
    this.article28FormStore.getAllComments();
    this.article28FormStore.getAllFiles();
    this.article28FormStore.getStatuses();

    this.options.formState.disabled = !this.isVendor || this.isVendorReadOnly;

    this.article28FormStore.form$.subscribe(data => {
      this.updateFormDate.emit({
        formName: this.formName,
        lastUpdate: data?.lastUpdate!,
        lastUpdateBy: data?.lastUpdateBy!,
        status: data?.article28Status!
      });
    });
  }

  selectChapter(id: string) {
    this.selectedChapter = id;
    this.scrollService.scrollToElementById(id);
  }

  formEdited(data: any) {
    this.formData = { ...this.formData, ...data };
  }

  editChapter(data: any) {
    const messageId = this.messageService.loading('Saving...', {
      nzDuration: 0,
    }).messageId;
    setTimeout(() => {
      this.article28FormService
        .update(
          this.solutionId,
          {
            ...data,
            article28Status: 'Vendor is responding',
          },
          true
        )
        .then(() => {
          return this.solutionService.updateSolution(this.solutionId, {
            lastUpdate: fireServerTimestamp(),
            article28Status: 'Vendor is responding',
            status: 'Ongoing',
          });
        })
        .then(() => {
          this.messageService.remove(messageId);
          this.veoliaMessageService.create(
            {
              title: 'Saved successfully',
            },
            { duration: 3000 }
          );
        })
        .catch(error => {
          console.log(error);
          this.messageService.remove(messageId);
        });
    }, 2000);
  }

  saveAll() {
    const messageId = this.messageService.loading('Saving All', {
      nzDuration: 0,
    }).messageId;
    setTimeout(() => {
      this.article28FormService
        .update(
          this.solutionId,
          {
            ...this.formData,
            article28Status: 'Vendor is responding',
          },
          true
        )
        .then(() => {
          return this.solutionService.updateSolution(this.solutionId, {
            lastUpdate: fireServerTimestamp(),
            article28Status: 'Vendor is responding',
            status: 'Ongoing',
          });
        })
        .then(() => {
          this.messageService.remove(messageId);
          this.logService.setLogForAction(
            'SaveForm',
            this.solutionId!,
            this.formName!,
            {
              action: 'Save form',
              userEmail: this.auth.currentUser?.email!,
              dateTime: serverTimestamp(),
              userId: this.auth.currentUser?.uid!,
            }
          );
          this.veoliaMessageService.create(
            {
              title: 'Saved All successfully',
            },
            { duration: 3000 }
          );
        })
        .catch(error => {
          console.log(error);
          this.messageService.remove(messageId);
        });
    }, 2000);
  }

  markAsComplete(status: boolean) {
    const messageId = this.messageService.loading('Marking as completed', {
      nzDuration: 0,
    }).messageId;

    setTimeout(() => {
      this.article28FormService
        .update(
          this.solutionId,
          {
            article28Status: status ? 'Vendor is responding' : 'Completed',
          },
          true
        )
        .then(() => {
          const data: any = {
            article28Status: status ? 'Vendor is responding' : 'Completed',
          };

          if (status === false) {
            data['status'] = 'Ongoing';
          }

          return this.solutionService.update(this.solutionId, data, false);
        })
        .then(() => {
          this.messageService.remove(messageId);

          if (status == false) {
            this.veoliaMessageService.create(
              {
                title: 'Marked as completed successfully',
              },
              { duration: 3000 }
            );

            this.logService.setLogForAction(
              'SaveCompleteForm',
              this.solutionId!,
              this.formName!,
              {
                action: 'Save complete form',
                userEmail: this.auth.currentUser?.email!,
                dateTime: serverTimestamp(),
                userId: this.auth.currentUser?.uid!,
              }
            );
            
            this.solutionApi
              .notifySolutionFormCompleted(this.solutionId, {
                formName: this.formName,
                formNameRoute: 'article28Form',
              })
              .subscribe();
          } else {
            this.veoliaMessageService.create(
              {
                title: 'Marked as uncompleted successfully',
              },
              { duration: 3000 }
            );
          }

          this.refreshForm();
        })
        .catch(error => {
          console.log(error);
          this.messageService.remove(messageId);
        });
    }, 2000);
  }

  get formCompletionStatus() {
    if ((this.article28FormStore.getForm() as Article28Form)?.article28Status) {
      if (
        ['New', 'Vendor is responding'].includes(
          (this.article28FormStore.getForm() as Article28Form)?.article28Status
        )
      ) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  get formStatus() {
    return (
      (this.article28FormStore.getForm() as Article28Form)?.article28Status ||
      'New'
    );
  }

  get isVendor() {
    return this.solution?.emailVendorAuthorized.includes(
      this.auth.currentUser?.email!
    );
  }

  get isVendorReadOnly() {
    return (
      this.solution?.emailVendorReadonlyArt28?.includes(
        this.auth.currentUser?.email!
      ) || false
    );
  }

  refreshForm() {
    this.article28FormStore.getArticle28Form();
    this.article28FormStore.getEvaluation();
    this.article28FormStore.getAllReservations();
  }
}
