import { Component, OnInit, ViewChild, inject } from '@angular/core';
import {
  FieldWrapper,
  FormlyFieldConfig,
  FormlyFieldProps as CoreFormlyFieldProps,
} from '@ngx-formly/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormActionStatusComponent } from '../form-action-status/form-action-status.component';
import { LegalFormStore } from 'src/app/features/demands/components/legal-form/legal-form.store';
import { getTagColor } from 'src/app/core/utils/colors';
import { Auth } from '@angular/fire/auth';
import { ChapterStatus } from 'src/app/core/models/chapter-status';
import { ChapterCompletionStatusService } from 'src/app/core/services/chapter-completion-status.service';
import { LogService } from 'src/app/core/services/log.service';

export interface FormlyFieldProps extends CoreFormlyFieldProps {
  hideRequiredMarker?: boolean;
  hideLabel?: boolean;
  evaluation?: any;
  extraTemplate?: string;
}

@Component({
  selector: 'app-formly-wrapper-nz-form-field',
  template: `
    <nz-form-item>
      <ng-container *ngIf="props.label && props.hideLabel !== true">
        <div nz-row nzJustify="space-between" class="spacing-v4">
          <nz-form-label
            nz-col
            nzSpan="16"
            [nzRequired]="props.required && props.hideRequiredMarker !== true"
            [nzFor]="id">
            {{ props.label }}
          </nz-form-label>
          <div nz-col>
            <div class="row row-vertical-center" *ngIf="vm$ | async as vm">
              <app-form-action-status
                [evaluation]="props.evaluation"
                [businessUnit]="getBusinessUnit()"
                [solution]="getSolution()"
                #formActionStatus></app-form-action-status>
              <nz-spin *ngIf="changing" nzSimple></nz-spin>
              <veolia-switch
                [disabled]="!hasFullRights && !isVendor"
                (click)="switchClick($event)"
                (valueChange)="updateChapterStatus($event)"
                [value]="completionStatus"
                [size]="'small'"
                [label]="'Completed'"
                [withContainer]="true" />
              <veolia-tag
                [label]="evaluationStatus!"
                [align]="'left'"
                (click)="openEvaluations($event)"
                [color]="evaluationColor!" />
            </div>
          </div>
        </div>
      </ng-container>
      <div [innerHTML]="props.extraTemplate"></div>
      <nz-form-control
        [nzValidateStatus]="errorState"
        [nzErrorTip]="errorTpl"
        [nzExtra]="props.description">
        <ng-container #fieldComponent></ng-container>
        <ng-template #errorTpl let-control>
          <formly-validation-message
            [field]="field"></formly-validation-message>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      .col {
        flex: 1;
      }
    `,
  ],
})
export class FormAssessmentWrapperComponent
  extends FieldWrapper<FormlyFieldConfig<FormlyFieldProps>>
  implements OnInit
{
  auth: Auth = inject(Auth);
  authService: AuthService = inject(AuthService);
  legalFormStore: LegalFormStore = inject(LegalFormStore);
  chapterCompletionStatusService: ChapterCompletionStatusService = inject(
    ChapterCompletionStatusService
  );
  logService: LogService = inject(LogService);
  changing = false;
  completionStatus = false;

  vm$ = this.legalFormStore.vm$;

  @ViewChild('formActionStatus')
  formActionStatus!: FormActionStatusComponent;

  ngOnInit(): void {
    this.legalFormStore
      .select(state => state.statuses)
      .subscribe(statuses => this.updateCompletionStatus(statuses));
  }

  updateCompletionStatus(statuses: Record<string, ChapterStatus> | undefined) {
    if (statuses && Object.keys(statuses).length !== 0) {
      const status = statuses[this.props.evaluation.fieldId];
      if (status) {
        if (status.status === 'Completed') {
          this.completionStatus = true;
        }
      }
    }
  }

  openEvaluations(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.formActionStatus.open(this.props.evaluation!, this.isVendor);
  }

  switchClick(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  updateChapterStatus(status: boolean) {
    this.changing = true;
    const path = `/completeChapSubChap/${this.props.evaluation.formName}/${this.props.evaluation.solutionId}/${this.props.evaluation.fieldId}`;

    const chapterStatus: ChapterStatus = this.legalFormStore.getStatus(
      this.props.evaluation.fieldId
    );

    const payload = {
      addedAt: new Date(Date.now()).toString(),
      addedBy: this.auth.currentUser?.email,
      formIs: this.props.evaluation.formName,
      idRef: `${this.props.evaluation.solutionId}-${this.props.evaluation.fieldId}`,
      idSolution: this.props.evaluation.solutionId,
      reference: this.props.evaluation.fieldId,
      solutionName: this.props.evaluation.solutionName,
      status: status ? 'Completed' : 'Not completed',
      userId: this.auth.currentUser?.uid,
    };

    if (chapterStatus) {
      this.chapterCompletionStatusService
        .updateCompletionStatus(`${path}/${chapterStatus.id!}`, payload)
        .then(() => {
          this.changing = false;
          this.logService.setLogForAction(
            'Complete',
            this.props.evaluation.solutionId,
            this.props.evaluation.formName,
            {
              action: 'Chapter/sub-chapter completed',
              chapter: this.props.evaluation.fieldId,
              comment: null,
              roleUser: this.authService.userRole,
              status: status ? 'Completed' : 'Not completed',
            }
          );
          this.reloadStatuses();
        })
        .catch(error => {
          console.log('Errrr', error);
          this.changing = false;
        });
    } else {
      this.chapterCompletionStatusService
        .setCompletionStatus(path, payload)
        .then(() => {
          this.logService.setLogForAction(
            'Complete',
            this.props.evaluation.solutionId,
            this.props.evaluation.formName,
            {
              action: 'Chapter/sub-chapter completed',
              chapter: this.props.evaluation.fieldId,
              comment: null,
              roleUser: this.authService.userRole,
              status: status ? 'Completed' : 'Not completed',
            }
          );
          this.changing = false;
          this.reloadStatuses();
        })
        .catch(error => {
          console.log('Errrr', error);
          this.changing = false;
        });
    }
  }

  getSolution() {
    return this.legalFormStore.getSolution();
  }

  getBusinessUnit() {
    return this.legalFormStore.getBusinessUnit();
  }

  reloadStatuses() {
    this.legalFormStore.getStatuses();
  }

  get isAdmin() {
    return this.authService.roles?.admin === true;
  }

  get isBusinessUnitEvaluator() {
    if (
      this.getBusinessUnit()?.evaluators.includes(this.auth.currentUser?.email!) &&
      this.authService.roles?.evaluator
    ) {
      return true;
    } else {
      return false;
    }
  }

  get isSolutionEvaluator() {
    return this.getSolution()?.emailEvaluatorAuthorized.includes(
      this.auth.currentUser!.email!
    );
  }

  get isVendor() {
    return this.authService.roles?.vendor === true;
  }

  get hasFullRights() {
    return (
      this.isAdmin || this.isBusinessUnitEvaluator || this.isSolutionEvaluator
    );
  }

  get errorState() {
    return this.showError ? 'error' : '';
  }

  get evaluationStatus() {
    return (
      this.legalFormStore.getFieldEvalution(
        this.props.evaluation.statusField
      ) || 'DLA Assessment'
    );
  }

  get evaluationColor() {
    return getTagColor(
      this.legalFormStore.getFieldEvalution(
        this.props.evaluation.statusField
      ) || 'DLA Assessment'
    );
  }
}
