import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MonoTypeOperatorFunction, tap } from 'rxjs';
import { DomainService } from 'src/app/core/services/domain.service';

@Component({
  selector: 'app-demand-form-page',
  templateUrl: './demand-form-page.component.html',
  styleUrls: ['./demand-form-page.component.scss'],
})
export class DemandFormPageComponent {
  selectedId?: string = '1';
  stepOneUnlocked = true;
  stepTwoUnlocked = false;
  stepThreeUnlocked = false;

  form = new FormGroup({});

  model: any = {
    solutions: [{}],
  };

  options: FormlyFormOptions = {};

  yesOrNoOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  yesOrNoIDKOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: "I don't know", label: "I don't know" },
  ];

  accessQuestionOptions = [
    {
      value:
        'Veolia employees (internal or external, with a veolia.com account)',
      label:
        'Veolia employees (internal or external, with a veolia.com account)',
    },
    {
      value: 'Veolia customers (municipalities...)',
      label: 'Veolia customers (municipalities...)',
    },
    { value: 'Veolia Partners', label: 'Veolia Partners' },
    {
      value: 'Public (specify if there is authentication)',
      label: 'Public (specify if there is authentication)',
    },
  ];

  communicationQuestionOptions = [
    {
      value: 'REST API capabilities are required',
      label: 'REST API capabilities are required',
    },
    {
      value: 'File Transfer capabilities are required',
      label: 'File Transfer capabilities are required',
    },
    {
      value: 'Offline capabilities are required',
      label: 'Offline capabilities are required',
    },
    {
      value: 'Sensitive personal data are going to be accessed or stored',
      label: 'Sensitive personal data are going to be accessed or stored',
    },
    {
      value: 'Sensitive business data are going to be accessed or stored',
      label: 'Sensitive business data are going to be accessed or stored',
    },
    {
      value: 'N/A',
      label: 'N/A',
    },
  ];

  fields = [
    {
      type: 'stepper',
      fieldGroup: [
        {
          props: { label: 'Informations about your request' },
          fieldGroup: [
            {
              key: 'disclaimer',
              type: 'disclaimer',
              props: {
                options: [{ label: 'I agree', value: 'Yes' }],
                required: true,
              },
            },
            {
              key: 'Q0_A',
              type: 'radio',
              props: {
                label: 'A. The solution is provided (developed) by the Vendor',
                options: this.yesOrNoIDKOptions,
                required: true,
              },
              expressions: {
                hide: "model.disclaimer !== 'Yes'",
              },
            },
            {
              key: 'Q0_B',
              type: 'radio',
              props: {
                label:
                  'B. The solution is to be hosted in Veolia Public Cloud (Amazon Web Services or Google Cloud Platform)',
                options: this.yesOrNoIDKOptions,
                required: true,
              },
              expressions: {
                hide: "model.disclaimer !== 'Yes'",
              },
            },
            {
              key: 'Q0_C',
              type: 'radio',
              props: {
                label:
                  'C. The solution offers a mobile app, a chrome extension or a Google add-on',
                options: this.yesOrNoIDKOptions,
                required: true,
              },
              expressions: {
                hide: "model.disclaimer !== 'Yes'",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 1',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'Yes' &&
                    field.model.Q0_B === 'Yes' &&
                    field.model.Q0_C === 'Yes'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 2',
              },
              expressions: {
                hide: "!(model.Q0_A === 'Yes' && model.Q0_B === 'Yes' && model.Q0_C === 'No')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 1',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'No' &&
                    field.model.Q0_B === 'Yes' &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 1',
              },
              expressions: {
                hide: "!(model.Q0_A === 'Yes' && model.Q0_B === 'No' && model.Q0_C === 'Yes')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 2',
              },
              expressions: {
                hide: "!(model.Q0_A === 'Yes' && model.Q0_B === 'No' && model.Q0_C === 'No')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 3',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'Yes' &&
                    field.model.Q0_B === 'No' &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 4',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'Yes' &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === 'Yes'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 5',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'Yes' &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === 'No'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 6',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'Yes' &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 4',
              },
              expressions: {
                hide: "!(model.Q0_A === 'No' && model.Q0_B === 'Yes' && model.Q0_C === 'Yes')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 5',
              },
              expressions: {
                hide: "!(model.Q0_A === 'No' && model.Q0_B === 'Yes' && model.Q0_C === 'No')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 5',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'No' &&
                    field.model.Q0_B === 'Yes' &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'Apps type 1',
              },
              expressions: {
                hide: "!(model.Q0_A === 'No' && model.Q0_B === 'No' && model.Q0_C === 'Yes')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'Idk type 1',
              },
              expressions: {
                hide: "!(model.Q0_A === 'No' && model.Q0_B === 'No' && model.Q0_C === 'No')",
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'Apps type 2',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'No' &&
                    field.model.Q0_B === 'No' &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 4',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'No' &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === 'Yes'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 5',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'No' &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === 'No'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 5',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === 'No' &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 4',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === 'Yes' &&
                    field.model.Q0_C === 'Yes'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 5',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === 'Yes' &&
                    field.model.Q0_C === 'No'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'PaaS or IaaS type 5',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === 'Yes' &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 1',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === 'No' &&
                    field.model.Q0_C === 'Yes'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 7',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === 'No' &&
                    field.model.Q0_C === 'No'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'SaaS type 7',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === 'No' &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'Apps type 3',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === 'Yes'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'Idk type 2',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === 'No'
                  );
                },
              },
            },
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: 'Idk type 3',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => {
                  return !(
                    field.model.Q0_A === "I don't know" &&
                    field.model.Q0_B === "I don't know" &&
                    field.model.Q0_C === "I don't know"
                  );
                },
              },
            },
            {
              type: 'iaas-info',
              props: {
                type: 'error',
                text: 'For IaaS & PaaS DLA requests, please check DLA for XaaS e-Norms',
              },
              expressions: {
                hide: 'model.Q0_A === undefined || model.Q0_B === undefined || model.Q0_C === undefined',
              },
            },
            {
              key: 'type',
              type: 'radio',
              props: {
                label: 'Solution type',
                options: [
                  { value: 'SaaS', label: 'SaaS' },
                  { value: 'IaaS', label: 'IaaS', disabled: true },
                  { value: 'PaaS', label: 'PaaS', disabled: true },
                  {
                    value: 'Apps/Extensions/Add-ons',
                    label: 'Apps/Extensions/Add-ons',
                    disabled: true,
                  },
                ],
                required: true,
              },
              expressions: {
                hide: 'model.Q0_A === undefined || model.Q0_B === undefined || model.Q0_C === undefined',
              },
            },
          ],
        },
        {
          props: { label: 'Tell us more about your need ?' },
          fieldGroup: [
            {
              type: 'text-zone',
              props: {
                type: 'error',
                text: '',
              },
            },
            {
              template: `<div>
              <p>Once your request is created, the process is the following:</p>
              <ul>
                <li>
                  1-Your request is pending approval by DLA team
                  <p>
                    The forms are not yet available<br>
                    The vendor is not yet notified<br>
                    You can still edit your request informations<br>
                  </p>
                </li>
                <li>
                  2-Your request has been validated by DLA team
                  <p>
                    As the Solution Owner, you are notified<br>
                    The vendor's account is created and access is granted<br>
                    The vendor must complete the forms (Design/Legal/Article 32) and allow Veolia to scan the solution<br>
                    As the Solution Owner, you must revive the vendor if the request remains incomplete (cf. <a href="https://docs.google.com/presentation/d/1kqMltFibPvQjwLI2bcVVdQAQ2w7Stf-MIw78wsZUkOI/edit?pli=1#slide=id.g85055ddc33_0_1700">DLAaaS Solution owner guide</a>)<br>
                  </p>
                </li>
              </ul>
              </div>`,
            },
            {
              key: 'rfpName',
              type: 'input',
              props: {
                label:
                  'RFP name/Project name (mandatory if more than one solution)',
                required: true,
                maxLength: 20,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'domainOfSolution',
              type: 'nz-select',
              props: {
                label: 'Domain of solution',
                options: this.domainService.observeAll(),
                required: true,
                valueProp: 'domainName',
                labelProp: 'domainName',
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              template:
                '<a href="https://docs.google.com/spreadsheets/d/1GmhGkvDij4reoV0GL9r3PJLEJutSOi4iovac-L9b71o/edit#gid=1430216237" target="_blank">Business Capabilities definitions</a>',
            },
            {
              template: '<br>',
            },
            {
              key: 'description',
              type: 'nz-textarea',
              props: {
                label:
                  'Can you describe the need ? What is the purpose of the solution ?',
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'accessQuestion',
              type: 'nz-checkbox',
              props: {
                label: 'Who will access/use the solution ?',
                multiple: true,
                options: this.accessQuestionOptions,
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'integrateQuestion',
              type: 'radio',
              props: {
                label:
                  'Will the solution have to integrate within Google Workspace? (Drive, GMail, Doc, Sheet...) ?',
                options: this.yesOrNoOptions,
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'interconnectQuestion',
              type: 'nz-checkbox',
              props: {
                label:
                  'Do you know if the solution will have to interconnect with 3rd party applications ?',
                multiple: true,
                options: [
                  {
                    value: 'Yes, with other SaaS',
                    label: 'Yes, with other SaaS',
                  },
                  {
                    value: 'Yes, with Veolia IaaS or PaaS solution',
                    label: 'Yes, with Veolia IaaS or PaaS solution',
                  },
                  { value: 'No', label: 'No' },
                  { value: "I don't know", label: "I don't know" },
                ],
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'interconnectQuestion2',
              type: 'nz-textarea',
              props: {
                label:
                  'Please give more details about interconnection with 3rd party applications',
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'communicationQuestion',
              type: 'nz-checkbox',
              props: {
                label:
                  'Select the following statements if they apply to your need',
                options: this.communicationQuestionOptions,
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'expectedCompletionDate',
              type: 'nz-datepicker',
              props: {
                label:
                  'Expected date of completion of documents (completion by vendor)',
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
              validators: {
                validation: ['IsCompletionAfterEvaluationDate'],
              },
            },
            {
              key: 'expectedEvaluationDate',
              type: 'nz-datepicker',
              props: {
                label:
                  'Expected evaluation date (Please allow 3 weeks once the documents are completed)',
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
              validators: {
                validation: ['IsEvaluationBeforeCompletionDate'],
              },
            },
            {
              key: 'expectedOnlineReleaseDate',
              type: 'nz-datepicker',
              props: {
                label: 'Expected date of on-line release',
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
            },
            {
              key: 'q0a_iaas',
              type: 'radio',
              props: {
                label:
                  'If it is a new solution, do you confirm that NO SaaS solution exists on the market for that need & that the editor doesn’t offer and doesn’t plan to offer a SaaS mode for its solution ?',
                options: this.yesOrNoOptions,
                required: true,
              },
              expressions: {
                hide: 'model.type === "SaaS"',
              },
            },
            {
              key: 'q0b_iaas',
              type: 'radio',
              props: {
                label:
                  'The purpose of this DLA for IaaS request is the study of the lift&shift of a legacy “on prem” solution.',
                options: this.yesOrNoOptions,
                required: true,
              },
              expressions: {
                hide: 'model.type === "SaaS"',
              },
            },
            {
              key: 'solutionName',
              type: 'input',
              props: {
                label: 'Name of the solution to be evaluated',
                required: true,
                maxLength: 20,
              },
              expressions: {
                hide: 'model.type === "SaaS"',
              },
            },
            {
              key: 'editorBrand',
              type: 'input',
              props: {
                label: 'Vendor brand name',
                required: true,
              },
              expressions: {
                hide: 'model.type === "SaaS"',
              },
            },
            {
              key: 'description',
              type: 'nz-textarea',
              props: {
                label: 'Can you describe your need ?',
                required: true,
              },
              expressions: {
                hide: 'model.type === "SaaS"',
              },
            },
            {
              key: 'domainOfSolution',
              type: 'nz-select',
              props: {
                label:
                  'What domain / business capability does the solution depend on ? ',
                options: this.domainService.observeAll(),
                required: true,
                valueProp: 'domainName',
                labelProp: 'domainName',
              },
              expressions: {
                hide: 'model.type === "SaaS"',
              },
            },
            {
              key: 'inPlace',
              type: 'radio',
              props: {
                label: 'Solution is already in use / DLA renewal ?',
                options: this.yesOrNoIDKOptions,
              },
              expressions: {
                hide: 'model.type === "SaaS"',
              },
            },
            {
              key: 'apmNumber',
              type: 'input',
              props: {
                label:
                  'If the solution is already in use, please provide its APM number (if available)',
              },
              expressions: {
                hide: 'model.type === "SaaS"',
              },
            },
            {
              key: 'link',
              type: 'input',
              props: {
                label: 'Vendor website url (SaaS solution offered to Veolia)',
              },
              expressions: {
                hide: 'model.type === "SaaS"',
              },
            },
            {
              key: 'comment',
              type: 'nz-textarea',
              props: {
                label: 'Additionnal comments',
              },
              expressions: {
                hide: 'model.type === "SaaS"',
              },
            },
            {
              key: 'nbSolution',
              type: 'input',
              props: {
                label: 'Number of solutions to be evaluated',
                type: 'number',
                min: 1,
                max: 4,
                required: true,
              },
              expressions: {
                hide: 'model.type !== "SaaS"',
              },
              hooks: {
                onInit: (field: {
                  formControl: {
                    valueChanges: {
                      pipe: (arg0: MonoTypeOperatorFunction<unknown>) => any;
                    };
                  };
                }) => {
                  return field.formControl.valueChanges.pipe(
                    tap((value: any) => {
                      const currentLength = this.model.solutions.length;
                      let solutions = this.model.solutions;
                      if (value > currentLength) {
                        for (let i = 0; i < value - currentLength; i++) {
                          solutions.push({});
                        }
                      } else {
                        solutions = this.model.solutions.slice(0, value);
                      }

                      this.model = { ...this.model, solutions };
                    })
                  );
                },
              },
            },
          ],
        },
        {
          props: {
            label: 'Candidate solution(s)',
          },
          hideExpression: 'model.type !== "SaaS"',
          fieldGroup: [
            {
              key: 'solutions',
              type: 'repeat-solution',
              props: {
                addText: 'Add another solution',
              },
              fieldArray: {
                fieldGroup: [
                  {
                    key: 'solutionName',
                    type: 'input',
                    props: {
                      label: 'Name of the solution to be evaluated',
                      required: true,
                      maxLength: 20,
                    },
                  },
                  {
                    key: 'editorBrand',
                    type: 'input',
                    props: {
                      label: 'Vendor brand name',
                      required: true,
                      maxLength: 20,
                    },
                  },
                  // {
                  //   key: 'description',
                  //   type: 'nz-textarea',
                  //   props: {
                  //     label: 'Can you describe your need ?',
                  //     required: true,
                  //   },
                  // },
                  // {
                  //   key: 'domainOfSolution',
                  //   type: 'nz-select',
                  //   props: {
                  //     label:
                  //       'What domain / business capability does the solution depend on ? ',
                  //     options: this.domainService.observeAll(),
                  //     required: true,
                  //     valueProp: 'domainName',
                  //     labelProp: 'domainName',
                  //   },
                  // },
                  {
                    key: 'inPlace',
                    type: 'radio',
                    props: {
                      label: 'Solution is already in use / DLA renewal ?',
                      options: this.yesOrNoIDKOptions,
                    },
                  },
                  {
                    key: 'apmNumber',
                    type: 'input',
                    props: {
                      label:
                        'If the solution is already in use, please provide its APM number (if available)',
                    },
                  },
                  {
                    key: 'link',
                    type: 'input',
                    props: {
                      label:
                        'Vendor website url (SaaS solution offered to Veolia)',
                    },
                  },
                  {
                    type: 'divider',
                  },
                  {
                    template: `<h3>
                    Vendor contact for the solution
                    </h3>`,
                  },
                  {
                    key: 'mainEmailVendor',
                    type: 'input',
                    props: {
                      label: 'Vendor email',
                      type: 'email',
                      required: true,
                    },
                    validators: {
                      validation: ['no-generic-email', 'no-blocked-word'],
                    },
                  },
                  {
                    key: 'mainLastnameVendor',
                    type: 'input',
                    props: {
                      label: 'Vendor lastname',
                      required: true,
                    },
                  },
                  {
                    key: 'mainFirstnameVendor',
                    type: 'input',
                    props: {
                      label: 'Vendor firstname',
                      required: true,
                    },
                  },
                  {
                    key: 'mainCompanyVendor',
                    type: 'input',
                    props: {
                      label: 'Vendor company',
                      required: true,
                    },
                  },
                  {
                    key: 'linkCom',
                    type: 'input',
                    props: {
                      label: 'Vendor website URL (of the commercial website)',
                    },
                    validators: {
                      validation: ['no-https-and-www'],
                    },
                  },
                ],
              },
              hooks: {
                onInit: (field: {
                  formControl: {
                    valueChanges: {
                      pipe: (arg0: MonoTypeOperatorFunction<unknown>) => any;
                    };
                  };
                }) => {
                  return field.formControl.valueChanges.pipe(
                    tap((value: any) => {
                      this.model = { ...this.model, nbSolution: value.length };
                    })
                  );
                },
              },
            },
          ],
        },
      ],
    },
  ];

  constructor(
    private domainService: DomainService,
    private router: Router
  ) {}

  setSelected(id: string) {
    this.selectedId = id;
  }

  submit() {
    // empty
  }

  backToHome() {
    this.router.navigate(['/']);
  }
}
