<veolia-tag
  *ngIf="!isEditable"
  [label]="evaluationStatus! || 'Form assessment'"
  [color]="evaluationColor!" />
<div class="pointer">
  <veolia-tag
    *ngIf="isEditable"
    class="pointer"
    [label]="evaluationStatus! || 'Form assessment'"
    [color]="evaluationColor!"
    (click)="modal.open()" />
</div>

<veolia-modal #modal [closable]="true">
  <div veolia-modal-title>Assessment for {{ formName }}</div>

  <div veolia-modal-content>
    <p class="row row-space-between">
      <strong>{{ 'Status of evaluation' | translate }}:</strong>
      <veolia-tag
        [label]="evaluation?.status || 'Form Assessment'"
        [color]="getFormStatusTheme(evaluation?.status || 'Form Assessment')" />
    </p>
    <p class="app-evaluation-comment">
      <app-evaluation-comment
        [title]="'Final comment'"
        [content]="
          evaluation?.finalComment || 'No comment yet added by evaluator'
        "></app-evaluation-comment>
    </p>
    <div *ngIf="formName === 'Design Form'">
      <nz-collapse class="spacing-v16">
        <ng-template #DA1>
          <app-evaluation-section-header
            class="full-width"
            title="DA_1"
            [status]="evaluation?.DA_1_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="DA1" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.DA_1_RADIO_comment"></app-evaluation-comment>
          <nz-divider></nz-divider>
          <app-evaluation-item
            title="DA_11"
            [status]="evaluation?.DA_11_RADIO"
            [comment]="evaluation?.DA_11_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_12"
            [status]="evaluation?.DA_12_RADIO"
            [comment]="evaluation?.DA_12_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_13"
            [status]="evaluation?.DA_13_RADIO"
            [comment]="evaluation?.DA_13_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_14"
            [status]="evaluation?.DA_14_RADIO"
            [comment]="evaluation?.DA_14_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_15"
            [status]="evaluation?.DA_15_RADIO"
            [comment]="evaluation?.DA_15_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_16"
            [status]="evaluation?.DA_16_RADIO"
            [comment]="evaluation?.DA_16_RADIO_comment"></app-evaluation-item>
        </nz-collapse-panel>

        <ng-template #DA2>
          <app-evaluation-section-header
            class="full-width"
            title="DA_2"
            [status]="evaluation?.DA_2_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="DA2" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.DA_2_RADIO_comment"></app-evaluation-comment>
          <nz-divider></nz-divider>
          <app-evaluation-item
            title="DA_21"
            [status]="evaluation?.DA_21_RADIO"
            [comment]="evaluation?.DA_21_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_22"
            [status]="evaluation?.DA_22_RADIO"
            [comment]="evaluation?.DA_22_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_23"
            [status]="evaluation?.DA_23_RADIO"
            [comment]="evaluation?.DA_23_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_24"
            [status]="evaluation?.DA_24_RADIO"
            [comment]="evaluation?.DA_24_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_25"
            [status]="evaluation?.DA_25_RADIO"
            [comment]="evaluation?.DA_25_RADIO_comment"></app-evaluation-item>
        </nz-collapse-panel>

        <ng-template #DA3>
          <app-evaluation-section-header
            class="full-width"
            title="DA_3"
            [status]="evaluation?.DA_3_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="DA3" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.DA_3_RADIO_comment"></app-evaluation-comment>
          <nz-divider></nz-divider>
          <app-evaluation-item
            title="DA_31"
            [status]="evaluation?.DA_31_RADIO"
            [comment]="evaluation?.DA_31_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_32"
            [status]="evaluation?.DA_32_RADIO"
            [comment]="evaluation?.DA_32_RADIO_comment"></app-evaluation-item>
        </nz-collapse-panel>

        <ng-template #DA4>
          <app-evaluation-section-header
            class="full-width"
            title="DA_4"
            [status]="evaluation?.DA_4_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="DA4" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.DA_4_RADIO_comment"></app-evaluation-comment>
          <nz-divider></nz-divider>
          <app-evaluation-item
            title="DA_41"
            [status]="evaluation?.DA_41_RADIO"
            [comment]="evaluation?.DA_41_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_410"
            [status]="evaluation?.DA_410_RADIO"
            [comment]="evaluation?.DA_410_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_42"
            [status]="evaluation?.DA_42_RADIO"
            [comment]="evaluation?.DA_42_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_43"
            [status]="evaluation?.DA_43_RADIO"
            [comment]="evaluation?.DA_43_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_44"
            [status]="evaluation?.DA_44_RADIO"
            [comment]="evaluation?.DA_44_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_45"
            [status]="evaluation?.DA_45_RADIO"
            [comment]="evaluation?.DA_45_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_46"
            [status]="evaluation?.DA_46_RADIO"
            [comment]="evaluation?.DA_46_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_47"
            [status]="evaluation?.DA_47_RADIO"
            [comment]="evaluation?.DA_47_RADIO_comment"></app-evaluation-item>
        </nz-collapse-panel>

        <ng-template #DA5>
          <app-evaluation-section-header
            class="full-width"
            title="DA_5"
            [status]="evaluation?.DA_5_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="DA5" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.DA_5_RADIO_comment"></app-evaluation-comment>
          <nz-divider></nz-divider>
          <app-evaluation-item
            title="DA_51"
            [status]="evaluation?.DA_51_RADIO"
            [comment]="evaluation?.DA_51_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_52"
            [status]="evaluation?.DA_52_RADIO"
            [comment]="evaluation?.DA_52_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_53"
            [status]="evaluation?.DA_53_RADIO"
            [comment]="evaluation?.DA_53_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_54"
            [status]="evaluation?.DA_54_RADIO"
            [comment]="evaluation?.DA_54_RADIO_comment"></app-evaluation-item>
        </nz-collapse-panel>

        <ng-template #DA6>
          <app-evaluation-section-header
            class="full-width"
            title="DA_6"
            [status]="evaluation?.DA_6_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="DA6" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.DA_6_RADIO_comment"></app-evaluation-comment>
          <nz-divider></nz-divider>
          <app-evaluation-item
            title="DA_61"
            [status]="evaluation?.DA_61_RADIO"
            [comment]="evaluation?.DA_61_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_62"
            [status]="evaluation?.DA_62_RADIO"
            [comment]="evaluation?.DA_62_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_63"
            [status]="evaluation?.DA_63_RADIO"
            [comment]="evaluation?.DA_63_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_64"
            [status]="evaluation?.DA_64_RADIO"
            [comment]="evaluation?.DA_64_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="DA_65"
            [status]="evaluation?.DA_65_RADIO"
            [comment]="evaluation?.DA_65_RADIO_comment"></app-evaluation-item>
        </nz-collapse-panel>

        <ng-template #DA7>
          <app-evaluation-section-header
            class="full-width"
            title="DA_7"
            [status]="evaluation?.DA_7_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="DA7" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.DA_7_RADIO_comment"></app-evaluation-comment>
        </nz-collapse-panel>

        <ng-template #DA8>
          <app-evaluation-section-header
            class="full-width"
            title="DA_8"
            [status]="evaluation?.DA_8_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="DA8" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.DA_8_RADIO_comment"></app-evaluation-comment>
        </nz-collapse-panel>

        <ng-template #DA9>
          <app-evaluation-section-header
            class="full-width"
            title="DA_9"
            [status]="evaluation?.DA_9_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="DA9" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.DA_9_RADIO_comment"></app-evaluation-comment>
        </nz-collapse-panel>
      </nz-collapse>
    </div>

    <div *ngIf="formName === 'Legal Form'">
      <nz-collapse class="spacing-v16">
        <ng-template #LA0>
          <app-evaluation-section-header
            class="full-width"
            title="LA_0"
            [status]="evaluation?.LA_0_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="LA0" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.LA_0_RADIO_comment"></app-evaluation-comment>
        </nz-collapse-panel>

        <ng-template #LA1>
          <app-evaluation-section-header
            class="full-width"
            title="LA_1"
            [status]="evaluation?.LA_1_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="LA1" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.LA_1_RADIO_comment"></app-evaluation-comment>
          <nz-divider></nz-divider>
          <app-evaluation-item
            title="LA_10"
            [status]="evaluation?.LA_10_RADIO"
            [comment]="evaluation?.LA_10_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_11"
            [status]="evaluation?.LA_11_RADIO"
            [comment]="evaluation?.LA_11_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_111"
            [status]="evaluation?.LA_111_RADIO"
            [comment]="evaluation?.LA_111_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_112"
            [status]="evaluation?.LA_112_RADIO"
            [comment]="evaluation?.LA_112_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_113"
            [status]="evaluation?.LA_113_RADIO"
            [comment]="evaluation?.LA_113_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_114"
            [status]="evaluation?.LA_114_RADIO"
            [comment]="evaluation?.LA_114_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_115"
            [status]="evaluation?.LA_115_RADIO"
            [comment]="evaluation?.LA_115_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_116"
            [status]="evaluation?.LA_116_RADIO"
            [comment]="evaluation?.LA_116_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_12"
            [status]="evaluation?.LA_12_RADIO"
            [comment]="evaluation?.LA_12_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_121"
            [status]="evaluation?.LA_121_RADIO"
            [comment]="evaluation?.LA_121_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_122"
            [status]="evaluation?.LA_122_RADIO"
            [comment]="evaluation?.LA_122_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_123"
            [status]="evaluation?.LA_123_RADIO"
            [comment]="evaluation?.LA_123_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_124"
            [status]="evaluation?.LA_124_RADIO"
            [comment]="evaluation?.LA_124_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_125"
            [status]="evaluation?.LA_125_RADIO"
            [comment]="evaluation?.LA_125_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_126"
            [status]="evaluation?.LA_126_RADIO"
            [comment]="evaluation?.LA_126_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_127"
            [status]="evaluation?.LA_127_RADIO"
            [comment]="evaluation?.LA_127_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_128"
            [status]="evaluation?.LA_128_RADIO"
            [comment]="evaluation?.LA_128_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_129"
            [status]="evaluation?.LA_129_RADIO"
            [comment]="evaluation?.LA_129_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_1210"
            [status]="evaluation?.LA_1210_RADIO"
            [comment]="evaluation?.LA_1210_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_1211"
            [status]="evaluation?.LA_1211_RADIO"
            [comment]="evaluation?.LA_1211_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_13"
            [status]="evaluation?.LA_13_RADIO"
            [comment]="evaluation?.LA_13_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_131"
            [status]="evaluation?.LA_131_RADIO"
            [comment]="evaluation?.LA_131_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_132"
            [status]="evaluation?.LA_132_RADIO"
            [comment]="evaluation?.LA_132_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_14"
            [status]="evaluation?.LA_14_RADIO"
            [comment]="evaluation?.LA_14_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_141"
            [status]="evaluation?.LA_141_RADIO"
            [comment]="evaluation?.LA_141_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_142"
            [status]="evaluation?.LA_142_RADIO"
            [comment]="evaluation?.LA_142_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_15"
            [status]="evaluation?.LA_15_RADIO"
            [comment]="evaluation?.LA_15_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_151"
            [status]="evaluation?.LA_151_RADIO"
            [comment]="evaluation?.LA_151_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_152"
            [status]="evaluation?.LA_152_RADIO"
            [comment]="evaluation?.LA_152_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_153"
            [status]="evaluation?.LA_153_RADIO"
            [comment]="evaluation?.LA_153_RADIO_comment"></app-evaluation-item>
        </nz-collapse-panel>

        <ng-template #LA2>
          <app-evaluation-section-header
            class="full-width"
            title="LA_2"
            [status]="evaluation?.LA_2_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="LA2" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.LA_2_RADIO_comment"></app-evaluation-comment>
          <nz-divider></nz-divider>
          <app-evaluation-item
            title="LA_21"
            [status]="evaluation?.LA_21_RADIO"
            [comment]="evaluation?.LA_21_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_22"
            [status]="evaluation?.LA_22_RADIO"
            [comment]="evaluation?.LA_22_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_23"
            [status]="evaluation?.LA_23_RADIO"
            [comment]="evaluation?.LA_23_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_24"
            [status]="evaluation?.LA_24_RADIO"
            [comment]="evaluation?.LA_24_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_25"
            [status]="evaluation?.LA_25_RADIO"
            [comment]="evaluation?.LA_25_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_26"
            [status]="evaluation?.LA_26_RADIO"
            [comment]="evaluation?.LA_26_RADIO_comment"></app-evaluation-item>
        </nz-collapse-panel>

        <ng-template #LA3>
          <app-evaluation-section-header
            class="full-width"
            title="LA_3"
            [status]="evaluation?.LA_3_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="LA3" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.LA_3_RADIO_comment"></app-evaluation-comment>
          <nz-divider></nz-divider>
          <app-evaluation-item
            title="LA_31"
            [status]="evaluation?.LA_31_RADIO"
            [comment]="evaluation?.LA_31_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_32"
            [status]="evaluation?.LA_32_RADIO"
            [comment]="evaluation?.LA_32_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_33"
            [status]="evaluation?.LA_33_RADIO"
            [comment]="evaluation?.LA_33_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_34"
            [status]="evaluation?.LA_34_RADIO"
            [comment]="evaluation?.LA_34_RADIO_comment"></app-evaluation-item>
        </nz-collapse-panel>
        <ng-template #LA4>
          <app-evaluation-section-header
            class="full-width"
            title="LA_4"
            [status]="evaluation?.LA_4_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="LA4" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.LA_4_RADIO_comment"></app-evaluation-comment>
          <nz-divider></nz-divider>
          <app-evaluation-item
            title="LA_41"
            [status]="evaluation?.LA_41_RADIO"
            [comment]="evaluation?.LA_41_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_42"
            [status]="evaluation?.LA_42_RADIO"
            [comment]="evaluation?.LA_42_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_43"
            [status]="evaluation?.LA_43_RADIO"
            [comment]="evaluation?.LA_43_RADIO_comment"></app-evaluation-item>
        </nz-collapse-panel>
        <ng-template #LA5>
          <app-evaluation-section-header
            class="full-width"
            title="LA_5"
            [status]="evaluation?.LA_5_RADIO!"></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="LA5" [nzActive]="printing">
          <app-evaluation-comment
            [content]="evaluation?.LA_5_RADIO_comment"></app-evaluation-comment>
          <nz-divider></nz-divider>
          <app-evaluation-item
            title="LA_51"
            [status]="evaluation?.LA_51_RADIO"
            [comment]="evaluation?.LA_51_RADIO_comment"></app-evaluation-item>
          <app-evaluation-item
            title="LA_52"
            [status]="evaluation?.LA_52_RADIO"
            [comment]="evaluation?.LA_52_RADIO_comment"></app-evaluation-item>
        </nz-collapse-panel>
      </nz-collapse>
    </div>

    <div *ngIf="formName === 'Article 32 Form'">
      <nz-collapse class="spacing-v16">
        <ng-template #AR32_0>
          <app-evaluation-section-header
            class="full-width"
            title="AR32_0"
            [status]="
              evaluation?.AR32_0_RADIO!
            "></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="AR32_0" [nzActive]="printing">
          <app-evaluation-comment
            [content]="
              evaluation?.AR32_0_RADIO_comment
            "></app-evaluation-comment>
        </nz-collapse-panel>
        <ng-template #AR32_1>
          <app-evaluation-section-header
            class="full-width"
            title="AR32_1"
            [status]="
              evaluation?.AR32_1_RADIO!
            "></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="AR32_1">
          <strong>Status</strong>
          <p>{{ evaluation?.AR32_1_RADIO }}</p>
          <app-evaluation-comment
            [content]="
              evaluation?.AR32_1_RADIO_comment
            "></app-evaluation-comment>
        </nz-collapse-panel>
        <ng-template #AR32_2>
          <app-evaluation-section-header
            class="full-width"
            title="AR32_2"
            [status]="
              evaluation?.AR32_2_RADIO!
            "></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="AR32_2">
          <app-evaluation-comment
            [content]="
              evaluation?.AR32_2_RADIO_comment
            "></app-evaluation-comment>
        </nz-collapse-panel>
        <ng-template #AR32_3>
          <app-evaluation-section-header
            class="full-width"
            title="AR32_3"
            [status]="
              evaluation?.AR32_3_RADIO!
            "></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="AR32_3">
          <app-evaluation-comment
            [content]="
              evaluation?.AR32_3_RADIO_comment
            "></app-evaluation-comment>
        </nz-collapse-panel>
        <ng-template #AR32_4>
          <app-evaluation-section-header
            class="full-width"
            title="AR32_4"
            [status]="
              evaluation?.AR32_4_RADIO!
            "></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="AR32_4">
          <app-evaluation-comment
            [content]="
              evaluation?.AR32_4_RADIO_comment
            "></app-evaluation-comment>
        </nz-collapse-panel>
        <ng-template #AR32_5>
          <app-evaluation-section-header
            class="full-width"
            title="AR32_5"
            [status]="
              evaluation?.AR32_5_RADIO!
            "></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="AR32_5">
          <app-evaluation-comment
            [content]="
              evaluation?.AR32_5_RADIO_comment
            "></app-evaluation-comment>
        </nz-collapse-panel>
        <ng-template #AR32_6>
          <app-evaluation-section-header
            class="full-width"
            title="AR32_6"
            [status]="
              evaluation?.AR32_6_RADIO!
            "></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="AR32_6">
          <app-evaluation-comment
            [content]="
              evaluation?.AR32_6_RADIO_comment
            "></app-evaluation-comment>
        </nz-collapse-panel>
        <ng-template #AR32_7>
          <app-evaluation-section-header
            class="full-width"
            title="AR32_7"
            [status]="
              evaluation?.AR32_7_RADIO!
            "></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="AR32_7">
          <app-evaluation-comment
            [content]="
              evaluation?.AR32_7_RADIO_comment
            "></app-evaluation-comment>
        </nz-collapse-panel>
        <ng-template #AR32_8>
          <app-evaluation-section-header
            class="full-width"
            title="AR32_8"
            [status]="
              evaluation?.AR32_8_RADIO!
            "></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel [nzHeader]="AR32_8">
          <app-evaluation-comment
            [content]="
              evaluation?.AR32_8_RADIO_comment
            "></app-evaluation-comment>
        </nz-collapse-panel>
      </nz-collapse>
    </div>

    <div *ngIf="formName === 'Article 28 Form'">
      <nz-collapse class="spacing-v16">
        <ng-template #AR28_SCC1_ANNEX_3_RADIO>
          <app-evaluation-section-header
            class="full-width"
            title="AR28_SCC1_ANNEX_3_RADIO"
            [status]="
              evaluation?.AR28_SCC1_ANNEX_3_RADIO!
            "></app-evaluation-section-header>
        </ng-template>
        <nz-collapse-panel
          [nzHeader]="AR28_SCC1_ANNEX_3_RADIO"
          [nzActive]="printing">
          <app-evaluation-comment
            [content]="
              evaluation?.AR28_SCC1_ANNEX_3_RADIO_comment
            "></app-evaluation-comment>
        </nz-collapse-panel>
      </nz-collapse>
    </div>

    <nz-divider></nz-divider>
    <h3 *ngIf="formName === 'Design Form'" class="center">
      Design Form assessment
    </h3>
    <h3 *ngIf="formName === 'Legal Form'" class="center">
      Legal Form assessment
    </h3>
    <h3 *ngIf="formName === 'Article 32 Form'" class="center">
      Article 32 Form assessment
    </h3>
    <h3 *ngIf="formName === 'Article 28 Form'" class="center">
      Annexes assessment
    </h3>
    <h3 *ngIf="formName === 'IaaS Form'" class="center">
      IaaS Form assessment
    </h3>

    <form
      nz-form
      [nzLayout]="'vertical'"
      [formGroup]="assessmentForm"
      (ngSubmit)="submit()">
      <formly-form
        [model]="model"
        [fields]="fields"
        [form]="assessmentForm"
        [options]="options">
      </formly-form>
    </form>
  </div>

  <div veolia-modal-footer>
    <veolia-button (click)="resetForm()" variant="secondary">
      Reset
    </veolia-button>
    <veolia-button [disabled]="assessmentForm.invalid" (click)="submit()"
      >Add new evaluation
    </veolia-button>
  </div>
</veolia-modal>
