export const yesOrNoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const yesOrNoSoonOptions = [
  ...yesOrNoOptions,
  { value: 'Soon', label: 'Soon' },
];

export const yesOrNoUnknowOptions = [
  ...yesOrNoOptions,
  { value: 'Unknow', label: 'Unknown' },
];

export const yesOrNoPlannedOptions = [
  ...yesOrNoOptions,
  { value: 'Planned', label: 'Planned' },
];

export const yesOrNoCentralizedPlannedOptions = [
  ...yesOrNoOptions,
  { value: 'No it is centralized', label: 'No it is centralized' },
  { value: 'Planned', label: 'Planned' },
];

export const yesOrNoNAOptions = [
  ...yesOrNoOptions,
  { value: 'N/A', label: 'N/A' },
];

export const implementationOptions = [
  { label: 'IDP initiated', value: 'IDP initiated' },
  { label: 'SP initiated', value: 'SP initiated' },
  { label: 'Both', value: 'Both' },
  { label: 'Unknow', value: 'Unknow' },
];

export const reportFormats = [
  { label: 'PDF', value: 'PDF' },
  { label: 'CSV', value: 'CSV' },
  { label: 'RTF', value: 'RTF' },
  { label: 'XML', value: 'XML' },
  { label: 'MS office', value: 'MS office' },
  { label: 'Others', value: 'Others' },
];

export const dataFormats = [
  { label: 'JSON', value: 'JSON' },
  { label: 'XML', value: 'XML' },
];

export const authenticationMethods = [
  { label: 'OAuth 2.0', value: 'OAuth 2.0' },
  { label: 'JWT', value: 'JWT' },
  {
    label: 'Others (Basic Auth, OpenID Connect, SAML v2, TLS)',
    value: 'Others (Basic Auth, OpenID Connect, SAML v2, TLS)',
  },
];

export const authenticationProtocols = [
  { label: 'SAML v2', value: 'SAML v2' },
  { label: 'OpenID Connect', value: 'OpenID Connect' },
];

export const monoOrMultiTenant = [
  { value: 'Multi-tenant', label: 'Multi-tenant' },
  { value: 'Mono tenant', label: 'Mono tenant' },
];

export const architecturesAndRegions = [
  {
    value: 'The architecture is multi AZ (same region) by default',
    label: 'The architecture is multi AZ (same region) by default',
  },
  {
    value: 'The architecture is multi region (all actives) by default',
    label: 'The architecture is multi region (all actives) by default',
  },
  {
    value: 'There is a Disaster Recovery in an other Region by default',
    label: 'There is a Disaster Recovery in an other Region by default',
  },
  {
    value: 'Architecture may vary depending on the subscribed SLA',
    label: 'Architecture may vary depending on the subscribed SLA',
  },
];

export const plannedMobileOptions = [
  { value: 'With Android mobile App', label: 'With Android mobile App' },
  { value: 'With IOS mobile App', label: 'With IOS mobile App' },
  {
    value: 'With a responsive interface',
    label: 'With a responsive interface',
  },
];

export const complianceOptions = [
  { label: 'SPF', value: 'SPF' },
  { label: 'DKIM', value: 'DKIM' },
  { label: 'DMARC', value: 'DMARC' },
  { label: 'BIMI (optional)', value: 'BIMI (optional)' },
];

export const languageOptions = [
  { label: 'French', value: 'French' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'English', value: 'English' },
  { label: 'German', value: 'German' },
  { label: 'Other', value: 'Other' },
];

export const assetInventory = [
  {
    label: 'Fully implemented on the whole scope',
    value: 'Fully implemented on the whole scope',
  },
  { label: 'Not implemented', value: 'Not implemented' },
  {
    label: 'Partially implemented or implementedon a minority of the scope',
    value: 'Partially implemented or implementedon a minority of the scope',
  },
  {
    label: 'Implemented on a majority of the scope',
    value: 'Implemented on a majority of the scope',
  },
  {
    label: 'Fully implemented on the whole scope',
    value: 'Fully implemented on the whole scope',
  },
  { label: 'Not relevant', value: 'Not relevant' },
];
