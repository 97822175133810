import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { ReservationApi } from 'src/app/core/apis/reservation.api';
import { Reservation } from 'src/app/core/models/reservation';
import { Solution } from 'src/app/core/models/solution';
import { ReservationService } from 'src/app/core/services/reservation.service';
import { formatStrDate } from 'src/app/core/utils/date';

@Component({
  selector: 'app-reservation-overview',
  templateUrl: './reservation-overview.component.html',
  styleUrls: ['./reservation-overview.component.scss'],
})
export class ReservationOverviewComponent implements OnInit {
  @Input() editedReference?: string;
  @Input() reservation: Reservation = {} as Reservation;
  @Input() solution: Solution = {} as Solution;
  @Output() reservationEdit: EventEmitter<string> = new EventEmitter<string>();
  @Output() reservationEdited: EventEmitter<Reservation> = new EventEmitter();
  @Output() reservationEditCancel: EventEmitter<void> =
    new EventEmitter<void>();

  editMode = false;
  translatedRef = '';

  form: FormGroup<{
    fixed: FormControl<boolean | null>;
  }> = this.formBuilder.group({
    fixed: [false],
  });

  constructor(
    private formBuilder: FormBuilder,
    private veoliaMessageService: VeoliaMessageService,
    private reservationService: ReservationService,
    private reservationApi: ReservationApi,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.form?.get('fixed')?.setValue(this.reservation.fixed);
    if (this.reservation.reference) {
      this.translateService.get(this.reservation.reference).subscribe(value => {
        this.translatedRef = value;
      });
    }
  }

  edit(): void {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.reservationEdit.emit(this.reservation.reference);
    } else {
      this.close();
    }
  }

  close(): void {
    this.editMode = false;
    this.reservationEditCancel.emit();
  }

  formatDate(date: string): string {
    return formatStrDate(date);
  }

  getHeaderColor(status: boolean): string {
    if (status) {
      return 'recommended';
    } else {
      return 'not-recommended';
    }
  }

  submitForm(): void {
    if (this.form.invalid) {
      return;
    }
    const fixedOn = new Date().toString();
    this.reservationService
      .updateReservation(
        `reservations/${this.reservation.formIs}/${this.reservation.idSolution}/${this.reservation.reference}/${this.reservation.idRef}`,
        {
          fixed: Boolean(this.form.get('fixed')?.value),
          fixedOn,
        }
      )
      .then(() => {
        this.editMode = false;
        this.reservationEdited.emit({
          ...this.reservation,
          fixed: Boolean(this.form.get('fixed')?.value),
          fixedOn,
        });
        this.reservationApi
          .notifyReservation(this.solution.uid, {
            businessUnit: this.solution.businessUnitOfSolution,
            chapter: this.translatedRef,
            content: this.reservation?.content,
            editorBrand: this.solution?.editorBrand,
            fixed: Boolean(this.form.get('fixed')?.value),
            formName: this.solution?.solutionName,
            rfpName: this.solution?.rfpName,
            postedAt: this.reservation?.postedAt,
            postedByEvaluator: this.reservation?.postedBy,
            solutionName: this.solution?.solutionName,
            solutionType: this.solution?.type,
          })
          .subscribe();
        this.veoliaMessageService.create(
          {
            title: 'Updated !',
            icon: 'check',
            content: 'Reservation updated successfully',
          },
          {
            duration: 10000,
          }
        );
      })
      .catch(() => {
        this.veoliaMessageService.create(
          {
            title: 'Error',
            icon: 'error',
            content: 'Error updating reservation',
          },
          {
            duration: 10000,
          }
        );
      });
  }
}
