import { Injectable } from '@angular/core';
import { BaseApi } from './api';
import { Observable } from 'rxjs';
import { FullEvaluationNotifyDTO } from '../dtos/full-evaluation-notify.dto';
import { NotifyOnHoldDTO } from '../dtos/notify-on-hold.dto';

@Injectable()
export class EvaluationApi extends BaseApi {
  notifyFullEvaluation(
    solution_id: string,
    data: FullEvaluationNotifyDTO
  ): Observable<any> {
    return this.post(
      `/api/evaluations/notify/evaluation_final/${solution_id}`,
      data
    );
  }

  notifyOnHold(solution_id: string, data: NotifyOnHoldDTO): Observable<any> {
    return this.post(`/api/evaluations/notify/on_hold/${solution_id}`, data);
  }
}
