<ng-container *ngIf="vm$ | async as vm">
  <nz-alert
    *ngIf="vm.error"
    nzType="error"
    [nzMessage]="vm.error"
    nzShowIcon></nz-alert>
  <div>
    <search-input
      [isSearching]="vm.isSearching"
      (onChange)="onSearch($event)"></search-input>
    <app-users-table
      (refresh)="onRefreshAfterEmit()!"
      *ngIf="userType === 0"
      [actions]="['manage']"
      [users]="vm.users"
      [userType]="vm.userType"></app-users-table>
    <app-users-table
      (refresh)="onRefreshAfterEmit()!"
      *ngIf="userType === 1"
      [actions]="['reset_password', 'disabled', 'enabled']"
      [users]="vm.users"
      [userType]="vm.userType"></app-users-table>
  </div>
  <app-pagination
    *ngIf="!vm.isSearching"
    [loading]="vm.isLoading || vm.isSearching"
    [size]="vm.limit"
    [page]="vm.currentPage"
    [total]="vm.total"
    (onPageChange)="changePage($event)"
    (onSizeChange)="changeSize($event)"></app-pagination>
</ng-container>
