import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';
import { EvaluationApi } from 'src/app/core/apis/evaluation.api';
import { FormName } from 'src/app/core/enums/form-name';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { DesignForm } from 'src/app/core/models/design-form';
import { Evaluation } from 'src/app/core/models/evalutation';
import { FileItem } from 'src/app/core/models/file-item';
import { IaasForm } from 'src/app/core/models/iaas-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { Solution } from 'src/app/core/models/solution';
import { Article28FormService } from 'src/app/core/services/article-28-form.service';
import { Article32FormService } from 'src/app/core/services/article-32-form.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DesignFormService } from 'src/app/core/services/design-form.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { FileService } from 'src/app/core/services/file.service';
import { IaaSFormService } from 'src/app/core/services/iaas-form.service';
import { LegalFormService } from 'src/app/core/services/legal-form.service';
import { LogService } from 'src/app/core/services/log.service';
import { SolutionService } from 'src/app/core/services/solution.service';
import { getTagColor } from 'src/app/core/utils/colors';
import { convertDictToList } from 'src/app/core/utils/common';

@Component({
  selector: 'app-evaluation-summary',
  templateUrl: './evaluation-summary.component.html',
  styleUrls: ['./evaluation-summary.component.scss'],
})
export class EvaluationSummaryComponent implements OnInit {
  @Input() evaluation?: Evaluation;
  @Input() fileList?: FileItem[] = [];
  @Input() files?: Record<string, FileItem[]>;
  @Input() currentForm?:
    | Article28Form
    | Article32Form
    | DesignForm
    | IaasForm
    | LegalForm;
  @Input() formName?: string;
  @Input() isVisible = false;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() solution?: Solution;

  @Input() businessUnit?: BusinessUnit;

  // Evaluations
  art28FormEvaluation?: Evaluation;
  art32FormEvaluation?: Evaluation;
  designFormEvaluation?: Evaluation;
  legalFormEvaluation?: Evaluation;
  iaaSFormEvaluation?: Evaluation;

  evalform = new FormGroup({});
  acunetixform = new FormGroup({});
  model: any = {};
  modelAcunetix: any = {};
  options: FormlyFormOptions = {
    formState: {
      disabled: true,
    },
  };
  fields: FormlyFieldConfig[] = [
    {
      key: 'status',
      type: 'nz-status',
      props: {
        options: [
          { label: 'Recommended', value: 'Recommended' },
          { label: 'Recommended with reservations', value: 'Reservations' },
          { label: 'Not recommended', value: 'Not recommended' },
        ],
      },
      expressions: {
        'props.disabled': 'formState.disabled',
      },
    },
    {
      key: 'comment',
      type: 'nz-textarea',
      expressions: {
        'props.disabled': 'formState.disabled',
      },
    },
  ];
  opened? = 0;

  fieldsAcunetix: FormlyFieldConfig[] = [
    {
      key: 'acunetix_radio',
      type: 'radio',
      props: {
        label: 'Acunetix scan done ?',
        options: [
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' },
        ],
      },
      expressions: {
        'props.disabled': 'formState.disabled',
      },
    },
    {
      key: 'secu_radio',
      type: 'radio',
      props: {
        label: 'Critical flaws have been detected ?',
        options: [
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' },
        ],
      },
      expressions: {
        'props.disabled': 'formState.disabled',
      },
    },
    {
      key: 'acunetix_comment',
      type: 'nz-textarea',
      expressions: {
        'props.disabled': 'formState.disabled',
      },
    },
  ];

  @ViewChild('modal') modal!: VeoliaModalComponent;

  constructor(
    private auth: Auth,
    private authService: AuthService,
    private evaluationService: EvaluationService,
    private evaluationApi: EvaluationApi,
    private logService: LogService,
    private solutionService: SolutionService,
    private messageService: VeoliaMessageService,
    private fileService: FileService,
    private article28FormService: Article28FormService,
    private article32FormService: Article32FormService,
    private designFormService: DesignFormService,
    private iaasFormService: IaaSFormService,
    private legalFormService: LegalFormService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.evaluation) {
      this.model.status = this.formStatus;
      this.model.comment = this.evaluation.finalComment;
      this.modelAcunetix.acunetix_radio = this.evaluation.acunetix_radio;
      this.modelAcunetix.acunetix_comment = this.evaluation.acunetix_comment;
      this.modelAcunetix.secu_radio = this.evaluation.secu_radio;
    } else {
      this.getFormEvaluation();
    }

    this.options.formState.disabled =
      !this.hasFullRights || !this.isFormsRoute();
  }

  isFormsRoute() {
    const re = new RegExp(
      '^(/designForm/)\\w+|(/legalForm/)\\w+|(/article32Form/)\\w+|(/article28Form/)\\w+'
    );
    if (this.router.url.match(re)) {
      return true;
    } else {
      return false;
    }
  }

  get hasFullRights() {
    return (
      this.isAdmin || this.isBusinessUnitEvaluator || this.isSolutionEvaluator
    );
  }

  open(formName?: string) {
    if (formName) {
      this.formName = formName;
      this.getFormEvaluation();
      this.getFiles();
    }
    this.modal.open();
  }

  close(): void {
    this.closeModal.emit();
  }

  getFormStatusTheme(status: string) {
    return getTagColor(status);
  }

  get evaluationPath() {
    return `evaluations/${this.formName}/${this.solution?.uid}/${this.evaluation?.id}`;
  }

  get isAdmin() {
    return this.authService.roles?.admin === true;
  }

  get isBusinessUnitEvaluator() {
    if (
      this.businessUnit?.evaluators.includes(this.auth.currentUser!.email!) &&
      this.authService.roles?.evaluator
    ) {
      return true;
    } else {
      return false;
    }
  }

  get isSolutionEvaluator() {
    return this.solution?.emailEvaluatorAuthorized.includes(
      this.auth.currentUser!.email!
    );
  }

  get isVendor() {
    return this.authService.roles?.vendor === true;
  }

  get isVeolia() {
    return this.solution?.emailVeoliaAuthorized.includes(
      this.auth.currentUser!.email!
    );
  }

  get isViewer() {
    return this.authService.roles?.viewer;
  }

  get formStatus() {
    if (this.formName === 'Design Form') {
      return (this.currentForm as DesignForm).designStatus;
    } else if (this.formName === 'Legal Form') {
      return (this.currentForm as LegalForm).legalStatus;
    } else if (this.formName === 'Article 28 Form') {
      return (this.currentForm as Article28Form).article28Status;
    } else if (this.formName === 'Article 32 Form') {
      return (this.currentForm as Article32Form).article32Status;
    } else {
      return (this.currentForm as IaasForm)?.iaasStatus;
    }
  }

  goToLink(file: FileItem) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = event => {
      const blob = xhr.response;
    };
    xhr.open('GET', file.url);
    xhr.send();
  }

  clickPanel(id: number) {
    this.opened = this.opened === id ? undefined : id;
  }

  submitAcunetix() {
    if (this.acunetixform.valid) {
      this.evaluationService
        .updateEvaluation(this.evaluationPath, {
          acunetix_radio: this.modelAcunetix.acunetix_radio || null,
          acunetix_comment: this.modelAcunetix.acunetix_comment || null,
          secu_radio: this.modelAcunetix.secu_radio || null,
          postedAt: new Date(Date.now()).toString(),
          postedBy: this.auth.currentUser?.email,
          userId: this.auth.currentUser?.uid,
          solutionName: this.solution?.solutionName,
        })
        .then(() => {
          this.messageService.create(
            {
              icon: 'check_box',
              title: 'Success',
              content: 'Acunetix saved successfully',
            },
            { duration: 5000 }
          );

          this.logService.setLogForAction(
            'Acunetix',
            this.solution!.uid,
            this.formName!,
            {
              roleUser: this.authService.userRole,
              action: 'Acunetix saved',
              dateTime: new Date(Date.now()).toString(),
              acunetix_radio: this.modelAcunetix.acunetix_radio,
              acunetix_comment: this.modelAcunetix.acunetix_comment || null,
              secu_radio: this.modelAcunetix.secu_radio || null,
            }
          );

          // this.modal.close();
          // this.close();
        })
        .catch(error => {
          this.messageService.create(
            {
              icon: 'error',
              title: 'Error',
              content: 'Oops! Acunetix error: ' + error.message,
            },
            { duration: 5000 }
          );
          //this.modal.close();
        });
    }
  }

  submit() {
    if (this.evalform.valid) {
      this.evaluationService
        .updateEvaluation(this.evaluationPath, {
          finalComment: this.model.comment || null,
          status: this.model.status, //Si toujours pas d'évaluation finale cochée alors DLA is reviewing
          postedAt: new Date(Date.now()).toString(),
          postedBy: this.auth.currentUser?.email,
          userId: this.auth.currentUser?.uid,
          solutionName: this.solution?.solutionName,
        })
        .then(() => {
          this.messageService.create(
            {
              icon: 'check_box',
              title: 'Success',
              content: 'Evaluated successfully',
            },
            { duration: 5000 }
          );

          if (
            ['Recommended', 'Reservations', 'Not recommended'].includes(
              this.model.status
            )
          ) {
            const data: any = {};

            if (this.formName === 'Design Form') {
              data['designStatus'] = this.model.status;
              this.designFormService
                .update(
                  this.solution?.uid!,
                  {
                    designStatus: this.model.status,
                    designFull: true,
                  },
                  false
                )
                .then(() => {
                  this.notifyEvaluation('designForm');
                })
                .catch(error => {
                  this.onErrorEvaluation(error);
                });
            }

            if (this.formName === 'Legal Form') {
              data['legalStatus'] = this.model.status;
              this.legalFormService
                .update(
                  this.solution?.uid!,
                  {
                    legalStatus: this.model.status,
                    legalFull: true,
                  },
                  false
                )
                .then(() => {
                  this.notifyEvaluation('legalForm');
                })
                .catch(error => {
                  this.onErrorEvaluation(error);
                });
            }

            if (this.formName === 'Article 32 Form') {
              data['article32Status'] = this.model.status;
              this.article32FormService
                .update(
                  this.solution?.uid!,
                  {
                    article32Status: this.model.status,
                    article32Full: true,
                  },
                  false
                )
                .then(() => {
                  this.notifyEvaluation('article32Form');
                })
                .catch(error => {
                  this.onErrorEvaluation(error);
                });
            }

            if (this.formName === 'Article 28 Form') {
              data['article32Status'] = this.model.status;
              this.article28FormService
                .update(
                  this.solution?.uid!,
                  {
                    article28Status: this.model.status,
                    article28Full: true,
                  },
                  false
                )
                .then(() => {
                  this.notifyEvaluation('article28Form');
                })
                .catch(error => {
                  this.onErrorEvaluation(error);
                });
            }

            if (this.formName === 'IaaS Form') {
              data['iaasStatus'] = this.model.status;
              this.iaasFormService
                .update(
                  this.solution?.uid!,
                  {
                    iaasStatus: this.model.status,
                    iaasFull: true,
                  },
                  false
                )
                .then(() => {
                  this.notifyEvaluation('iaasForm');
                })
                .catch(error => {
                  this.onErrorEvaluation(error);
                });
            }

            this.solutionService.update(this.solution!.uid, data, false);
          }

          this.logService.setLogForAction(
            'FinalEvaluation',
            this.solution!.uid,
            this.formName!,
            {
              roleUser: this.authService.userRole,
              action: 'Final evaluation saved',
              dateTime: new Date(Date.now()).toString(),
              status: this.model.status,
              comment: this.model.comment || null,
            }
          );

          this.modal.close();
          this.close();
        })
        .catch(error => {
          this.messageService.create(
            {
              icon: 'error',
              title: 'Error',
              content: 'Oops! Evaluation error: ' + error.message,
            },
            { duration: 5000 }
          );
          this.modal.close();
        });
    }
  }

  getFiles() {
    this.fileService
      .getAllFiles(this.formName!, this.solution?.uid!)
      .then(data => {
        const files: Record<string, FileItem[]> = {};
        let fileList: FileItem[] = [];

        if (data) {
          Object.entries(data).forEach(([field, fieldFiles]) => {
            files[field] = Object.values(fieldFiles);
            fileList = [
              ...fileList,
              ...(Object.values(fieldFiles) as FileItem[]),
            ];
          });
        }
        this.fileList = fileList;
        this.files = files;
      })
      .catch(error => {
        console.log(error);
      });
  }

  getFormEvaluation() {
    this.evaluationService
      .getEvaluations(this.solution?.uid!, this.formName!)
      .then(evaluations => {
        const evals = evaluations as Record<string, unknown>;
        if (evals) {
          const evalDatas = convertDictToList(evals);

          if (this.formName === FormName.Article28) {
            this.art28FormEvaluation = evalDatas[0] as Evaluation;
            this.evaluation = this.art28FormEvaluation;
          }

          if (this.formName === FormName.Article32) {
            this.art32FormEvaluation = evalDatas[0] as Evaluation;
            this.evaluation = this.art32FormEvaluation;
          }

          if (this.formName === FormName.Design) {
            this.designFormEvaluation = evalDatas[0] as Evaluation;
            this.evaluation = this.designFormEvaluation;
          }

          if (this.formName === FormName.Legal) {
            this.legalFormEvaluation = evalDatas[0] as Evaluation;
            this.evaluation = this.legalFormEvaluation;
          }

          if (this.formName === FormName.IAAS) {
            this.iaaSFormEvaluation = evalDatas[0] as Evaluation;
            this.evaluation = this.iaaSFormEvaluation;
          }
          this.model = {
            ...this.model,
            status: this.evaluation?.status,
            comment: this.evaluation?.finalComment,
          };
        }
      })
      .catch(error => {
        // this.notificationService.error('error', error + ' ' + form);
      });
  }

  resetForm() {
    console.log('reset form');
    this.model = {
      ...this.model,
      status: this.evaluation?.status,
      comment: this.evaluation?.finalComment,
    };
  }

  resetAcunetixForm() {
    console.log('reset form');
    this.modelAcunetix = {
      ...this.modelAcunetix,
      acunetix_radio: this.evaluation?.acunetix_radio,
      secu_radio: this.evaluation?.secu_radio,
      acunetix_comment: this.evaluation?.acunetix_comment,
    };
  }

  notifyEvaluation(formNameRoute: string) {
    this.evaluationApi
      .notifyFullEvaluation(this.solution!.uid, {
        formName: this.formName!,
        status: this.model.status,
        comment: this.model.comment || null,
        formNameRoute: formNameRoute,
      })
      .subscribe({
        next: () => {
          this.messageService.create(
            {
              icon: 'check_box',
              title: 'Success',
              content: 'Notification sent successfully',
            },
            { duration: 5000 }
          );
        },
        error: error => {
          this.messageService.create(
            {
              icon: 'error',
              title: 'Error',
              content: 'Oops! Evaluation error: ' + error.message,
            },
            { duration: 5000 }
          );
        },
      });
  }

  onErrorEvaluation(error: any) {
    this.messageService.create(
      {
        icon: 'error',
        title: 'Error',
        content: 'Oops! Evaluation error: ' + error.message,
      },
      { duration: 5000 }
    );
  }
}
